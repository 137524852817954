import React from 'react'; 
import {Component} from 'react'; 
import {  Table, Loader,  Container, Header, Divider, Icon, Button, Grid, Segment, Dropdown, Dimmer, Label, Confirm } from 'semantic-ui-react';
import axios from 'axios';
import Moment from 'react-moment';
import Auth from '../../validators/auth';
import {
  Form } from 'formsy-semantic-ui-react';
 
class PayrollDetail extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;  
    this.state = {
      schedules:props.location.state.schedules,
      pdfloading:false 
   }; 
  }
 
  refreshContent(){ 
    axios.get(`https://api.pureptinc.com/payrolls/`+this.props.location.state.id).then(res => {
        console.log(res.data[0])
        this.setState(res.data[0])
        axios.get(`https://api.pureptinc.com/therapists/`+res.data[0].therapistid).then(res => { 
          this.setState({therapist:res.data.[0]})
      });  
    });   
  }
 
  componentDidMount(){
    
    this.refreshContent();
  }

 
  handleChange = event => {
    this.name = event.target.name;
    this.setState({ [event.target.name]: event.target.value });
 }

 statusChange = (event, {name, value}) => {
  
   
  this.setState({
    [name]: value  
  })
  
}


  handleSubmit = () => {
 
    axios.put(`https://api.pureptinc.com/payrolls/`+this.props.location.state.id, {
         count:this.state.count,
         total:this.state.total,
         status:this.state.status
    })
    .then(res => {
        this.refreshContent()
    })
}


handleDownload = data => {
  console.log(data)
  this.setState({pdfloading:true})
  axios.post(`https://api.pureptinc.com/payrolls/pdf`, this.state).then(response =>  {
 
    setTimeout(function(){ 
      
      const link = document.createElement('a');
      link.href =  'https://pure-doc.s3-us-west-1.amazonaws.com/payroll-'+data+'.pdf';
      document.body.appendChild(link);
      link.click();
      this.setState({pdfloading:false})
       
     }, 3000);
  

  });

} 
 
 
 render() {

  const status = [
    {key: 1, text: 'Not Paid', value:'notpaid'},
    {key: 2, text: 'Paid', value:'paid'},
    {key: 3, text: 'Rejected', value:'rejected'}
  ]


  const authdata = Auth.isAuthenticated()
    
   return (
      <div>   

<Confirm
          size={'tiny'}
          open={this.state.confirm}
          confirmButton={'Yes'}
          content={'Are you sure you want to delete this payroll document?'}
          onCancel={()=>{this.setState({confirm:false})}}
          onConfirm={()=>{ 
              this.setState({confirm:false})
              this.handleDelete(this.state.selectedtherapist)

           }}
        />
         
             <Container>
  
   
            
   <Grid  divided>
    <Grid.Row>
      <Grid.Column  width={5}>
      <Header as='h2'>
     
     <Header.Content>
       
     {this.state.therapistname}
       <Header.Subheader>Payroll # {this.state._id}</Header.Subheader>
        
     </Header.Content>
   </Header> 
      </Grid.Column>
      <Grid.Column  width={8}>
      From:<Label color={'green'}><Moment date={this.props.location.state.from} format="L"  /> </Label>- To: <Label color={'red'}><Moment date={this.props.location.state.to} format="L"  /> </Label> Status <Label color={'violet'}>{this.state.status}</Label> 
      </Grid.Column>
      <Grid.Column  width={3}>
      <Button loading={this.state.pdfloading} icon labelPosition='left' color={'yellow'}    onClick={() => { this.handleDownload(this.state._id) }}   ><Icon name='file pdf' />Download PDF</Button>
      </Grid.Column>
    </Grid.Row>

     
  </Grid>
  <Segment basic>
          <Dimmer active={this.state.dimmer} inverted>
      <Loader />
    </Dimmer>
         

    {this.state.schedules.map((schedule, index) => (

        <div>
             <Header>{schedule.refferal ? 'Refferal' : 'Regular'} <Label color='black' horizontal> $ {schedule.refferal ? schedule.therapistreffrate : schedule.therapistrate} </Label></Header>
              <Table   singleLine  >
                      
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Date</Table.HeaderCell>
                          <Table.HeaderCell>Patient Name</Table.HeaderCell>
                          <Table.HeaderCell>Clock In/Out</Table.HeaderCell>
                         
                          <Table.HeaderCell>Status</Table.HeaderCell>
                          
                          
                        </Table.Row>
                      </Table.Header>
          
                      <Table.Body>
                      <Divider hidden />
                      {schedule.schedules.map((schedule, index) => (
                            <Table.Row key={index}>
                            <Table.Cell> <Moment format="LL">{schedule.start}</Moment> </Table.Cell>
                            <Table.Cell> {schedule.patient ? schedule.patient.patientname : <Label color='red'>Deleted Patient</Label>} </Table.Cell> 
                            <Table.Cell> <Moment format="hh:mm a" >{schedule.time_in}</Moment> - <Moment  format="hh:mm a">{schedule.time_out}</Moment> </Table.Cell> 
                             
                            <Table.Cell>  <Label color={schedule.status === 'discharge' ? 'red' : (schedule.status === 'evaluate' ? 'blue' : (schedule.status === 'reeval' ? 'teal' : (schedule.status === 'refusal' ? 'yellow' : (schedule.status === 'visit' ? 'green' : (schedule.status === 'missvisit' ? 'violet' : 'orange')))))}  >{schedule.status}</Label> </Table.Cell> 
                              
                      </Table.Row> 
                      ))}
          
                      </Table.Body>
                      
                      <Table.Footer>
                        <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell />
                        <Table.HeaderCell />
                        
                          <Table.HeaderCell colSpan='5'>
                             Total:  ${schedule.total}
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                      </Table>
                      <Divider hidden />
        </div>
    ))}
         
         {
          authdata.role === 'company' ?  <Form onValidSubmit={this.handleSubmit}>
          <Form.Group widths={3}>

         <Form.Input required onChange={this.handleChange} name='count' label='Count'   value={this.state.count}  />
         <Form.Input labelPosition='right' type='text' required onChange={this.handleChange} name='total' label='Rate' placeholder='50' value={this.state.total}  > <Label basic>$</Label><input /><Label>.00</Label></Form.Input>
         <Form.Field>
         <label> &nbsp; </label>
         <Dropdown
            placeholder='Select Status'
            fluid
            selection 
            defaultValue={this.state.status}
            name="status"
            options={status}
            onChange={this.statusChange}
        />


       
</Form.Field>
         <Form.Field>
             <label> &nbsp; </label>
             <Button color={'blue'} loading={this.state.loading}   content="Submit"   fluid type='submit'>Update</Button></Form.Field>
          
       </Form.Group></Form> : null
        }


        
          </Segment>
  
             </Container>
            
      </div>
   );
 }
}
 
export default PayrollDetail;
