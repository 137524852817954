import React from 'react'; 
import {Component} from 'react'; 
import { Accordion, Table, Loader, Divider, Container, Header, Popup, Icon, Button, Grid, Segment, Dropdown, Dimmer, Label } from 'semantic-ui-react';
import axios from 'axios';
import Moment from 'react-moment';
import { BrowserRouter as Link} from "react-router-dom";
import Auth from '../../validators/auth';
import {
    Form } from 'formsy-semantic-ui-react';
    
 
class InvoiceDetail extends React.Component {
  constructor(props) {
    super(props);
    this.props = props; 
    console.log(this.props)
    this.state = {
      patients:[],
      pdfloading:false,  
      agency:[]
   };
 
  }
 
   
 
  componentDidMount(){
    
    this.refreshContent();
  }

  refreshContent(){
    
    axios.get(`https://api.pureptinc.com/invoices/`+this.props.location.state.id).then(res => {
   
        console.log(res)
        this.setState({patients:res.data[0].patients})
        axios.get(`https://api.pureptinc.com/agencies/`+res.data[0].agencyid).then(res => { 
          this.setState({agency:res.data[0]})
      });  
    });  

   
}

  handleDelete = (data) => {
    
       
        axios.put(`https://api.pureptinc.com/invoices/`+this.props.location.state.id, 
            { '$pull': { 'patients.$[2].schedules': {  '_id': data.id } } }
            
          ).then((res) => {
            console.log(res)
            this.refreshContent()
          }); 
  }

  handleChange = event => {
    this.name = event.target.name;
    this.setState({ [event.target.name]: event.target.value });
 }

 statusChange = (event, {name, value}) => {
  
   
  this.setState({
    [name]: value  
  })
  
}

 handleDownload = data => {
    console.log(data)
    this.setState({pdfloading:true})
    axios.post(`https://api.pureptinc.com/invoices/pdf`, this.state).then(response =>  {
   
      setTimeout(function(){ 
        
        const link = document.createElement('a');
        link.href =  'https://pure-doc.s3-us-west-1.amazonaws.com/invoice-'+data+'.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.setState({pdfloading:false})
       }, 3000);
    
  
    });
  
  } 
 
  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

 handleSubmit = () => {

 
            
    axios.put(`https://api.pureptinc.com/invoices/`+this.props.location.state.id, {
         count:this.state.count,
         total:this.state.total,
         status:this.state.status
    })
    .then(res => {
        this.refreshContent()
    })
}
 
 
 render() {

  const status = [
    {key: 1, text: 'Pending', value:'pending'},
    {key: 2, text: 'Completed', value:'completed'},
    {key: 3, text: 'Rejected', value:'rejected'}
  ]

  const { activeIndex } = this.state
  const authenteicated =  Auth.isAuthenticated();
    
   return (
      <div>   
         
             <Container>
   
   
             <Segment basic>

             
             

   <Grid  divided>
    <Grid.Row>
      <Grid.Column  width={3}>
      <Header as='h2'>
     
     <Header.Content>
       
       {this.state.agency.name}
       <Header.Subheader>Invoice # {this.state.invoicenum}</Header.Subheader>
        
     </Header.Content>
   </Header> 
      </Grid.Column>
      <Grid.Column  width={10}>
      From:<Label color={'green'}><Moment date={this.props.location.state.from} format="L"  /> </Label>- To: <Label color={'red'}><Moment date={this.props.location.state.to} format="L"  /> </Label> Status <Label color={'violet'}>{this.state.status}</Label> 
      </Grid.Column>
      <Grid.Column  width={3}>
      <Button loading={this.state.pdfloading} icon labelPosition='left' color={'yellow'}    onClick={() => { this.handleDownload(this.props.location.state.id) }}   ><Icon name='file pdf' />Download PDF </Button>
      </Grid.Column>
    </Grid.Row>

     
  </Grid>

   
             </Segment>
  <Segment basic>
          <Dimmer active={this.state.dimmer} inverted>
      <Loader />
    </Dimmer>
         

         <Accordion fluid styled>

         {this.state.patients.map((patient, index) => (
         
              <div>
                <Accordion.Title
              active={activeIndex === index}
              index={index}
              onClick={this.handleClick}
            >
              <Icon name='dropdown' />
              {patient.patient ? patient.patient.patientname : <Label color='red'>Deleted Patient</Label>}
            </Accordion.Title>
            <Accordion.Content  active={activeIndex === index}>
            <Table   singleLine  >
           
           <Table.Header>
             <Table.Row>
                <Table.HeaderCell>Patient Name</Table.HeaderCell> 
               <Table.HeaderCell>Date</Table.HeaderCell>
               <Table.HeaderCell>Status</Table.HeaderCell> 
                
               <Table.HeaderCell></Table.HeaderCell>
                
             </Table.Row>
           </Table.Header>
        
           <Table.Body>
           
           {patient.schedules.map((schedule, key) => (
                <Table.Row key={key}>
                      <Table.Cell> {patient.patient ? patient.patient.patientname : <Label color='red'>Deleted Patient</Label>}  </Table.Cell> 
                    <Table.Cell> {schedule.start} </Table.Cell> 
                    <Table.Cell> {schedule.status}  </Table.Cell>  
              
              <Table.Cell>   {/*  <Popup
                        trigger={
                            <Button color='orange' fluid  icon='trash' content='Delete' />
                        }
                        content={<Button color={'red'}   onClick={() => { this.handleDelete({id:schedule._id, index:index}) }}  fluid>Confirm deletion ?</Button>}
                        on='click'
                        position='top right'

                        /> */} </Table.Cell> 
              </Table.Row> 
           ))}
       
           </Table.Body>
           <Table.Footer fullWidth>
                       
           </Table.Footer>
         </Table>
            </Accordion.Content>
              </div>
           ))}
       
 
      </Accordion>

      <Divider hidden />

      {authenteicated.role === 'company' ?   <Form onValidSubmit={this.handleSubmit}>
                     <Form.Group widths={3}>

                    <Form.Input disabled required onChange={this.handleChange} name='count' label='Count'   value={this.state.count}  />
                    <Form.Input disabled labelPosition='right' type='text' required onChange={this.handleChange} name='total' label='Rate' placeholder='50' value={this.state.total}  > <Label basic>$</Label><input /><Label>.00</Label></Form.Input>
                    <Form.Field>
                    <label> &nbsp; </label>
                   <Dropdown
                    placeholder='Select Status'
                    fluid
                    selection 
                    defaultValue={this.state.status}
                    name="status"
                    options={status}
                    onChange={this.statusChange}
                  />
  
 
                  
       </Form.Field>
                    <Form.Field>
                        <label> &nbsp; </label>
                        <Button color={'blue'} loading={this.state.loading}   content="Submit"   fluid type='submit'>Update</Button></Form.Field>
                     
                  </Form.Group></Form> : null }
        
          </Segment>
  
             </Container>
            
      </div>
   );
 }
}
 
export default InvoiceDetail;
