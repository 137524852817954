import React from "react";
import {  Grid, Table, Radio, Button, Label, Segment, Header, Divider } from "semantic-ui-react";
import moment from 'moment'
import {
  Form, Dropdown, Checkbox
} from 'formsy-semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import {
  DateInput,
  TimeInput,
  DateTimeInput,
  DatesRangeInput
} from 'semantic-ui-calendar-react';
import axios from 'axios';
import 'semantic-ui-css/semantic.min.css'


class PhysicalTherapyEval extends React.Component {
  constructor(props) {
    super(props); 
   
    
    this.props=props;
    this.state = {
      "job_id": this.props.job_id,
      "type":this.props.type, 
      "agency":this.props.agency,
      "created_at": new Date,
      "updated_at": new Date, 
      "jobdetail":{},
      "data":{ 
      "goals_bed_grades": "",
      "goals_strenght_weeks": "",
      "goals_balance_grades": "",
      "goals_rom_weeks": "",
      "goals_gait_grades": "",
      "strength_lue": "",
      "goals_transfer_grades": "",
      "goals_pain_grades": "",
      "reqequipment":[],
      "problems": [
        "Transfers",
        "Gait",
        "Stairs",
        "Balance",
        "Strenght",
        "Safety",
        "ROM",
        "HEP",
        "BCG Knowledge",
        "Pain",
        "Bed Mobility",
        "ROM"
      ],
      "livingtype": "",
      "tx_rendered": "",
      "limit_speach": false,
      "limit_respiratory": false,
      "edema_na": false,
      "sensation_wfl": false,
      "strength_lle": "",
      "strength_rue": "",
      "strength_rle": "",
      "strength_mmt": "",
      "cogition_able": true,
      "cogition_unable": false,
      "priorlvloffunction": "Caregiver’s assistance with all activities, ADL’s and ambulation.",
      "gender": "",
      "patientname": "",
      "md": "",
      "mr": "",
      "age": "",
      "physicaltherapydx": "Unsteady gait, Muscles weakness, decline functional level",
      "medicalhx": "",
      "vitalbp": "",
      "vitalhr": "",
      "vitalrr": "",
      "vitaltemp": "",
      "txplan": [
        "Bed Mobility Training",
        "Transfers Training",
        "Gait Training",
        "Stairs Training",
        "Balance Training",
        "Strengthening",
        "ROM",
        "Instruct in HEP",
        "Safety Education",
        "Decrease Pain",
        "Stretching",
        "CG Training",
        "Ther ex",
        "ROM"
      ],
      "vitalptequipment": [],
      "livingtype": "",
      "livingstairs": "",
      "bed_roll": "",
      "bed_scoot": "",
      "bed_sup": "",
      "transfer_sit_stand": "",
      "transfer_bed_wc": "",
      "transfer_ability": false,
      "gait_dist": "",
      "gait_assitant": "",
      "gait_assistive_device":"",
      "gait_analysis": "Short Steps, shuffling",
      "gait_posture": "Bending forward.",
      "gait_ability": false,
      "stairs_assitant": "",
      "stairs_siderail": false,
      "stairs_na": false,
      "balance_sit": "",
      "balance_stand": "",
      "limit_vision": [],
      "limit_hearing": "",
      "limit_other": "",
      "cogition_alert": "X3",
      "strength_note": "",
      "rom_note": "",
      "pain_location": [],
      "edema_location": "",
      "edema_option": "",
      "sensation": "",
      "problems_rom": "",
      "problems_other": "",
      "txplan_rom": "",
      "txplan_other": "Stretches with 10 second holds",
      "txplan_freq": "",
      "goals_bed_weeks": "",
      "goals_transfer_weeks": "",
      "goals_gait_weeks": "",
      "goals_balance_weeks": "",
      "goals_pain_weeks": "",
      "goals_strenght_grades": "",
      "goals_rom_grades": "",
      "homeboundstatus": "Unable to leave home unassisted",
      "pain_note":"MD will be notified if the pain level increases more than above",
      "complition_date":"",
      "discharge_plan": "Home with HEP",
      "date":"",
      "timein":"",
      "timeout":"",
      "rom_wfl":false
    }
    }
    
  }

  componentDidMount(){

    axios.get(`https://api.pureptinc.com/jobs/`+this.state.job_id )
    .then(res => {
      console.log(res.data[0])
       this.setState({jobdetail:res.data[0]})
    })   

    if(this.props.new){
     
    }
    else {
      this.setState({
        data:this.props.data
      })
    }
      
  }
 
  handleDropChange = (event, {name, value}) => {
     
    this.setState(prevState => ({
      data: {                    
        ...prevState.data,    
        [name]: value    
    }
  }))
  }

  checkboxChangeHandler = (event, {name, checked}) => {
     
    this.setState(prevState => ({
      data: {                    
        ...prevState.data,    
        [name]: checked    
    } 
  }))
    
  };
   

  handleChange = (event, data) => {
    console.log(data)
    this.name = event.target.name;
    
    this.setState(prevState => ({
      data: {                    
        ...prevState.data,    
        [event.target.name]: event.target.value   
    }
       
  }))
 }

 handleDateChange = (event, {name, value}) => {
   
  
 
  this.setState(prevState => ({
  
    data: {                    
      ...prevState.data,    
      [name]: value   
  }
}))
  
}

handleRadioChange = (event, {name, value}) => {
     
  this.setState(prevState => ({
    data: {                    
      ...prevState.data,    
      [name]: value    
  }
}))
}
 
 handleSubmit = () => {
  this.setState({ 
    dimmer: true
  }) 

  axios.post(`https://api.pureptinc.com/forms/`, this.state)
  .then(res => {
    this.setState({loading:false});
    this.props.refreshContent(this.state.job_id)       
    this.props.setCloseForm()       
  })       
   
     
}
 
  render() {
 
    const errorLabel = <Label color="red" pointing="left" />;

    const respiratoryrate = [
        { text: '16', value: '16' },
        { text: '17', value: '17' },
        { text: '18', value: '18' },
        { text: '19', value: '19' },
        { text: '20', value: '20' },
        { text: '21', value: '21' }  
      ];

      const pains = [
        { text: 'C/S', value: 'C/S' },
        { text: 'T/S', value: 'T/S' },
        { text: 'L/S', value: 'L/S' },
        { text: 'Shoulders', value: 'Shoulders' },
        { text: 'Elbows', value: 'Elbows' },
        { text: 'Wrists', value: 'Wrists' },
        { text: 'Hips', value: 'Hips' },
        { text: 'Knees', value: 'Knees' },
        { text: 'Ankles', value: 'Ankles' },
        { text: 'Lower Back', value: 'Lower Back' }   
      ];
  

    const vision = [
      { text: 'Lt', value: 'Lt' },
      { text: 'Rt', value: 'Rt' },
      { text: 'Both', value: 'Both' },
      { text: 'Blind', value: 'Blind' } 
    ];  

    const rom = [
      { text: 'WFL', value: 'WFL' },
      { text: 'WNL', value: 'WNL' }  
    ]; 

    const hearing = [
      { text: 'Lt', value: 'Lt' },
      { text: 'Rt', value: 'Rt' },
      { text: 'Both', value: 'Both' },
      { text: 'Deaf', value: 'Deaf' } 
    ];  

    const temp = [
        { text: '98.1', value: '98.1' },
        { text: '98.2', value: '98.2' },
        { text: '98.3', value: '98.3' },
        { text: '98.4', value: '98.4' },
        { text: '98.5', value: '98.5' },
        { text: '98.6', value: '98.6' }  
      ]; 

      const living = [
        { text: 'Apt', value: 'Apt' },
        { text: 'House', value: 'House' },
        { text: 'B&C', value: 'B&C' },
        { text: 'Mobile', value: 'Mobile' },
        { text: 'Facility', value: 'Facility' },
      ]; 
      
      const livingstatus = [
        { text: 'Alone', value: 'Alone' },
        { text: 'With family/friend', value: 'With family/friend' },
        { text: 'Caregiver', value: 'Caregiver' } 
      ]; 

    const equipments = [
        {name:'ptequipment', text:"FWW", value: "FWW"},
        {name:'ptequipment', text:"4WW", value: "4WW"},
        {name:'ptequipment', text:"PUW", value: "PUW"},
        {name:'ptequipment', text: "Shower chair/tub bench", value: "Shower chair/tub bench"},
        {name:'ptequipment', text: "Raised toilet seat", value: "Raised toilet seat"},
        {name:'ptequipment', text: "SPC", value: "SPC"},
        {name:'ptequipment', text: "Hosp bed", value: "Hosp bed"},
        {name:'ptequipment', text: "Hoyer lift", value: "Hoyer lift"},
        {name:'ptequipment', text: "HW", value: "HW"},
        {name:'ptequipment', text: "Oxygen tube", value: "Oxygen tube"},
        {name:'ptequipment', text: "Commode", value: "Commode"},
        {name:'ptequipment', text: "W/C", value: "W/C"},
        {name:'ptequipment', text: "QC", value: "QC"}, 
        {name:'ptequipment', text: "Crutches", value: "Crutches"} 
    ]

    const grades = [
        { text: 'P/P-', value: 'P/P-' },
        { text: 'P+/P', value: 'P+/P' },
        { text: 'F-/P+', value: 'F-/P+' },
        { text: 'F/P+', value: 'F/P+' },
        { text: 'F+/P+', value: 'F+/P+' },
        { text: 'F+/F-', value: 'F+/F-' },
        { text: 'G-/F-', value: 'G-/F-' },
        { text: 'G-/F', value: 'G-/F' },
        { text: 'G/F', value: 'G/F' },
        { text: 'G+/F+', value: 'G+/F+' },
        { text: 'G+/G-', value: 'G+/G-' } 

    ]

    const strenght = [
      { text: '0/5 MMT', value: '0/5 MMT' },
      { text: '1-/5 MMT', value: '1-/5 MMT' },
      { text: '1/5 MMT', value: '1/5 MMT' },
      { text: '1+/5 MMT', value: '1+/5 MMT' },
      { text: '2-/5 MMT', value: '2-/5 MMT' },
      { text: '2/5 MMT', value: '2/5 MMT' },
      { text: '2+/5 MMT', value: '2+/5 MMT' },
      { text: '3-/5 MMT', value: '3-/5 MMT' },
      { text: '3/5 MMT', value: '3/5 MMT' },
      { text: '3+/5 MMT', value: '3+/5 MMT' }, 
      { text: '4-/5 MMT', value: '4-/5 MMT' },
      { text: '4/5 MMT', value: '4/5 MMT' },
      { text: '4+/5 MMT', value: '4+/5 MMT' } 
      
  ]

  const pain = [
    { text: '0/10', value: '0/10' },
    { text: '1/10', value: '1/10' },
    { text: '2/10', value: '2/10' },
    { text: '3/10', value: '3/10' },
    { text: '4/10', value: '4/10' },
    { text: '5/10', value: '5/10' },
    { text: '6/10', value: '6/10' },
    { text: '7/10', value: '7/10' },
    { text: '8/10', value: '8/10' },
    { text: '9/10', value: '9/10' },
    { text: '10/10', value: '10/10' },

    
]

const edema = [
  { text: 'Min', value: 'min' },
  { text: 'Mod', value: 'mod' },
  { text: 'Sever', value: 'sever' }  
]

const problems = [
  { text: 'Bed Mobility', value: 'Bed Mobility' },
  { text: 'Transfers', value: 'Transfers' },
  { text: 'Gait', value: 'Gait' },
  { text: 'Stairs', value: 'Stairs' },
  { text: 'Balance', value: 'Balance' },
  { text: 'Strength', value: 'Strength' },
  { text: 'Safety', value: 'Safety' },
  { text: 'ROM', value: 'ROM' },
  { text: 'HEP', value: 'HEP' },
  { text: 'CG Knowledge', value: 'BCG Knowledge' },
  { text: 'Pain', value: 'Pain' },
  { text: 'ROM', value: 'ROM' }   
]

const defaultproblems = [
  'Bed Mobility', 'Transfers', 'Gait', 'Stairs', 'Balance', 'Strength', 'Safety', 'ROM', 'HEP', 'BCG Knowledge', 'Pain'
]

const txplan = [
 
  { text: 'Bed Mobility Training', value: 'Bed Mobility Training' },
  { text: 'Transfers Training', value: 'Transfers Training' },
  { text: 'Gait Training', value: 'Gait Training' },
  { text: 'Stairs Training', value: 'Stairs Training' },
  { text: 'Balance Training', value: 'Balance Training' },
  { text: 'Strengthening', value: 'Strengthening' },
  { text: 'Stretching', value: 'Stretching' },
  { text: 'ROM', value: 'ROM' },
  { text: 'Instruct in HEP', value: 'Instruct in HEP' },
  { text: 'Safety Education', value: 'Safety Education' },
  { text: 'CG Training', value: 'CG Training' },
  { text: 'Decrease Pain', value: 'Decrease Pain' },
  { text: 'Ther ex', value: 'Ther ex' },
  { text: 'ROM', value: 'ROM' },
]

const defaulttxplan = [
 
  'Bed Mobility Training', 'Transfers Training', 'Gait Training',  'Stairs Training', 'Balance Training', 'Strengthening', 'ROM', 'Instruct in HEP', 'Safety Education', 'CG Training', 'Decrease Pain', 'Ther ex'
]


const complition_weeks = [
  { text: '1 Week', value: '1 Week' },
  { text: '1 to 3 weeks', value: '1 to 3 weeks' },
  { text: '2 to 4 weeks', value: '2 to 4 weeks' },
  { text: '3 to 5 weeks', value: '3 to 5 weeks' },
  { text: '4 to 6 weeks', value: '4 to 6 weeks' },
  { text: '5 to 7 weeks', value: '5 to 7 weeks' },
  { text: '6 to 8 weeks', value: '6 to 8 weeks' },
  { text: '7 to 9 weeks', value: '7 to 9 weeks' },
  { text: '8 to 10 weeks', value: '8 to 10 weeks' },
  { text: '9 to 11 weeks', value: '9 to 11 weeks' },
  { text: '12 to 14 weeks', value: '12 to 14 weeks' },
  { text: '13 to 15 weeks', value: '13 to 15 weeks' },
  { text: '14 to 16 weeks', value: '14 to 16 weeks' },
  { text: '15 to 17 weeks', value: '15 to 17 weeks' },
  { text: '16 to 18 weeks', value: '16 to 18 weeks' },
  { text: '17 to 19 weeks', value: '17 to 19 weeks' },
  { text: '18 to 20 weeks', value: '18 to 20 weeks' } 
]

const weeks = [
  { text: '1 Week', value: '1 Week' },
  { text: '2 Weeks', value: '2 Weeks' },
  { text: '3 Weeks', value: '3 Weeks' },
  { text: '4 Weeks', value: '4 Weeks' },
  { text: '5 Weeks', value: '5 Weeks' },
  { text: '6 Weeks', value: '6 Weeks' },
  { text: '7 Weeks', value: '7 Weeks' },
  { text: '8 Weeks', value: '8 Weeks' },
  { text: '9 Weeks', value: '9 Weeks' },
  { text: '10 Weeks', value: '10 Weeks' },
  { text: '11 Weeks', value: '11 Weeks' },
  { text: '12 Weeks', value: '12 Weeks' },
  { text: '13 Weeks', value: '13 Weeks' },
  { text: '14 Weeks', value: '14 Weeks' },
  { text: '15 Weeks', value: '15 Weeks' },
  { text: '16 Weeks', value: '16 Weeks' },
  { text: '17 Weeks', value: '17 Weeks' },
  { text: '18 Weeks', value: '18 Weeks' },
  { text: '19 Weeks', value: '19 Weeks' },
  { text: '20 Weeks', value: '20 Weeks' } 
  
]


const gender = [
  { text: 'Male', value: 'Male' },
  { text: 'Female', value: 'Female' },
  { text: 'Other', value: 'Other' }
]

const goals = [
  { text: 'Unable', value: 'Unable' },
  { text: 'Total-A', value: 'Total-A' },
  { text: 'MAX-A', value: 'sever' },
  { text: 'MAX/MOD-A', value: 'MAX/MOD-A' },
  { text: 'MOD-A', value: 'MOD-A' },
  { text: 'MOD/MIN-A', value: 'MOD/MIN-A' },
  { text: 'MIN-A', value: 'MIN-A' },
  { text: 'MIN-A/CGA', value: 'MIN-A/CGA' },
  { text: 'CGA', value: 'CGA' },
  { text: 'CGA/SBA', value: 'CGA/SBA' },
  { text: 'SBA', value: 'SBA' },
  { text: 'SBA/S', value: 'SBA/S' },
  { text: 'S', value: 'S' },
  { text: 'S/INDP', value: 'S/INDP' },
  { text: 'INDP', value: 'INDP' }
]


    return (
      <div className="container"> 
        <div className="formcontainer">
          <Header textAlign="center">Physical Therapy Evaluation and Plan of Care</Header>
    
          <Header.Subheader  textAlign="center">Agency: {this.state.agency}</Header.Subheader>
          <Divider></Divider>
          <Form onValidSubmit={this.handleSubmit}>
          <Form.Group widths="5">

        <Form.Input  fluid   disabled  label="Patient:"  placeholder="John Doe" value={this.state.jobdetail.patientname}  onChange={this.handleChange}  name='patientname'  />
        <Form.Input  fluid     label="MD:"  value={ this.state.data.md}  onChange={this.handleChange}  name='md'  />
        <Form.Input  fluid     label="MR:"  value={ this.state.data.mr}  onChange={this.handleChange}  name='mr'  />
        <Form.Input  fluid     label="Age:" value={ this.state.data.age}   onChange={this.handleChange}  name='age'  />
        <Form.Dropdown fluid label="Gender:"  value={ this.state.data.gender}  name="gender"  placeholder="Select Gender"   validationErrors={{   isDefaultRequiredValue: 'You need to select a gender',    }} errorLabel={errorLabel} options={gender} selection   clearable    onChange={this.handleDropChange}   />

        </Form.Group>

        <Segment>
        <Grid columns={2} divided>
            <Grid.Row>
                 <Grid.Column>
                     <Form.Input  fluid  value={ this.state.data.physicaltherapydx}     label="PHYSICAL THERAPY DX:"   onChange={this.handleChange}  name='physicaltherapydx'  />
                     <Form.Input  fluid  value={ this.state.data.medicalhx}    label="MEDICAL HX:"   onChange={this.handleChange}  name='medicalhx'  />
                     <Segment>
                         <Header>
                             Vitals
                             
                         </Header>

                         <Form.Group widths="2">
                             <Form.Input  value={ this.state.data.vitalbp} fluid     label="BP:"  placeholder="Blood Preasure"   onChange={this.handleChange}  name='vitalbp'  />
                             <Form.Input  value={ this.state.data.vitalhr} fluid     label="HR:"  placeholder="Heart Rate"   onChange={this.handleChange}  name='vitalhr'  />
                             </Form.Group>
                             <Form.Group widths="2">
                             <Form.Dropdown
                             fluid
                                   label="RR:" 
                                    name="vitalrr"
                                    placeholder="Select Value" 
                                    validationErrors={{
                                    isDefaultRequiredValue: 'You need to select a value',
                                    }}
                                    errorLabel={errorLabel}
                                    options={respiratoryrate}
                                    selection
                                    clearable
                                    onChange={this.handleDropChange}
                                    value={ this.state.data.vitalrr}
                                />

                                <Form.Dropdown
                                   fluid     
                                   label="Temp:" 
                                    name="vitaltemp"
                                    placeholder="Select Value" 
                                    validationErrors={{
                                    isDefaultRequiredValue: 'You need to select a value',
                                    }}
                                    errorLabel={errorLabel}
                                    options={temp}
                                    selection
                                    clearable
                                    onChange={this.handleDropChange}
                                    value={ this.state.data.vitaltemp}
                                />
                             </Form.Group>

                            
                             <Form.Dropdown
                                   fluid     
                                   label="EQUIPMENT PT HAS:" 
                                    name="vitalptequipment"
                                    placeholder="Select Value" 
                                    validationErrors={{
                                    isDefaultRequiredValue: 'You need to select a value',
                                    }}
                                    errorLabel={errorLabel}
                                    options={equipments}
                                    selection
                                    multiple
                                    onChange={this.handleDropChange}
                                    value={ this.state.data.vitalptequipment}
                                />
                            
                            
                     </Segment>
                     <Form.Input value={ this.state.data.homeboundstatus}  fluid     label="HOMEBOUND STATUS:" defaultValue="Unable to leave home unassisted"   onChange={this.handleChange}  name='homeboundstatus'  />
                 </Grid.Column>
                 <Grid.Column>

                    <Form.Input value={ this.state.data.priorlvloffunction} fluid defaultValue="Caregiver’s assistance with all activities, ADL’s and ambulation."    label="PRIOR LEVEL OF FUNCTION:"   onChange={this.handleChange}  name='priorlvloffunction'  />
                    <Segment secondary>
                        <Header>Living Situation</Header>
                                <Form.Dropdown
                                   fluid      
                                    name="livingtype"
                                    label="Type:" 
                                    placeholder="Type" 
                                    validationErrors={{
                                    isDefaultRequiredValue: 'You need to select a type',
                                    }}
                                    errorLabel={errorLabel}
                                    options={living}
                                    selection 
                                    onChange={this.handleDropChange}
                                    value={ this.state.data.livingtype}
                                />
                                <Form.Dropdown
                                   fluid      
                                    name="livingstatus"
                                    label="Living Status:" 
                                    placeholder="Status" 
                                    validationErrors={{
                                    isDefaultRequiredValue: 'You need to select a type',
                                    }}
                                    errorLabel={errorLabel}
                                    options={livingstatus}
                                    selection 
                                    onChange={this.handleDropChange}
                                    value={ this.state.data.livingstatus}
                                />
                                
                                 <Form.Input value={ this.state.data.livingstairs} fluid     label="stairs/steps:"   onChange={this.handleChange}  name='livingstairs'  />
                                 <Form.Checkbox defaultChecked={ this.state.data.elevator} label="Elevator"   onChange={this.checkboxChangeHandler}  name='elevator' ></Form.Checkbox>
                                 <Form.Checkbox defaultChecked={ this.state.data.stairs}  label="No Stairs or Steps"   onChange={this.checkboxChangeHandler}  name='stairs' ></Form.Checkbox>
                    </Segment>
                    
                    <Form.Input value={ this.state.data.percautions} fluid  defaultValue="High risk of fall"  label="PRECAUTIONS:"   onChange={this.handleChange}  name='percautions'  />
                    <Form.Dropdown
                                   fluid     
                                   label="EQUIPMENT RECOMMENDED:" 
                                    name="reqequipment"
                                    placeholder="Select Value" 
                                    validationErrors={{
                                    isDefaultRequiredValue: 'You need to select a value',
                                    }}
                                    errorLabel={errorLabel}
                                    options={equipments}
                                    selection
                                    multiple
                                    onChange={this.handleDropChange}
                                    value={ this.state.data.reqequipment}
                                />
                 </Grid.Column>
            </Grid.Row>
        </Grid>
        </Segment>
        <Segment>
            <Grid columns={2} divided>
                <Grid.Row>
                    <Grid.Column>
                        <Header>Bed Mobility:</Header>
                        <Form.Group widths='3'>
                            
                             <Form.Dropdown
                              fluid     
                              label="Roll:" 
                              name="bed_roll"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={goals}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.bed_roll}
                         />


                          <Form.Dropdown
                              fluid     
                              label="Scoot:" 
                              name="bed_scoot"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={goals}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.bed_scoot}
                         />

                          <Form.Dropdown
                              fluid     
                              label="Sup to sit:" 
                              name="bed_sup"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={goals}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.bed_sup}
                         /> 
                        </Form.Group>
                        <Header>Transfers:</Header>
                        <Form.Group widths='3'>
                             
                            <Form.Dropdown
                              fluid     
                              label="Sit to stand:" 
                              name="transfer_sit_stand"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={goals}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.transfer_sit_stand}
                         /> 

                            <Form.Dropdown
                              fluid     
                              label="Bed to W/C:"
                              name="transfer_bed_wc"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={goals}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.transfer_bed_wc}
                         /> 
                            
                            
                            
                            <Form.Field>
                                <lable>&nbsp;</lable>
                               <Form.Checkbox  defaultChecked={ this.state.data.transfer_ability} label="Unable"  name="transfer_ability"  onChange={this.checkboxChangeHandler} ></Form.Checkbox>            
                            </Form.Field>
                        </Form.Group>
                        <Header>Gait:</Header>
                        <Form.Group widths='2'>
                            <Form.Input value={ this.state.data.gait_dist} fluid     label="Distance:"   onChange={this.handleChange}  name='gait_dist'  />
                             
                                
                            <Form.Dropdown
                              fluid     
                              label="Assist:"
                              name="gait_assitant"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={goals}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.gait_assitant}
                         /> 

                          <Form.Dropdown
                              fluid     
                              label="Assistive Device:"
                              name="gait_assistive_device"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={equipments}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.gait_assistive_device}
                         /> 

                         

                         
                        </Form.Group>

                        <Form.Group widths='3'>
                        <Form.Input value={ this.state.data.gait_analysis} fluid     label="Analysis:"   onChange={this.handleChange}  name='gait_analysis'  />

                        <Form.Input value={ this.state.data.gait_posture} fluid     label="Posture:"   onChange={this.handleChange}  name='gait_posture'  /> 

                        <Form.Field>
                        <lable>&nbsp;</lable>
                        <Form.Checkbox defaultChecked={ this.state.data.gait_ability} label="Unable"  name="gait_ability"  onChange={this.checkboxChangeHandler} ></Form.Checkbox>            
                        </Form.Field> 
                        </Form.Group>
                        <Header>Stairs:</Header>
                        <Form.Group widths='3'>
                            
                             
                            <Form.Dropdown
                              fluid     
                              label="Assist:"
                              name="stairs_assitant"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={goals}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.stairs_assitant}
                         /> 

                            <Form.Field>
                                <lable>&nbsp;</lable>
                               <Form.Checkbox defaultChecked={ this.state.data.stairs_siderail}  label="Siderail" toggle name="stairs_siderail"  onChange={this.checkboxChangeHandler} ></Form.Checkbox>            
                            </Form.Field> 
                            <Form.Field>
                                <lable>&nbsp;</lable>
                               <Form.Checkbox defaultChecked={ this.state.data.stairs_na}  label="N/A" toggle name="stairs_na"  onChange={this.checkboxChangeHandler} ></Form.Checkbox>            
                            </Form.Field> 
                        </Form.Group>  
                        <Header>Balance:</Header>           
                        <Form.Group widths='2'>
                            
                        <Form.Dropdown
                                    fluid     
                                    label="Sitting (Static/Dynamic):" 
                                    name="balance_sit"
                                    placeholder="Select Value" 
                                    validationErrors={{
                                    isDefaultRequiredValue: 'You need to select a value',
                                    }}
                                    errorLabel={errorLabel}
                                    options={grades}
                                    selection
                                    clearable
                                    onChange={this.handleDropChange}
                                    value={ this.state.data.balance_sit} 
                                />

                        <Form.Dropdown
                                    fluid     
                                    label="Standing (Static/Dynamic):" 
                                    name="balance_stand"
                                    placeholder="Select Value" 
                                    validationErrors={{
                                    isDefaultRequiredValue: 'You need to select a value',
                                    }}
                                    errorLabel={errorLabel}
                                    options={grades}
                                    selection
                                    clearable
                                    onChange={this.handleDropChange}
                                    value={ this.state.data.balance_stand} 
                                />
                           
                        </Form.Group>                    
                        <Header>Limitations:</Header>   
                        <Form.Group widths='2'>
                        <Form.Dropdown
                                   fluid     
                                   label="Vision:" 
                                    name="limit_vision"
                                    placeholder="Select Value" 
                                    validationErrors={{
                                    isDefaultRequiredValue: 'You need to select a value',
                                    }}
                                    errorLabel={errorLabel}
                                    options={vision}
                                    selection
                                    multiple
                                    clearable
                                    onChange={this.handleDropChange}
                                    value={ this.state.data.limit_vision} 
                                /> 
                        <Form.Dropdown
                            fluid     
                            label="Hearing:" 
                            name="limit_hearing"
                            placeholder="Select Value" 
                            validationErrors={{
                            isDefaultRequiredValue: 'You need to select a value',
                            }}
                            errorLabel={errorLabel}
                            options={hearing}
                            selection
                            multiple
                            clearable
                            onChange={this.handleDropChange}
                            value={ this.state.data.limit_hearing} 
                        /> 
                        </Form.Group>
                        <Form.Group widths='3'>
                             <Form.Field>
                                <lable>&nbsp;</lable>
                               <Form.Checkbox defaultChecked={ this.state.data.limit_respiratory}  label="Respiratory" toggle name="limit_respiratory"  onChange={this.checkboxChangeHandler} ></Form.Checkbox>            
                            </Form.Field> 
                            <Form.Field>
                                <lable>&nbsp;</lable>
                               <Form.Checkbox defaultChecked={ this.state.data.limit_speach}  label="Speech" toggle name="limit_speach"  onChange={this.checkboxChangeHandler} ></Form.Checkbox>            
                            </Form.Field> 
                           
                           <Form.Input  value={ this.state.data.limit_other} fluid     label="Other:"   onChange={this.handleChange}  name='limit_other'  />
                        </Form.Group>

                    </Grid.Column>
                    <Grid.Column>
                    <Header>Cognition:</Header>   
                    <Form.Group widths='3'>
                           <Form.Input value={ this.state.data.cogition_alert}  fluid label="Alert and oriented:"   onChange={this.handleChange}  name='cogition_alert'  />  
                          
                          <Form.Field>
                                <lable>&nbsp;</lable>
                               <Form.Checkbox  defaultChecked={ this.state.data.cogition_able}  label="Able to follow commands"   name="cogition_able"  onChange={this.checkboxChangeHandler} ></Form.Checkbox>            
                         </Form.Field>
                         <Form.Field>
                                <lable>&nbsp;</lable>
                               <Form.Checkbox defaultChecked={ this.state.data.cogition_unable}  label="Unable to follow commands"   name="cogition_unable"  onChange={this.checkboxChangeHandler} ></Form.Checkbox>            
                         </Form.Field> 
                    </Form.Group>  
                    <Header>Strength:</Header> 
                    
                    <Form.Group  widths='5'>
                         <Form.Dropdown
                              fluid     
                              label="LUE:" 
                              name="strength_lue"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={strenght}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.strength_lue}
                         />
                         <Form.Dropdown
                              fluid     
                              label="LLE:" 
                              name="strength_lle"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={strenght}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.strength_lle}
                         /> 
                         <Form.Dropdown
                              fluid     
                              label="RUE:" 
                              name="strength_rue"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={strenght}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.strength_rue}
                         /> 
                          <Form.Dropdown
                              fluid     
                              label="RLE:" 
                              name="strength_rle"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={strenght}
                              selection
                              clearable 
                              onChange={this.handleDropChange}
                              value={ this.state.data.strength_rle}
                         /> 
                           <Form.Dropdown
                              fluid     
                              label="Trunk:" 
                              name="strength_mmt"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={strenght}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.strength_mmt}
                         />
                    </Form.Group>      
                    <Header>ROM:</Header> 
                     <p>WFL all extremities OR Limitations as follows:</p> 
                     <Form.Group widths='2'>
                        <Form.Field>
                                    <lable>&nbsp;</lable>
                                  <Form.Checkbox  defaultChecked={ this.state.data.rom_wfl} label="WFL" toggle name="rom_wfl"  onChange={this.checkboxChangeHandler} ></Form.Checkbox>            
                          </Form.Field>      
                        <Form.Input label={'Other Limitations'} value={ this.state.data.edema_limitations}  fluid     onChange={this.handleChange}  name='rom_note'  />  
                     </Form.Group> 
                     <Header>PAIN:</Header> 
                      <Form.Group widths='2'>

                      <Form.Dropdown
                            fluid     
                            label="Location" 
                            name="pain_location"
                            placeholder="Select Value" 
                            validationErrors={{
                            isDefaultRequiredValue: 'You need to select a value',
                            }}
                            errorLabel={errorLabel}
                            options={pains}
                            selection
                            multiple
                            onChange={this.handleDropChange}
                            value={ this.state.data.pain_location}
                        />  
                     <Form.Dropdown
                              fluid     
                              label="Severity (0-10 scale)" 
                              name="pain_severity"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={pain}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.pain_severity}
                               
                         /> 
                      </Form.Group>
                      
                      <Form.Input value={ this.state.data.pain_note} fluid       onChange={this.handleChange}  name='pain_note'  />  
                      <Header>Edema:</Header> 
                      <Form.Group widths='3'>

                     <Form.Input value={ this.state.data.edema_location} fluid      label="Location"  onChange={this.handleChange}  name='edema_location'  />   
                     <Form.Dropdown
                              fluid     
                              label="Severity" 
                              name="edema_option"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={edema}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.edema_option}
                       /> 

                      <Form.Field>
                                <lable>&nbsp;</lable>
                               <Form.Checkbox  defaultChecked={ this.state.data.edema_na} label="N/A" toggle name="edema_na"  onChange={this.checkboxChangeHandler} ></Form.Checkbox>            
                      </Form.Field> 
                      </Form.Group>
                      <Header>Sensations/Propproception:</Header>        
                      <Form.Group widths="2">
                      
                      <Form.Input  value={ this.state.data.sensation} fluid    label="Value"  onChange={this.handleChange}  name='sensation'  />          
                      <Form.Field>
                                <lable>&nbsp;</lable>
                               <Form.Checkbox defaultChecked={ this.state.data.sensation_wfl} label="WFL" toggle name="sensation_wfl"  onChange={this.checkboxChangeHandler} ></Form.Checkbox>            
                      </Form.Field>      
                      
                      </Form.Group>  
                     

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
        <Segment secondary>
            <Grid divided>
              <Grid.Row>
                 <Grid.Column width={4}>
                 <Header>Problems:</Header>
                 <Form.Dropdown
                              fluid     
                              label="Problmes" 
                              name="problems"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={problems}
                              selection
                              multiple 
                              clearable
                              onChange={this.handleDropChange}
                              defaultValue={ this.state.data.problems}
                       />  
                        <Form.Input value={ this.state.data.problems_other} fluid      label="Other"  onChange={this.handleChange}  name='problems_other'  />                   
                 </Grid.Column>
                 <Grid.Column width={4}>
                 <Header>Treatment Plan:</Header>
                 <Form.Dropdown
                              fluid     
                              label="Plan" 
                              name="txplan"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel} 
                              options={txplan}
                              selection
                              multiple 
                              clearable
                              onChange={this.handleDropChange}
                              defaultValue={ this.state.data.txplan}
                       /> 
                         
                        <Form.Input value={ this.state.data.txplan_other} fluid      label="Other"  onChange={this.handleChange}  name='txplan_other'  />
                        <Form.Input value={ this.state.data.txplan_freq} fluid      label="FREQ AND DUR"  onChange={this.handleChange}  name='txplan_freq'  />                                      
                 </Grid.Column>
                 <Grid.Column width={8} >
                 <Header >Goals:</Header>
                   <Grid  divided >
                        <Grid.Row>
                            <Grid.Column width={6} >Improved Bed Mobility in</Grid.Column>
                            <Grid.Column width={5} > <Form.Dropdown value={ this.state.data.goals_bed_weeks} onChange={this.handleDropChange} clearable fluid name="goals_bed_weeks" placeholder="Select Week" options={weeks} selection  /> </Grid.Column> 
                            <Grid.Column width={5}> <Form.Dropdown value={ this.state.data.goals_bed_grades} onChange={this.handleDropChange} clearable fluid name="goals_bed_grades" placeholder="Select Grade" options={goals} selection  />  </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={6} >Increased Transfer in </Grid.Column>
                            <Grid.Column width={5} > <Form.Dropdown value={ this.state.data.goals_transfer_weeks} onChange={this.handleDropChange} clearable fluid name="goals_transfer_weeks" placeholder="Select Week" options={weeks} selection  /> </Grid.Column> 
                            <Grid.Column width={5}> <Form.Dropdown value={ this.state.data.goals_transfer_grades} onChange={this.handleDropChange} clearable fluid name="goals_transfer_grades" placeholder="Select Grade" options={goals} selection  />  </Grid.Column>
                        </Grid.Row>     
                        <Grid.Row>
                            <Grid.Column width={6} >Increased Gait in </Grid.Column>
                            <Grid.Column width={5} > <Form.Dropdown value={ this.state.data.goals_gait_weeks} onChange={this.handleDropChange} clearable fluid name="goals_gait_weeks" placeholder="Select Week" options={weeks} selection  /> </Grid.Column> 
                            <Grid.Column width={5}> <Form.Dropdown value={ this.state.data.goals_gait_grades} onChange={this.handleDropChange} clearable fluid name="goals_gait_grades" placeholder="Select Grade" options={goals} selection  />  </Grid.Column>
                        </Grid.Row>       
                        <Grid.Row>
                            <Grid.Column width={6} >Increased Balance in </Grid.Column>
                            <Grid.Column width={5} > <Form.Dropdown value={ this.state.data.goals_balance_weeks} onChange={this.handleDropChange} clearable fluid name="goals_balance_weeks" placeholder="Select Week" options={weeks} selection  /> </Grid.Column> 
                            <Grid.Column width={5}> <Form.Dropdown value={ this.state.data.goals_balance_grades} onChange={this.handleDropChange} clearable fluid name="goals_balance_grades" placeholder="Select Grade" options={grades} selection  />  </Grid.Column>
                        </Grid.Row>    
                        <Grid.Row>
                            <Grid.Column width={6} >Increased Strength in </Grid.Column>
                            <Grid.Column width={5} > <Form.Dropdown value={ this.state.data.goals_strenght_weeks} onChange={this.handleDropChange} clearable fluid name="goals_strenght_weeks" placeholder="Select Week" options={weeks} selection  /> </Grid.Column> 
                            <Grid.Column width={5}> <Form.Dropdown value={ this.state.data.goals_strenght_grades} onChange={this.handleDropChange} clearable fluid name="goals_strenght_grades" placeholder="Select Grade" options={strenght} selection  />  </Grid.Column>
                           
                        </Grid.Row>   
                        <Grid.Row>
                            <Grid.Column width={6} >Increased ROM in </Grid.Column>
                            <Grid.Column width={5} > <Form.Dropdown  value={ this.state.data.goals_rom_weeks} onChange={this.handleDropChange} clearable fluid name="goals_rom_weeks" placeholder="Select Week" options={weeks} selection  /> </Grid.Column> 
                            <Grid.Column width={5}> <Form.Dropdown value={ this.state.data.goals_rom_grades} onChange={this.handleDropChange} clearable fluid name="goals_rom_grades" placeholder="Select Grade" options={rom} selection  />  </Grid.Column>
                             
                        </Grid.Row>   
                        <Grid.Row>
                            <Grid.Column width={6} >Decreased Pain in </Grid.Column>
                            <Grid.Column width={5} > <Form.Dropdown value={ this.state.data.goals_pain_weeks} onChange={this.handleDropChange} clearable fluid name="goals_pain_weeks" placeholder="Select Week" options={weeks} selection  /> </Grid.Column> 
                            <Grid.Column width={5}> <Form.Dropdown value={ this.state.data.goals_pain_grades} onChange={this.handleDropChange} clearable fluid name="goals_pain_grades" placeholder="Select Grade" options={pain} selection  />  </Grid.Column>
                        </Grid.Row> 
                        <Grid.Row>
                        <Grid.Column width={16} >  <Form.Input value={ this.state.data.discharge_plan} fluid      label="Discharge Plan"  onChange={this.handleChange}  name='discharge_plan'  />   </Grid.Column>
                        
                        </Grid.Row>
                   </Grid>
                 </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Group widths='2'>
                     <Header>POC Discussed With</Header>
                     <Form.Group widths='4'>
                     <Form.Field>
                      <Radio
                        label='Patient'
                        name='poc_discussed'
                        value='Patient' 
                        onChange={this.handleRadioChange}
                        checked={ this.state.data.poc_discussed === 'Patient'}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label='Family'
                        name='poc_discussed'
                        value='Family' 
                        onChange={this.handleRadioChange}
                        checked={ this.state.data.poc_discussed === 'Family'}
                      />
                    </Form.Field>  
                    <Form.Field>
                      <Radio
                        label='Caregiver'
                        name='poc_discussed'
                        value='Caregiver' 
                        onChange={this.handleRadioChange}
                        checked={ this.state.data.poc_discussed === 'Caregiver'}
                      />
                    </Form.Field>  
                     </Form.Group>
                     <Form.Dropdown label="Completion Date" value={ this.state.data.complition_date} onChange={this.handleDropChange} clearable fluid name="complition_date" placeholder="Select Week" options={complition_weeks} selection  />                               
                  </Form.Group>
                  <Form.Group widths='3'>
            <DateInput label="Date" closable={true}   name="date"  value={this.state.data.date}  placeholder="Date"   dateFormat={'MM-DD-YYYY'} iconPosition="left"  onChange={this.handleDateChange}  />
            <TimeInput closable={true}
                    
                   name="timein"
                   label="Time In"
                   value={this.state.data.timein}
                   timeFormat={'ampm'}
                   iconPosition="left"
                   onChange={this.handleDateChange}
                  
                 />
             <TimeInput closable={true}
                    
                   name="timeout"
                   label="Time Out"
                   value={this.state.data.timeout}
                   timeFormat={'ampm'}
                   iconPosition="left"
                   onChange={this.handleDateChange}
                 />    
          </Form.Group>
                </Grid.Column>
                
              </Grid.Row>
            </Grid>
        </Segment>  
        <Button content='Submit' primary fluid >Submit</Button>
       
      </Form>
        </div>
      </div>
    );
  }
  
};

export default PhysicalTherapyEval;
