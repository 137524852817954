import React from 'react'; 
import { Form, Checkbox, Message, Card, Divider, Item, Label, Responsive, Container, Header, Modal, Rating, Icon, Input, Select, Popup, Image, Button, List, Grid, Segment } from 'semantic-ui-react';
import axios from 'axios';
import Auth from '../../validators/auth';

class Login extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)
    this.state = {
      email: '',
      password: '',
      open:true,
      message:''
   };
  }

  handleChange = event => {
    this.name = event.target.name; 
   this.setState({ [event.target.name]: event.target.value });
 }

 handleSubmit = event => {
   event.preventDefault();

   const user = {
     email: this.state.email,
     password: this.state.password
   };

   axios.post(`https://api.pureptinc.com/auth/signin`, {
     email: this.state.email,
     password: this.state.password })
     .then(res => { 
       console.log(res.data)
    
       if(res.data.status){
         
        Auth.setSession(res.data.user);
        window.location.href = '/';
 
       }
       else{
       
         this.setState({
          open:false,
          message:res.data.message
        })
       }
     })
 }

 render() {

   return (
      <div>   
        
      <Container>
      <Image src='https://pureptinc.com/wp-content/uploads/2020/09/logo.png'  size='small' centered />
      <Form>
                <Form.Field>
                  <input placeholder='Email'
                   name="email"
                   onChange={this.handleChange}
                   tabIndex={0}
                    value={this.state.email}
                    appearance="neutral"
                    type="email" />
                </Form.Field>
                <Form.Field>
                  <input placeholder='Password' 
                    onChange={this.handleChange}
                    value={this.state.password}
                    appearance="neutral"
                    type="password"
                    inputHeight={36}
                    name="password"/>
                </Form.Field>
                <Message negative hidden={this.state.open}>
                <Message.Header>Login Failed</Message.Header>
                     <p>{this.state.message}</p>
                </Message> 
                <Button type='submit' fluid color={'olive'} onClick={this.handleSubmit}>Submit</Button>
                
              </Form>
      </Container>

      </div>
   );
 }
}



export default Login;
