import React from "react";
import {  Grid, Table, Popup, Button, Label, Segment, Header, Radio, Divider } from "semantic-ui-react";
import moment from 'moment'
import {
  Form, Dropdown, Checkbox
} from 'formsy-semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import {
  DateInput,
  TimeInput,
  DateTimeInput,
  DatesRangeInput
} from 'semantic-ui-calendar-react';
import axios from 'axios';
import 'semantic-ui-css/semantic.min.css'


class PhysicalTherapyDischarge extends React.Component {
  constructor(props) {
    super(props); 
    this.props=props;
    this.state = { 
      "job_id": this.props.job_id,
      "type":this.props.type, 
      "created_at": new Date,
      "updated_at": new Date, 
      agency:this.props.agency,
      jobdetail:{},
      data:{
        bed_grades:'',
        transfer_grades:'', 
        gait_grades:'',
        balance_grades:'',
        rom_grades:'', 
        pain_grades:'',
        patientname: "",
        mr: "",
        visitdate: "",
        lastvisitdate: "",
        dischargedate: "",
        therapydx: "",
        bed_sup:"",
        treatment_discharge: "",
        discharge_reason_other: "",
        strenght_grades: "",
        discharge_plan: "Home with MD supervision",
        discharge_plan_other: "",
        date: "",
        goals_comment:"All goals are met",
        problems:[],
        treatments:[],
        discipline_report:"pt",
        discharge_reasons:"Goals met/no further care needed",
        discharge_notified:"Patient",
        goalsmet:"yes"
      }
   };
    
    
  }

  componentDidMount(){
    

    axios.get(`https://api.pureptinc.com/jobs/`+this.state.job_id )
    .then(res => {
      console.log(res.data[0])
       this.setState({jobdetail:res.data[0]})
    })   

    if(this.props.new){
    
      axios.get('https://api.pureptinc.com/forms/', {params: {
        job_id:this.props.job_id,
        type: 'Evaluation', 
       
       } 
           
       }).then(res => {
        console.log(res.data.length)
         if(res.data.length > 0){
          const evalform = res.data[0]
       
          this.setState(prevState => ({
            data: {                    
                ...prevState.data,    
                strength_lle:evalform.data.strength_lle,
                strength_rue:evalform.data.strength_rue,
                strength_rle:evalform.data.strength_rle,
                strength_lue:evalform.data.strength_lue,
                balance_stand:evalform.data.balance_stand, 
                gait_assitant:evalform.data.gait_assitant,
                transfer_sit_stand:evalform.data.transfer_sit_stand,
                bed_sup:evalform.data.bed_sup,
                pain_location: evalform.data.pain_location,
                pain_severity: evalform.data.pain_severity,
                stairs_assitant: evalform.data.stairs_assitant,
                txplan: evalform.data.txplan,
                visitdate: evalform.data.date,
                mr: evalform.data.mr,
                therapydx: evalform.data.physicaltherapydx

            }
          })) 
         }
  
      })


      axios.get('https://api.pureptinc.com/forms/', {params: {
        job_id:this.props.job_id,
        type: 'Note', 
       
       } 
           
       }).then(res => {
        console.log(res.data)
         if(res.data.length > 0){
          const noteForm = res.data[res.data.length - 1]
       
          this.setState(prevState => ({
            data: {                    
                ...prevState.data,     
                bed_grades: noteForm.data.bed_sup,
                transfer_grades: noteForm.data.transfer_sit_stand,
                gait_grades: noteForm.data.gait_assitant,
                balance_grades: noteForm.data.balance_stand, 
                pain_grades: noteForm.data.pain_intensity,
                date:noteForm.data.date,
                lastvisitdate:noteForm.data.date,
                dischargedate:noteForm.data.date,
                timein:noteForm.data.timein,
                timeout:noteForm.data.timeout,
            }
          })) 
         }
  
      })
       
    }
    else {
     
      this.setState({
        data:this.props.data
      })
    }

    
 
    
  }
 
   
  handleDropChange = (event, {name, value}) => {
     
    this.setState(prevState => ({
      data: {                    
        ...prevState.data,    
        [name]: value    
    }
  }))
  }

  handleRadioChange = (event, {name, value}) => {
     
    this.setState(prevState => ({
      data: {                    
        ...prevState.data,    
        [name]: value    
    }
  }))
  }

  checkboxChangeHandler = (event, {name, checked}) => {
     
    this.setState(prevState => ({
      data: {                    
        ...prevState.data,    
        [name]: checked    
    } 
  }))
    
  };
   

  handleChange = (event, data) => {
    console.log(data)
    this.name = event.target.name;
    
    this.setState(prevState => ({
      data: {                    
        ...prevState.data,    
        [event.target.name]: event.target.value   
    }
       
  }))
 }

 handleDateChange = (event, {name, value}) => {
   
  
 
  this.setState(prevState => ({
  
    data: {                    
      ...prevState.data,    
      [name]: value   
  }
}))
  
}
 
 handleSubmit = () => {
  this.setState({ 
    dimmer: true
  }) 

  axios.post(`https://api.pureptinc.com/forms/`, this.state)
  .then(res => {
    this.setState({loading:false});
    this.props.refreshContent(this.state.job_id)       
    this.props.setCloseForm()       
  })       
   
     
}
 

 
 
  render() {
 
    const errorLabel = <Label color="red" pointing="left" />;

    const respiratoryrate = [
        { text: '16', value: '16' },
        { text: '17', value: '17' },
        { text: '18', value: '18' },
        { text: '19', value: '19' },
        { text: '20', value: '20' },
        { text: '21', value: '21' }  
      ];

    const vision = [
      { text: 'Lt', value: 'Lt' },
      { text: 'Rt', value: 'Rt' },
      { text: 'Both', value: 'Both' },
      { text: 'Blind', value: 'Blind' } 
    ];  

    const hearing = [
      { text: 'Lt', value: 'Lt' },
      { text: 'Rt', value: 'Rt' },
      { text: 'Both', value: 'Both' },
      { text: 'Deaf', value: 'Deaf' } 
    ];  

    const temp = [
        { text: '98.1', value: '98.1' },
        { text: '98.2', value: '98.2' },
        { text: '98.3', value: '98.3' },
        { text: '98.4', value: '98.4' },
        { text: '98.5', value: '98.5' },
        { text: '98.6', value: '98.6' }  
      ]; 

      const living = [
        { text: 'Apt', value: 'Apt' },
        { text: 'House', value: 'House' },
        { text: 'B&C', value: 'B&C' },
        { text: 'Mobile', value: 'Mobile' },
        { text: 'Facility', value: 'Facility' },
      ];    

    const equipments = [
        {id: 1, name:'ptequipment', text:"FWW/4WW/PUW", value: "FWW/4WW/PUW", isChecked: false},
        {id: 2, name:'ptequipment', text: "Shower chair/tub bench", value: "Shower chair/tub bench", isChecked: false},
        {id: 3, name:'ptequipment', text: "Raised toilet seat", value: "Raised toilet seat", isChecked: false},
        {id: 4, name:'ptequipment', text: "SPC", value: "SPC", isChecked: false},
        {id: 5, name:'ptequipment', text: "Hosp bed", value: "Hosp bed", isChecked: false},
        {id: 6, name:'ptequipment', text: "Hoyer lift", value: "Hoyer lift", isChecked: false},
        {id: 7, name:'ptequipment', text: "HW", value: "HW", isChecked: false},
        {id: 8, name:'ptequipment', text: "Oxygen tube", value: "Oxygen tube", isChecked: false},
        {id: 9, name:'ptequipment', text: "Commode", value: "Commode", isChecked: false},
        {id: 10, name:'ptequipment', text: "W/C", value: "W/C", isChecked: false},
        {id: 11, name:'ptequipment', text: "QC", value: "QC", isChecked: false}, 
        {id: 12, name:'ptequipment', text: "Crutches", value: "Crutches", isChecked: false} 
    ]

    const grades = [
        { text: 'P/P-', value: 'P/P-' },
        { text: 'P+/P', value: 'P+/P' },
        { text: 'F-/P+', value: 'F-/P+' },
        { text: 'F/P+', value: 'F/P+' },
        { text: 'F+/P+', value: 'F+/P+' },
        { text: 'F+/F-', value: 'F+/F-' },
        { text: 'G-/F-', value: 'G-/F-' },
        { text: 'G-/F', value: 'G-/F' },
        { text: 'G/F', value: 'G/F' },
        { text: 'G+/F+', value: 'G+/F+' },
        { text: 'G+/G-', value: 'G+/G-' } 

    ]

    const strenght = [
      { text: '0/5 MMT', value: '0/5 MMT' },
      { text: '1-/5 MMT', value: '1-/5 MMT' },
      { text: '1/5 MMT', value: '1/5 MMT' },
      { text: '1+/5 MMT', value: '1+/5 MMT' },
      { text: '2-/5 MMT', value: '2-/5 MMT' },
      { text: '2/5 MMT', value: '2/5 MMT' },
      { text: '2+/5 MMT', value: '2+/5 MMT' },
      { text: '3-/5 MMT', value: '3-/5 MMT' },
      { text: '3/5 MMT', value: '3/5 MMT' },
      { text: '3+/5 MMT', value: '3+/5 MMT' }, 
      { text: '4-/5 MMT', value: '4-/5 MMT' },
      { text: '4/5 MMT', value: '4/5 MMT' },
      { text: '4+/5 MMT', value: '4+/5 MMT' } 
      
  ]

  const pain = [
    { text: '0/10', value: '0/10' },
    { text: '1/10', value: '1/10' },
    { text: '2/10', value: '2/10' },
    { text: '3/10', value: '3/10' },
    { text: '4/10', value: '4/10' },
    { text: '5/10', value: '5/10' },
    { text: '6/10', value: '6/10' },
    { text: '7/10', value: '7/10' },
    { text: '8/10', value: '8/10' },
    { text: '9/10', value: '9/10' },
    { text: '10/10', value: '10/10' },

    
]

const edema = [
  { text: 'Min', value: 'min' },
  { text: 'Mod', value: 'mod' },
  { text: 'Sever', value: 'sever' }  
]

const problems = [
  { text: 'Bed Mobility', value: 'Bed Mobility' },
  { text: 'Transfers', value: 'Transfers' },
  { text: 'Gait', value: 'Gait' },
  { text: 'Stairs', value: 'Stairs' },
  { text: 'Balance', value: 'Balance' },
  { text: 'Strenght', value: 'Strenght' },
  { text: 'Safety', value: 'Safety' },
  { text: 'ROM', value: 'ROM' },
  { text: 'HEP', value: 'HEP' },
  { text: 'CG Knowledge', value: 'BCG Knowledge' },
  { text: 'Pain', value: 'Pain' }  
]

const txplan = [
 
  { text: 'Bed Mobility Training', value: 'Bed Mobility Training' },
  { text: 'Transfers Training', value: 'Transfers Training' },
  { text: 'Gait Training', value: 'Gait Training' },
  { text: 'Stairs Training', value: 'Stairs Training' },
  { text: 'Balance Training', value: 'Balance Training' },
  { text: 'Strengthening', value: 'Strengthening' },
  { text: 'Stretching', value: 'Stretching' },
  { text: 'ROM', value: 'ROM' },
  { text: 'Instruct in HEP', value: 'Instruct in HEP' },
  { text: 'Safety Education', value: 'Safety Education' },
  { text: 'CG Training', value: 'CG Training' },
  { text: 'Decrease Pain', value: 'Decrease Pain' },
  { text: 'Ther ex', value: 'Ther ex' },
]


const weeks = [
  { text: '1 week', value: '1 week' },
  { text: '2 weeks', value: '2 weeks' },
  { text: '3 weeks', value: '3 weeks' },
  { text: '4 weeks', value: '4 weeks' },
  { text: '5 weeks', value: '5 weeks' },
  { text: '6 weeks', value: '6 weeks' },
  { text: '7 weeks', value: '7 weeks' },
  { text: '8 weeks', value: '8 weeks' },
  { text: '9 weeks', value: '9 weeks' }

]

const goals = [
  { text: 'Unable', value: 'Unable' },
  { text: 'Total-A', value: 'Total-A' },
  { text: 'MAX-A', value: 'sever' },
  { text: 'MAX/MOD-A', value: 'MAX/MOD-A' },
  { text: 'MOD-A', value: 'MOD-A' },
  { text: 'MOD/MIN-A', value: 'MOD/MIN-A' },
  { text: 'MIN-A', value: 'MIN-A' },
  { text: 'MIN-A/CGA', value: 'MIN-A/CGA' },
  { text: 'CGA', value: 'CGA' },
  { text: 'CGA/SBA', value: 'CGA/SBA' },
  { text: 'SBA', value: 'SBA' },
  { text: 'SBA/S', value: 'SBA/S' },
  { text: 'S', value: 'S' },
  { text: 'S/INDP', value: 'S/INDP' },
  { text: 'INDP', value: 'INDP' }
]

const reasons = [
  { text: 'Goals met/no further care needed', value: 'Goals met/no further care needed' },
  { text: 'Lack of progress', value: 'Lack of progress' },
  { text: 'Patients request', value: 'Patients request' },
  { text: 'Admitted to hospital/hospice', value: 'Admitted to hospital/hospice' },
  { text: 'Admitted to hospice', value: 'Admitted to hospice' },
  { text: 'Physicians request', value: 'Physicians request' },
  { text: 'Patient refused services', value: 'Patient refused services' },
  { text: 'Patient transferred to another agency', value: 'Patient transferred to another agency' } 
 

]

const pains = [
  { text: 'C/S', value: 'C/S' },
  { text: 'T/S', value: 'T/S' },
  { text: 'L/S', value: 'L/S' },
  { text: 'Shoulders', value: 'Shoulders' },
  { text: 'Elbows', value: 'Elbows' },
  { text: 'Wrists', value: 'Wrists' },
  { text: 'Hips', value: 'Hips' },
  { text: 'Knees', value: 'Knees' },
  { text: 'Ankles', value: 'Ankles' },
  { text: 'Lower Back', value: 'Lower Back' }   
];



    return (
      <div className="container"> 
        <div className="formcontainer">
          <Header textAlign="center">DISCHARGE VISIT NOTE AND SUMMARY</Header>
         
    <Header.Subheader  textAlign="center">Agency: {this.state.agency}</Header.Subheader>
    <Divider></Divider>
        <Form onValidSubmit={this.handleSubmit}>
        <Form.Group widths="equal">

        <Form.Input  fluid     label="Patient:"  disabled  label="Patient:"  placeholder="John Doe" value={this.state.jobdetail.patientname}  onChange={this.handleChange}  name='patientname'  /> 
        <Form.Input  fluid     label="MR:"   onChange={this.handleChange} value={this.state.data.mr} name='mr'  />

        </Form.Group>

        <Form.Group widths="4">

            <DateInput closable={true}    name="visitdate"  value={this.state.data.visitdate}  label="Date of first visit"   dateFormat={'MM-DD-YYYY'} iconPosition="left"  onChange={this.handleDateChange}  />
            <DateInput closable={true}   name="lastvisitdate"  value={this.state.data.lastvisitdate}  label="Date of last visit"   dateFormat={'MM-DD-YYYY'} iconPosition="left"  onChange={this.handleDateChange}  />
            <DateInput closable={true}   name="dischargedate"  value={this.state.data.dischargedate}  label="Date of discharge"   dateFormat={'MM-DD-YYYY'} iconPosition="left"  onChange={this.handleDateChange}  />
            <Form.Group>
            <label>Discipline reporting</label>
            <Form.Field> 
              <Radio   label='P.T.' name='discipline_report' value='pt'  checked={this.state.data.discipline_report === 'pt'} onChange={this.handleRadioChange} /> 
            </Form.Field>
            <Form.Field>
              <Radio   label='S.T.' name='discipline_report' value='st'  checked={this.state.data.discipline_report === 'st'} onChange={this.handleRadioChange} />
            </Form.Field>
            <Form.Field>
               <Radio   label='O.T.' name='discipline_report' value='ot'  checked={this.state.data.discipline_report === 'ot'} onChange={this.handleRadioChange} />  
            </Form.Field>
            </Form.Group>
            


        </Form.Group>

         <Form.Input value={this.state.data.therapydx}  fluid     label="PHYSICAL THERAPY DX:"   onChange={this.handleChange}  name='therapydx'  />
         <Form.Input value={this.state.data.treatment_discharge} fluid     label="TREATMENT RENDERED ON DAY OF DISCHARGE:"   onChange={this.handleChange}  name='treatment_discharge'  />       

          <Form.Group widths='2'>
            <Form.Dropdown  onChange={this.handleDropChange} value={this.state.data.discharge_reasons}  fluid label="REASON FOR DISCHARGE:" name="discharge_reasons" placeholder="Select Reason" validationErrors={{  isDefaultRequiredValue: 'You need to select a reason', }} errorLabel={errorLabel}  options={reasons}  selection multiple />          
            <Form.Input  value={this.state.data.discharge_reason_other}  fluid     label="Other Reason:"   onChange={this.handleChange}  name='discharge_reason_other'  />  
          </Form.Group> 
          
          <Grid columns='2'> 
            <Grid.Row>
              <Grid.Column>
                <Header>PROBLEMS IDENTIFIED ON INITIAL EVALUATION</Header> 
               
                 


        <Form.Group widths="equal">
                
                <Form.Dropdown
                    fluid     
                    label="Bed Mobility:" 
                    name="bed_sup"
                    placeholder="Select Value" 
                    validationErrors={{
                    isDefaultRequiredValue: 'You need to select a value',
                    }}
                    errorLabel={errorLabel}
                    options={goals}
                    selection
                    clearable
                    onChange={this.handleDropChange}
                    value={ this.state.data.bed_sup}
                                 /> 
        
                <Form.Dropdown
                    fluid     
                    label="Sit to stand:" 
                    name="transfer_sit_stand"
                    placeholder="Select Value" 
                    validationErrors={{
                    isDefaultRequiredValue: 'You need to select a value',
                    }}
                    errorLabel={errorLabel}
                    options={goals}
                    selection
                    clearable
                    onChange={this.handleDropChange}
                    value={ this.state.data.transfer_sit_stand}
                 />      
                 
                 <Form.Dropdown
                    fluid     
                    label="Gait With:"
                    name="gait_assitant"
                    placeholder="Select Value" 
                    validationErrors={{
                    isDefaultRequiredValue: 'You need to select a value',
                    }}
                    errorLabel={errorLabel}
                    options={goals}
                    selection
                    clearable
                    onChange={this.handleDropChange}  
                    value={ this.state.data.gait_assitant}  /> 
        
                                 
                                       
        
        
                  
                </Form.Group> 
        
                <Form.Group widths="equal">
                <Form.Dropdown
                    fluid     
                    label="Standing Balance:" 
                    name="balance_stand"
                    placeholder="Select Value" 
                    validationErrors={{
                    isDefaultRequiredValue: 'You need to select a value',
                    }}
                    errorLabel={errorLabel}
                    options={grades}
                    selection
                    clearable
                    onChange={this.handleDropChange}
                    value={ this.state.data.balance_stand} 
                                        />        
                <Form.Dropdown
            fluid     
            label="Assist:"
            name="stairs_assitant"
            placeholder="Select Value" 
            validationErrors={{
            isDefaultRequiredValue: 'You need to select a value',
            }}
            errorLabel={errorLabel}
            options={goals}
            selection
            clearable
            onChange={this.handleDropChange}
            value={ this.state.data.stairs_assitant}
        />   
                   
                </Form.Group>
                <Form.Group widths="equal">
        
        <Form.Dropdown
            fluid     
            label="Strength LUE:" 
            name="strength_lue"
            placeholder="Select Value" 
            validationErrors={{
            isDefaultRequiredValue: 'You need to select a value',
            }}
            errorLabel={errorLabel}
            options={strenght}
            selection
            clearable
            onChange={this.handleDropChange}
            value={ this.state.data.strength_lue}
                         />
                         <Form.Dropdown
            fluid     
            label="Strength LLE:" 
            name="strength_lle"
            placeholder="Select Value" 
            validationErrors={{
            isDefaultRequiredValue: 'You need to select a value',
            }}
            errorLabel={errorLabel}
            options={strenght}
            selection
            clearable
            onChange={this.handleDropChange}
            value={ this.state.data.strength_lle}
                         /> 

<Form.Dropdown
            fluid     
            label="Strength RUE:" 
            name="strength_rue"
            placeholder="Select Value" 
            validationErrors={{
            isDefaultRequiredValue: 'You need to select a value',
            }}
            errorLabel={errorLabel}
            options={strenght}
            selection
            clearable
            onChange={this.handleDropChange}
            value={ this.state.data.strength_rue}
                         />
                         <Form.Dropdown
            fluid     
            label="Strength RLE:" 
            name="strength_rle"
            placeholder="Select Value" 
            validationErrors={{
            isDefaultRequiredValue: 'You need to select a value',
            }}
            errorLabel={errorLabel}
            options={strenght}
            selection
            clearable
            onChange={this.handleDropChange}
            value={ this.state.data.strength_rle}
                         /> 
        </Form.Group>
                <Form.Dropdown
                              fluid     
                              label="Plan" 
                              name="txplan"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel} 
                              options={txplan}
                              selection
                              multiple 
                              clearable
                              onChange={this.handleDropChange}
                              defaultValue={ this.state.data.txplan}
                       /> 
                
                <Form.Group widths='2'>
                
<Form.Dropdown
      fluid     
      label="Pain Location" 
      name="pain_location"
      placeholder="Select Value" 
      validationErrors={{
      isDefaultRequiredValue: 'You need to select a value',
      }}
      errorLabel={errorLabel}
      options={pains}
      selection
      multiple
      onChange={this.handleDropChange}
      value={ this.state.data.pain_location}
  />  
<Form.Dropdown
        fluid     
        label="Pain Severity" 
        name="pain_severity"
        placeholder="Select Value" 
        validationErrors={{
        isDefaultRequiredValue: 'You need to select a value',
        }}
        errorLabel={errorLabel}
        options={pain}
        selection
        clearable
        onChange={this.handleDropChange}
        value={ this.state.data.pain_severity}
         
   /> 
</Form.Group>

<Form.TextArea value={this.state.data.goals_comment} rows="6"  fluid     label="ADDITIONAL COMMENTS"   onChange={this.handleChange}  name='goals_comment'  />  
              </Grid.Column> 
              <Grid.Column> 
              <Segment inverted color='red' tertiary> 
                <Header>ASSESSMENT OF PROBLEMS AT DISCHARGE</Header>
              <Grid  divided >
                        <Grid.Row>
                            <Grid.Column width={8} >Improved Bed Mobility to </Grid.Column>  
                            <Grid.Column width={8}> <Form.Dropdown value={ this.state.data.bed_grades} onChange={this.handleDropChange} clearable fluid name="bed_grades" placeholder="Select Grade" options={goals} selection  />  </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={8} >Increased Transfer to </Grid.Column> 
                            <Grid.Column width={8}> <Form.Dropdown value={ this.state.data.transfer_grades} onChange={this.handleDropChange} clearable fluid name="transfer_grades" placeholder="Select Grade" options={goals} selection  />  </Grid.Column>
                        </Grid.Row>     
                        <Grid.Row>
                            <Grid.Column width={8} >Increased Gait to </Grid.Column> 
                            <Grid.Column width={8}> <Form.Dropdown value={ this.state.data.gait_grades} onChange={this.handleDropChange} clearable fluid name="gait_grades" placeholder="Select Grade" options={goals} selection  />  </Grid.Column>
                        </Grid.Row>       
                        <Grid.Row>
                            <Grid.Column width={8} >Increased Balance to </Grid.Column> 
                            <Grid.Column width={8}> <Form.Dropdown value={ this.state.data.balance_grades} onChange={this.handleDropChange} clearable fluid name="balance_grades" placeholder="Select Grade" options={grades} selection  />  </Grid.Column>
                        </Grid.Row>    
                        <Grid.Row>
                            <Grid.Column width={8} >Increased Strenght to </Grid.Column> 
                            <Grid.Column width={8}> <Form.Dropdown value={ this.state.data.strenght_grades} onChange={this.handleDropChange} clearable fluid name="strenght_grades" placeholder="Select Grade" options={grades} selection  />  </Grid.Column>
                            
                        </Grid.Row>   
                        <Grid.Row>
                            <Grid.Column width={8} >Increased ROM to </Grid.Column> 
                            <Grid.Column width={8}> <Form.Dropdown value={ this.state.data.rom_grades} onChange={this.handleDropChange} clearable fluid name="rom_grades" placeholder="Select Grade" options={grades} selection  />  </Grid.Column>
 
                        </Grid.Row>   
                        <Grid.Row>
                            <Grid.Column width={8} >Decreased Pain to </Grid.Column> 
                            <Grid.Column width={8}> <Form.Dropdown value={ this.state.data.pain_grades} onChange={this.handleDropChange} clearable fluid name="pain_grades" placeholder="Select Grade" options={pain} selection  />  </Grid.Column>
                        </Grid.Row> 
                   </Grid>
                   <Divider></Divider>
                   <Header>Goals Met</Header>
                   <Form.Group widths='3'>
                     
                          <Form.Field><Radio   label='Yes' name='goalsmet' value='yes'  checked={this.state.data.goalsmet === 'yes'} onChange={this.handleRadioChange} /></Form.Field>
                          <Form.Field><Radio   label='No' name='goalsmet' value='no'  checked={this.state.data.goalsmet === 'no'} onChange={this.handleRadioChange} /></Form.Field>
                          <Form.Field><Radio   label='Partialy' name='goalsmet' value='partialy'  checked={this.state.data.goalsmet === 'partialy'} onChange={this.handleRadioChange} /></Form.Field>
                          
                    </Form.Group>          
              </Segment>
                   
              </Grid.Column>
            </Grid.Row>
           
          </Grid> 
         
          

          <Grid columns='3'> 
            <Grid.Row>
              <Grid.Column>
                  <Form.Input  fluid     value={this.state.data.discharge_plan} label="DISCHARGE PLAN"   onChange={this.handleChange}  name='discharge_plan'  />  
                  
              </Grid.Column>
              <Grid.Column>
              <Form.Input  fluid     value={this.state.data.discharge_plan_other} label="Other"   onChange={this.handleChange}  name='discharge_plan_other'  />  
              </Grid.Column>
              <Grid.Column> 
              <Header>NOTIFIED OF DISCHARGE</Header>
                    <Form.Group widths='3'>
                     
                    <Form.Field><Radio   label='Patient' name='discharge_notified' value='Patient'  checked={this.state.data.discharge_notified === 'Patient'} onChange={this.handleRadioChange} /></Form.Field>
                    <Form.Field><Radio   label='Caregiver' name='discharge_notified' value='Caregiver'  checked={this.state.data.discharge_notified === 'Caregiver'} onChange={this.handleRadioChange} /></Form.Field>
                    <Form.Field><Radio   label='Family' name='discharge_notified' value='Family'  checked={this.state.data.discharge_notified === 'Family'} onChange={this.handleRadioChange} /></Form.Field>
                    </Form.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider></Divider>
          <Form.Group widths='3'>
            <DateInput closable={true}   name="date"  value={this.state.data.date}  placeholder="Date"   dateFormat={'MM-DD-YYYY'} iconPosition="left"  onChange={this.handleDateChange}  />
            <TimeInput closable={true}
                    
                   name="timein"
                   placeholder="Time In"
                   value={this.state.data.timein}
                   timeFormat={'ampm'}
                   iconPosition="left"
                   onChange={this.handleDateChange}
                 />
             <TimeInput closable={true}
                    
                   name="timeout"
                   placeholder="Time Out"
                   value={this.state.data.timeout}
                   timeFormat={'ampm'}
                   iconPosition="left"
                   onChange={this.handleDateChange}
                 />    
          </Form.Group>
          <Button content='Submit' primary fluid >Submit</Button> 
      </Form>
 
        </div>
      </div>
    );
  }
  
};

export default PhysicalTherapyDischarge;
