import React from 'react'; 
import { Table, Container, Button, Header, Icon, Modal, Dimmer, Loader, Segment, Label, Confirm, Pagination} from 'semantic-ui-react'
import {
  Form, Input, TextArea, Checkbox, Radio, RadioGroup, Dropdown, Select,
} from 'formsy-semantic-ui-react';
import axios from 'axios';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link
  } from "react-router-dom";

class Therapists extends React.Component {

    
    
  constructor(props) {
    super(props) 
    this.state = {
        therapists:[],
        agencies:[],
        open:false,
        dimmer:true,
        queryid:null,
        selectedpage:1,
        totalpages:1,
        sort:'created_at',
        rate:0,
        reffrate:0,
        refferal:false,
        confirm:false
   };
 
  }

  refreshContent(data) {

    const active =  (data ? data.active : 1)
    const postdata = { size:10, number:active,  sort:this.state.sort};
    axios.get(`https://api.pureptinc.com/therapists`, { params: postdata} )
        .then(res => { 
            
            this.setState({
                therapists:res.data.therapists,
                totalpages:res.data.totalpages,
                dimmer:false
              })
        
        }) 

        axios.get(`https://api.pureptinc.com/agencies`)
        .then(res => { 
            console.log(res)
            this.setState({
                agencies:res.data.agencies,
                dimmer:false
              })
        
        })
  }

  componentDidMount(){
    if(this.props.location.state) {
      this.refreshContent(this.props.location.state.id);
    }
    else {
      this.refreshContent(null);
    }

    
        
  }

  setOpen(value){
    this.setState({
        open:value,
        dimmer: (value ? true : false)
      })
  }
  handleChange = event => {
     
    this.name = event.target.name;
    this.setState({ [event.target.name]: event.target.value });
 }

 handleChangeDrop = (e, { value }) => { 
  this.setState({
    agency_id:value  
  })
 }

 handleChangeCheckBox = (e, data) => { 
  console.log(data.checked)
  this.setState({
    refferal:data.checked  
  })
 }

 handleChangeSpecialty= (e, { value }) => { 
  this.setState({
    specialty:value  
  })
 }

 handleChangeRole= (e, { value }) => { 
  this.setState({
    role:value  
  })
 }

 handlePaginationChange = (e, { activePage }) => {
 
 
  this.refreshContent({active:activePage})
  
}


 handleDelete = id => {
  axios.delete(`https://api.pureptinc.com/therapists/`+id).then(res => {this.refreshContent();});

}

 handleSubmit = () => {

  this.setState({
     
    dimmer: true
  })
            
    axios.post(`https://api.pureptinc.com/therapists/`, {
        fname:this.state.fname,
        lname:this.state.lname,
        phone:this.state.phone,
        address1:this.state.address1,
        address2:this.state.address2,
        city:this.state.city,
        state:this.state.state,
        zip:this.state.zip,
        specialty:this.state.specialty,
        role:this.state.role,
        signature:false,
        company_id:"5f777a3e2b5082092ac62dc0",
        rate:this.state.rate,
        reffrate:this.state.reffrate,
        refferal:this.state.refferal
    })
    .then(res => {
       
        axios.post(`https://api.pureptinc.com/auth/signup`, {
            email:this.state.email,
            password:this.state.password,
            permission:"false",
            role:"therapist", 
            uuid:res.data,
            companyid:"5f777a3e2b5082092ac62dc0"
        }).then(result => {
          this.refreshContent()
          this.setState({
            open:false,
            dimmer: false
          })
        })
    })
}

dynamicSort = (property) => {
  console.log(property)
 var sortOrder = 1;
 if(property[0] === "-") {
     sortOrder = -1;
     property = property.substr(1);
 }
 return function (a,b) {
     /* next line works with strings and numbers, 
      * and you may want to customize it to your needs
      */
     var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
     return result * sortOrder;
 }
}

sortContent = (data) => {
    
  const therapists = this.state.therapists.sort(this.dynamicSort(String(data)));
  this.setState({therapists:therapists})
}
  
 render() {
   const agencyOptions = this.state.agencies.map((p, index) => {
    return {...p, key: index, text: p.name, value:p._id}
  })
   const renderLabel = (label) => ({
    color: 'blue',
    content: label.name,
    icon: 'check',
  })

  const specialties = [
    {key: 0, text: 'Occupational', value:'ot'},
    {key: 1, text: 'Physical', value:'pt'},
    {key: 2, text: 'Speech', value:'st'},
    
  ]

  const roles = [
    {key: 0, text: 'Therapist', value:'therapist'},
    {key: 1, text: 'Assistant', value:'assistant'},
    {key: 2, text: 'Aid', value:'aid'}
  ]
    
    

  const errorLabel = <Label color="red" pointing/>
  
   return (

    
      <div>   

<Confirm
          size={'tiny'}
          open={this.state.confirm}
          confirmButton={'Yes'}
          content={'Are you sure you want to delete this therapist?'}
          onCancel={()=>{this.setState({confirm:false})}}
          onConfirm={()=>{ 
              this.setState({confirm:false})
              this.handleDelete(this.state.selectedtherapist)

           }}
        />
         
         <Container>
         
             <Header as='H1'>Therapists</Header>
          <Segment basic>
          <Dimmer active={this.state.dimmer} inverted>
      <Loader />
    </Dimmer>
          <Table   basic={'very'}  >
           
           <Table.Header>
             <Table.Row>
               <Table.HeaderCell onClick={() => {  this.sortContent('fname')}}>Name</Table.HeaderCell>
               <Table.HeaderCell onClick={() => {  this.sortContent('specialty')}} >Specialty</Table.HeaderCell>
               <Table.HeaderCell onClick={() => {  this.sortContent('role')}}>Role</Table.HeaderCell>
               <Table.HeaderCell>Phone</Table.HeaderCell>
               <Table.HeaderCell>Refferal</Table.HeaderCell>
               <Table.HeaderCell></Table.HeaderCell>
               <Table.HeaderCell></Table.HeaderCell>
                
             </Table.Row>
           </Table.Header>
        
           <Table.Body>
           
           {this.state.therapists.map((therapist, index) => (
                <Table.Row>
                <Table.Cell  >{therapist.fname} {therapist.lname}</Table.Cell>
                <Table.Cell >{therapist.specialty == 'ot' ? <Label color={'olive'}>Occupational</Label>: <Label color={'green'}>Therapist</Label>}</Table.Cell>
                <Table.Cell >{therapist.role == 'therapist' ? <Label color={'blue'}>Therapist</Label>: (therapist.role == 'aid' ? <Label color={'red'}>Aid</Label> : <Label color={'violet'}>Assistant</Label>)}</Table.Cell>
                <Table.Cell>{therapist.phone}</Table.Cell> 
                <Table.Cell> <Icon disabled={!therapist.refferal} color={'green'} name='check circle' /></Table.Cell> 
                <Table.Cell><Button as={Link}   
                     to={{
                       pathname: "/therapist", 
                       state: { "id": therapist._id }
                     }}
                
                color={'blue'} fluid>Detail</Button></Table.Cell>
                <Table.Cell>
                <Button color={'red'}    onClick={() => { this.setState({confirm:true, selectedtherapist:therapist._id}) }}  fluid>Delete</Button>
                  </Table.Cell> 
              </Table.Row> 
           ))}
       
           </Table.Body>
           <Table.Footer fullWidth>
             <Table.Row>
               
               <Table.HeaderCell colSpan='7'>
               
       
                 <Modal
             onClose={() => this.setOpen(false)}
             onOpen={() => this.setOpen(true)}
             open={this.state.open}
             trigger={  <Button
               floated='right'
               icon
               labelPosition='left'
                
               color={'olive'}
               size='medium'
             >
               <Icon name='briefcase' /> Add New Therapist
             </Button>}
           >
             <Modal.Header>Add New Therapist</Modal.Header>
             <Modal.Content  >
             <Form onValidSubmit={this.handleSubmit}>
                         <Form.Group unstackable widths={2}>
                           <Form.Input validations="isEmail" validationErrors={{ isDefaultRequiredValue: 'Email is Required', isEmail: 'Email not valid' }} required  errorLabel={ errorLabel } onChange={this.handleChange} name='email' label='Email' placeholder='Email' value={this.state.email} />
                           <Form.Input required  validations="minLength:8"  validationErrors={{ minLength: 'Minimin of 8 characters', isDefaultRequiredValue: 'Password is Required', }} errorLabel={ errorLabel } onChange={this.handleChange}  name='password' label='Password' type='password' placeholder='Password' value={this.state.password}   />            
                         </Form.Group>

                          <Header>Therapist Details</Header>

                         <Form.Group unstackable widths={3}>
                           <Form.Input  required onChange={this.handleChange} name='fname' label='First Name' placeholder='First Name' value={this.state.fname} />
                           <Form.Input required onChange={this.handleChange} name='lname' label='Last Name' placeholder='Last Name' value={this.state.lname} />
                           <Form.Field>
                              <label>Select Role </label>
                              <Dropdown
                                name='role'
                                selection
                                fluid
                                options={roles}
                                onChange={this.handleChangeRole}
                                placeholder='Choose an role'
                                renderLabel={renderLabel}
                                required
                                validationErrors={{
                                  isDefaultRequiredValue: 'You need to select a role',
                                }}
                                errorLabel={errorLabel}
                              />
                            </Form.Field>

                            <Form.Field>
                              <label>Select Specialty </label>
                              <Dropdown
                                name='specialty'
                                selection
                                fluid
                                options={specialties}
                                onChange={this.handleChangeSpecialty}
                                placeholder='Choose an specialty'
                                renderLabel={renderLabel}
                                required
                                validationErrors={{
                                  isDefaultRequiredValue: 'You need to select a specialty',
                                }}
                                errorLabel={errorLabel}
                              />
                            </Form.Field>
                            
                           
                         </Form.Group>

                         <Form.Group widths={3}>
                           <Form.Input required onChange={this.handleChange} name='rate' label='Rate' placeholder='Rate' value={this.state.rate}   />
                           
                           <Form.Input   onChange={this.handleChange} name='reffrate' label='Refferal Rate' placeholder='Refferal Rate' value={this.state.reffrate}   />
                           <Form.Field>
                           <label>Refferal </label>
                           <Form.Checkbox toggle onChange={this.handleChangeCheckBox}  name='refferal' ></Form.Checkbox>
                           </Form.Field>
                         </Form.Group>



                         <Header>Contact Details</Header>


                         <Form.Group widths={3}>
                           <Form.Input required onChange={this.handleChange} name='phone' label='Phone' placeholder='Phone' value={this.state.phone}   />
                           <Form.Input required onChange={this.handleChange} name='address1' label='Address' placeholder='Address 1' value={this.state.address1}  />
                           <Form.Input   onChange={this.handleChange} name='address2' label='Address 2' placeholder='Address 2' value={this.state.address2}  /> 
           
                         </Form.Group>

                         <Form.Group widths={3}>
                            
                           <Form.Input required onChange={this.handleChange} name='city' label='City' placeholder='City' value={this.state.city}  />
                           <Form.Input required onChange={this.handleChange} name='state' label='State' placeholder='State' value={this.state.state}  />
                           <Form.Input validations="isNumeric" validationErrors={{ isDefaultRequiredValue: 'Valid Zipcode Required', isNumeric: 'Numbers Only' }} required  errorLabel={ errorLabel } onChange={this.handleChange} name='zip' label='Zipcode' placeholder='Zipcode' value={this.state.zip}  />
                         </Form.Group>
                         
                         <Button
           
                 labelPosition='right'
                 icon='checkmark'
                 color={'blue'}
                 content="Submit"
                  fluid 
               />
                       </Form>
                
             </Modal.Content>
             
           </Modal>
                 
               </Table.HeaderCell>
               
             </Table.Row>
           </Table.Footer>
         </Table>
         <Pagination onPageChange={this.handlePaginationChange} defaultActivePage={this.state.selectedpage} totalPages={this.state.totalpages} />
          </Segment>
         </Container>

      </div>
   );
 }
}
 
export default Therapists;
