import React from 'react'; 
import { Container, Header, Button, Segment, Label } from 'semantic-ui-react';
import axios from 'axios';
import {
  Form } from 'formsy-semantic-ui-react';
 
class AgencyDetail extends React.Component {
  constructor(props) {
    super(props);
    this.props = props; 
  
    this.state = {
      loading:false
   };

   
  }
  
  componentDidMount(){
    const id = this.props.location.state.id;
    
    axios.get(`https://api.pureptinc.com/agencies/`+id )
        .then(res => { 
           
          
            this.setState(res.data[0]);
        
        })
 
  }

  handleChange = event => {
    this.name = event.target.name;
    this.setState({ [event.target.name]: event.target.value });
 }

  handleSubmit = () => {
    const id = this.props.location.state.id;
    this.setState({loading:true});
              
      axios.put(`https://api.pureptinc.com/agencies/`+id, {
          name:this.state.name,
          contact:this.state.contact,
          phone:this.state.phone,
          address1:this.state.address1,
          address2:this.state.address2,
          city:this.state.city,
          state:this.state.state,
          zip:this.state.zip, 
          rate:this.state.rate
      })
      .then(res => {
        this.setState({loading:false});
      })
  }
 
 render() {
   
  const errorLabel = <Label color="red" pointing/>

   return (
      <div>   
         
            <Container>
                <Segment >
                <Header as={'H1'}>{this.state.name}</Header>
                 <Form onValidSubmit={this.handleSubmit} >
                  <Form.Group unstackable widths={3}>
                    <Form.Input disabled required onChange={this.handleChange} name='name' label='Name' placeholder='Name' value={this.state.name} />
                    <Form.Input required onChange={this.handleChange} name='contact' label='Contact' placeholder='Contact' value={this.state.contact}   />
                    <Form.Input required onChange={this.handleChange} name='phone' label='Phone' placeholder='Phone' value={this.state.phone}   />
                    <Form.Input labelPosition='right' type='text' required onChange={this.handleChange} name='rate' label='Rate' placeholder='50' value={this.state.rate}  > <Label basic>$</Label>
    <input />
    <Label>.00</Label>
  </Form.Input> 
                  </Form.Group>
                  <Form.Group widths={5}>
                    <Form.Input required onChange={this.handleChange} name='address1' label='Address' placeholder='Address 1' value={this.state.address1}  />
                    <Form.Input onChange={this.handleChange} name='address2' label='Address 2' placeholder='Address 2' value={this.state.address2}  /> 
                    <Form.Input required onChange={this.handleChange} name='city' label='City' placeholder='City' value={this.state.city}  />
                    <Form.Input required onChange={this.handleChange} name='state' label='State' placeholder='State' value={this.state.state}  />
                    <Form.Input required  validations="isNumeric" validationErrors={{ isDefaultRequiredValue: 'Valid Zipcode Required', isNumeric: 'Numbers Only' }}  errorLabel={ errorLabel } onChange={this.handleChange} name='zip' label='Zipcode'  placeholder='Zipcode' value={this.state.zip}  />
                  </Form.Group>
                  
                  <Button color={'blue'} loading={this.state.loading} fluid  content="Submit">Update</Button>
                </Form>
                </Segment>

                
            </Container>
            
      </div>
   );
 }
}
 
export default AgencyDetail;
