import React from 'react'; 
import {  Container, Header, Button, Segment, Image, Label } from 'semantic-ui-react';
import {
  Form,  Dropdown, Checkbox} from 'formsy-semantic-ui-react';
import axios from 'axios';
 
class TherapistDetail extends React.Component {
  constructor(props) {
    super(props);
    this.props = props; 
  
    this.state = {
        loading:false
   };

   
  }
  
  componentDidMount(){
    const id = this.props.location.state.id;
    
    axios.get(`https://api.pureptinc.com/therapists/`+id )
        .then(res => { 
           
            
            this.setState(res.data[0]);
        
        })
 
  }

  handleChange = event => {
    this.name = event.target.name;
    this.setState({ [event.target.name]: event.target.value });
 }

 handleChangeCheckBox = (e, data) => { 
  console.log(data.checked)
  this.setState({
    refferal:data.checked  
  })
 }

 handleChangeSpecialty= (e, { value }) => { 
  this.setState({
    specialty:value  
  })
 }

 handleSubmit = () => {

  console.log(this.state.refferal)

  const id = this.state._id;

  this.setState({loading:true});
            
    axios.put(`https://api.pureptinc.com/therapists/`+id, {
        fname:this.state.fname,
        lname:this.state.lname,
        phone:this.state.phone,
        address1:this.state.address1,
        address2:this.state.address2,
        city:this.state.city,
        state:this.state.state,
        zip:this.state.zip,
        specialty:this.state.specialty,
        rate:this.state.rate,
        refferal:this.state.refferal,
        reffrate:this.state.reffrate
    })
    .then(res => {
      this.setState({loading:false});
    })
}
 
 render() {

  const renderLabel = (label) => ({
    color: 'blue',
    content: label.name,
    icon: 'check',
  })

  const specialties = [
    {key: 0, text: 'Occupational Therapist', value:'ot'},
    {key: 1, text: 'Physical Therapist', value:'pt'}
  ]
 
   return (
      <div>   
         
            <Container>
                <Segment  >
                <Header as={'H1'}>{this.state.fname} {this.state.lname} - {this.state.specialty}   </Header>
                 <Form onValidSubmit={this.handleSubmit} >
                  <Form.Group unstackable widths={4}>
                    <Form.Input required onChange={this.handleChange} name='fname' label='First Name' placeholder='First Name' value={this.state.fname} />
                    <Form.Input required onChange={this.handleChange} name='lname' label='Last Name' placeholder='Last Name' value={this.state.lname} /> 
                    <Form.Input required onChange={this.handleChange} name='phone' label='Phone' placeholder='Phone' value={this.state.phone}   />
                    <Form.Field>
                              <label>Select Specialty </label>
                              <Dropdown
                                required
                                name='specialty'
                                selection
                                fluid
                                options={specialties}
                                onChange={this.handleChangeSpecialty}
                                placeholder='Choose an specialty'
                                renderLabel={renderLabel}
                                value={this.state.specialty}
                              />
                            </Form.Field>
                            <Form.Input labelPosition='right' type='text' required onChange={this.handleChange} name='rate' label='Rate' placeholder='50' value={this.state.rate}  > <Label basic>$</Label>
    <input />
    <Label>.00</Label>
  </Form.Input>      
                  </Form.Group>
                  <Form.Group widths={5}>
                    <Form.Input required onChange={this.handleChange} name='address1' label='Address' placeholder='Address 1' value={this.state.address1}  />
                    <Form.Input   onChange={this.handleChange} name='address2' label='Address 2' placeholder='Address 2' value={this.state.address2}  /> 
                    <Form.Input required onChange={this.handleChange} name='city' label='City' placeholder='City' value={this.state.city}  />
                    <Form.Input required onChange={this.handleChange} name='state' label='State' placeholder='State' value={this.state.state}  />
                    <Form.Input required validations="isNumeric" validationErrors={{ isDefaultRequiredValue: 'Valid Zipcode Required', isNumeric: 'Numbers Only' }} lonChange={this.handleChange} name='zip' label='Zipcode' placeholder='Zipcode' value={this.state.zip}  />
                  </Form.Group>

                  <Form.Group widths={3}>
                           <Form.Input required onChange={this.handleChange} name='rate' label='Rate' placeholder='Rate' value={this.state.rate}   />
                           
                           <Form.Input   onChange={this.handleChange} name='reffrate' label='Refferal Rate' placeholder='Refferal Rate' value={this.state.reffrate}   />
                           <Form.Field>
                           <label>Refferal  </label>
                           <Checkbox  defaultChecked={this.state.refferal} toggle onChange={this.handleChangeCheckBox}  name='refferal' ></Checkbox>
                           </Form.Field>
                         </Form.Group>
                  
                  <Segment>
                      {this.state.signature === 'false' ? 'Signature Not Provided' :  <Image src={this.state.signatureurl} size='small' /> }
                  </Segment>
                  
                  <Button color={'blue'} loading={this.state.loading}   content="Submit"   fluid type='submit'>Update</Button>
                </Form>

                </Segment>
                
            </Container>
            
      </div>
   );
 }
}
 
export default TherapistDetail;
