import React from 'react'; 
import {Component} from 'react'; 
import {  Table, Loader,  Container, Header, Modal, Icon, Button, Grid, Segment, Dropdown, Dimmer, Label, Confirm} from 'semantic-ui-react';
import axios from 'axios';
import Moment from 'react-moment';
import Auth from '../../validators/auth';
import ObjectID from "bson-objectid";
import { BrowserRouter as Link} from "react-router-dom";
import PhysicalTherapyOrder from './forms/therapists/pt/order';
import PhysicalTherapyEval from './forms/therapists/pt/eval';
import PhysicalTherapyNote from './forms/therapists/pt/note';
import PhysicalTherapyDischarge from './forms/therapists/pt/discharge';
import OccupationalTherapyEval from './forms/therapists/ot/eval';
import OccupationalTherapyNote from './forms/therapists/ot/note';
import OccupationalTherapyOrder from './forms/therapists/ot/order';
import OccupationalTherapyDischarge from './forms/therapists/ot/discharge';


 import Empty from './forms/therapists/pt/empty';
 const FileDownload = require('js-file-download');

 const forms = {
  'Empty': Empty,
  'Order': PhysicalTherapyOrder,
  'Evaluation': PhysicalTherapyEval,
  'Note': PhysicalTherapyNote,
  'Discharge': PhysicalTherapyDischarge,
  'OtEvaluation': OccupationalTherapyEval,
  'OtNote':OccupationalTherapyNote,
  'OtOrder':OccupationalTherapyOrder,
  'OtDischarge': OccupationalTherapyDischarge
};

class JobDetail extends React.Component {
  constructor(props) {
    super(props);
    this.props = props; 
  
    this.state = {
      forms:[],
        open:false,
        openForm:false,
        selectedForm:Empty,
        dimmer:true,
        jobdata:{},
        therapists:[],
        confirm:false,
        editconfirm:false
   };

    
   
  }
  setCloseForm=()=>{
    this.setState({
      openForm:false
    })
  }

  refreshContent=()=> {

    const id = this.props.location.state.id; 
    axios.get(`https://api.pureptinc.com/forms`, { params: { "job_id": id} } )
    .then(res => { 
     
        this.setState({
          forms:res.data,
            dimmer:false
          })
    
    }) 
  }
 
  componentDidMount(){
    
    console.log(this.props)
    const id = this.props.location.state.id; 
    axios.get(`https://api.pureptinc.com/jobs/`+id )
        .then(res => {  
            this.setState(res.data[0]);
            this.setState({selectedForm:'Empty'});
            this.refreshContent();
        }) 
 
  }

  setOpen(value){
    
    this.setState({
        open:value,
        dimmer: (value ? true : false)
      })
  }

  setOpenForm(value){
   
    this.setState({
        jobdata:value.jobdata,
        selectedForm:value.formname,
        openForm:value.open,
        dimmer: (value.open ? true : false)
      })
  }


  handleChange = event => {
    this.name = event.target.name;
    this.setState({ [event.target.name]: event.target.value });
 }

 handleChangeForm = (e, { value }) => { 
  this.setState({
    selectedForm:value  
  })
 }

 handleSubmit = () => {
  this.setState({ 
    dimmer: true
  })
  
}
handleDelete = id => {
  axios.delete(`https://api.pureptinc.com/forms/`+id).then(res => {this.refreshContent();});

} 

handlePdfDownload = data => {
  axios.post(`https://api.pureptinc.com/forms/pdf`, data).then(response =>  {
 
     if(response.status === 201){
      setTimeout(() => {
        const link = document.createElement('a');
        link.href =  'https://pure-doc.s3.us-west-1.amazonaws.com/forms-'+data.template+'-'+data.job_id+'.pdf'
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }, 3000);
     
     }
    
     

  });
}

handleDownload = data => {
  axios.post(`https://api.pureptinc.com/pdfs/create`, data).then(response =>  {
 
     
    const link = document.createElement('a');
    link.href =  response.data.Location;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  });

} 
 
 render() {
  const options = [
    { key: 1, text: 'Order', value: 'Order' },
    { key: 2, text: 'Evaluation', value: 'Evaluation' },
    { key: 3, text: 'Note', value: 'Note' },
    { key: 4, text: 'Discharge', value: 'Discharge' }    
  ]

   
   
  
  const renderLabel = (label) => ({
    color: 'blue',
    content: `Customized label - ${label.text}`,
    icon: 'check',
  })
 

  const DynamicComponent = forms[this.state.selectedForm];
  const authenteicated =  Auth.isAuthenticated(); 
   
   return (

    
      <div>   

        <Confirm    
          confirmButton="Yes"
          open={this.state.confirm}
          onCancel={()=> this.setState({confirm:false, open:true })}
          onConfirm={()=> {
            this.refreshContent()
            this.setState({confirm:false, open:false })}
          }
        />
        <Confirm
         confirmButton="Yes"
          open={this.state.editconfirm}
          onCancel={()=> this.setState({editconfirm:false, openForm:true })}
          onConfirm={()=> {
            this.refreshContent()
            this.setState({editconfirm:false, openForm:false, formname:'Empty'})
          }}
        />
         <Modal 
         onClose={() =>  this.setState({confirm:true, open:true })}
         onOpen={() => this.setOpen(true)}
         open={this.state.open}  
         size={'large'}
       >
   
      <Modal.Content >
       <DynamicComponent  patientname={this.state.patientname}  agency={this.state.agency_name} data={''} new={true} job_id={this.state._id} refreshContent={this.refreshContent} setCloseForm={() => { this.setState({open:false}) }} type={this.state.selectedForm} created_at={new Date} updated_at={new Date} />
      </Modal.Content>
      
    </Modal> 
  <Container> 
   <Grid>
   <Header.Subheader as={'h3'} > {this.state.patientname} {this.state.specialty === 'pt' ? <Label size={'mini'} color='green' horizontal>PT</Label> : <Label size={'mini'} color='olive' horizontal>OT</Label>} </Header.Subheader>
   <Header.Subheader  > 
   {this.state.agency_name}
     </Header.Subheader>
   <Header.Subheader  > <Moment date={this.state.created_at} format="LLL"  /> </Header.Subheader>
  

   
   

    {this.state.specialty === 'ot' && authenteicated.role === 'company' ?    <Grid.Row columns={6}> 
      
    <Grid.Column>
        <Button color={'black'}   icon='file pdf'    href={this.state.refferal}  content="Refferal"  fluid /> 
      </Grid.Column>
      <Grid.Column>
        <Button color={'grey'}   icon='file pdf'  href={this.state.addrefferal}  content="Add Refferal"  fluid />
      </Grid.Column>
      <Grid.Column>
        <Button color={'green'}   icon='file'  onClick={()=>{this.setState({open:true, selectedForm:'OtEvaluation' })}}    content="OT Evaluation"  fluid />
      </Grid.Column>
      <Grid.Column>
      <Button color={'red'}   icon='file'   onClick={()=>{this.setState({open:true, selectedForm:'OtDischarge' })}}    content="OT Discharge"  fluid />
      </Grid.Column>
      <Grid.Column>
      <Button color={'olive'}   icon='file'  onClick={()=>{this.setState({open:true, selectedForm:'OtOrder' })}}     content="OT Order"  fluid />
      </Grid.Column>
     
      <Grid.Column>
      <Button color={'orange'}   icon='file'  onClick={()=>{this.setState({open:true, selectedForm:'OtNote' })}}     content="OT Note"  fluid />
     
     
     
      </Grid.Column>
    </Grid.Row> :  ( this.state.specialty === 'pt' && authenteicated.role === 'company' ? <Grid.Row  columns={6}>
    <Grid.Column>
        <Button color={'black'}   icon='file pdf'    href={this.state.refferal}  content="Refferal"  fluid /> 
      </Grid.Column>
      <Grid.Column>
        <Button color={'grey'}   icon='file pdf'  href={this.state.addrefferal}  content="Add Refferal"  fluid />
      </Grid.Column>
    <Grid.Column>
        <Button color={'green'}   icon='file'  onClick={()=>{this.setState({open:true, selectedForm:'Evaluation' })}}     content="Evaluation"  fluid />
      </Grid.Column>
      <Grid.Column>
      <Button color={'red'}   icon='file'   onClick={()=>{this.setState({open:true, selectedForm:'Discharge' })}}    content="Discharge"  fluid />
      </Grid.Column>
      <Grid.Column>
        <Button color={'olive'}   icon='file'  onClick={()=>{this.setState({open:true, selectedForm:'Order' })}}     content="Order"  fluid />
      </Grid.Column>
      <Grid.Column>
        <Button color={'orange'}   icon='file'  onClick={()=>{this.setState({open:true, selectedForm:'Note' })}}     content="Note"  fluid />
      </Grid.Column>
    </Grid.Row> : null)}

  </Grid>

  <Modal 
                   
                  onClose={() =>  this.setState({editconfirm:true, openForm:true })}
                  open={this.state.openForm} 
                  size={'large'}
                >
                 
                  <Modal.Content >
                      <DynamicComponent patientname={this.state.patientname}   agency={this.state.agency_name} id={this.state.formid} data={this.state.jobdata} refreshContent={this.refreshContent} setCloseForm={this.setCloseForm} type={this.state.type}/>
 </Modal.Content>
               
  </Modal>
  <Segment basic>
          <Dimmer active={this.state.dimmer} inverted>
      <Loader />
    </Dimmer>
          <Table   singleLine  >
           
           <Table.Header>
             <Table.Row>
               <Table.HeaderCell>Created</Table.HeaderCell>
               <Table.HeaderCell>Note/Visit Date</Table.HeaderCell>
               <Table.HeaderCell>Note/Visit Time</Table.HeaderCell>
               <Table.HeaderCell>Form Type </Table.HeaderCell>
               <Table.HeaderCell></Table.HeaderCell>
               {
                 authenteicated.role === 'company' ?  <Table.HeaderCell></Table.HeaderCell>
                  : null
               } 

               
             </Table.Row>
           </Table.Header>
        
           <Table.Body> 
           
           {this.state.forms.map((form, index) => (
                <Table.Row key={index}>
                <Table.Cell> <Moment date={form.created_at} format="LL"  /> </Table.Cell> 
                <Table.Cell> {form.data.date} </Table.Cell> 
                <Table.Cell> {form.data.timein} - {form.data.timeout} </Table.Cell> 
                <Table.Cell>{form.type} {form.type === 'Note' 
                ? <Label color='orange' horizontal>{form.index}</Label> : null }  </Table.Cell> 
                <Table.Cell> <Button color='yellow' fluid onClick={() => { this.handlePdfDownload(
                 {
                  "agency":this.state.agency_name,
                  "data":form.data, 
                  "job_id": form.job_id,
                  "template": form.type }
               ) }} >Download PDF</Button></Table.Cell>
                {authenteicated.role === 'company' ? <div>

                <Table.Cell> <Button color='blue' fluid onClick={() => {   this.setState({selectedForm: form.type, jobdata:form.data, formid:form._id, openForm:true})}} >Detail</Button></Table.Cell>
                <Table.Cell><Button color={'red'}    onClick={() => { this.handleDelete(form._id) }}  fluid>Delete</Button></Table.Cell>

                </div> : null}
                 
                
              </Table.Row> 
           ))}
       
           </Table.Body>
           <Table.Footer fullWidth>
            
           </Table.Footer>
         </Table>
          </Segment>
             
            
             </Container>
            
      </div>
   );
 }
}
 
export default JobDetail;
