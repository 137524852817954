import React from 'react'; 
import { Table, Container, Button, Header, Confirm, Modal, Dimmer, Loader, Segment, Label, Divider, Popup, Pagination, Input} from 'semantic-ui-react'
import axios from 'axios';
import Moment from 'react-moment';  
import moment from 'moment';
import {
  DateInput,
  TimeInput,
  DateTimeInput,
  DatesRangeInput
} from 'semantic-ui-calendar-react';
import Auth from '../../validators/auth';
import {
  Form, Dropdown} from 'formsy-semantic-ui-react';
  import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link
  } from "react-router-dom";
class Invoices extends React.Component {
  constructor(props) {
    super(props) 
    this.state = {
       invoices:[],
       selectedfrom:null,
       selectedto:null,
       selectedagency:null,
       agencies:[],
       confirm:false,
       selectedinvoice:null,
       errorpop:false
   };
  } 

  componentDidMount(){

    axios.get(`https://api.pureptinc.com/agencies`)
        .then(res => { 
           
            this.setState({
                agencies:res.data.agencies,
                dimmer:false
              })
        
        })

        this.refreshContent()
  }

  handleChangeAgency= (e, { value }) => { 
  
    this.setState({
      selectedagency:value,
      selectedagencyname: e.target.innerText
    })
     
   }

   handleDelete = id => {
    axios.delete(`https://api.pureptinc.com/invoices/`+id).then(res => {this.refreshContent();}); 
  }

  refreshContent() {
    const authdata = Auth.isAuthenticated()
      const userid = authdata.userid; 
    const invoicequery = authdata.role === 'agency' ? {'agency._id':userid} : {'agency.company_id':userid}  ;
  const invoicedata = {query:invoicequery, size:5};

  axios.get(`https://api.pureptinc.com/invoices`, { params: invoicedata } )
  .then(res => { 
   
      this.setState({
        invoices:res.data.invoices, 
        })
  
  }) 
  }

  handleDateChange = (event, {name, value}) => {
    
    this.setState({  [name]: value  })
    
  }

  handleCreate = () => {

    axios.get(`https://api.pureptinc.com/schedules/invoices`, { params: {
      agencyid:this.state.selectedagency,
      from:moment(this.state.selectedfrom, 'MM-DD-YYYY').format(),
      to:moment(this.state.selectedto, 'MM-DD-YYYY').format(),
    }})
    .then(res => { 
      
         const invoicedata = res.data[0].schedules[0];
         if(invoicedata){
          axios.post(`https://api.pureptinc.com/invoices/`, { 
          
           
            company_id:"5f777a3e2b5082092ac62dc0",  
            from:moment(this.state.selectedfrom, 'MM-DD-YYYY').format(),
            to:moment(this.state.selectedto, 'MM-DD-YYYY').format(),
            created_at: Date.now(),
            updated_at: Date.now(),
            count:Number(invoicedata.count),
            total:Number(invoicedata.total),
            patients:invoicedata.patients, 
            rate:Number(invoicedata.rate),
            agency:invoicedata.agency
        })
        .then(res => {
          
          this.refreshContent()
           
        })
         }
         else {
          this.setState({errorpop:true, errormessage:'No schedule available for selected date range or agency.'})
         }
          
     

    }) 


    
  }
 
 render() {

  const agencyOptions = this.state.agencies.map((p, index) => {
    return {...p, key: index, text: p.name, value:p._id}
  })
   const renderLabelAgency = (label) => ({
    color: 'blue',
    content: label.name,
    icon: 'check',
  })

  const errorLabel = <Label color="red" pointing/>

   return (
      <div>   
          
          <Container>

          <Confirm
          size={'tiny'}
          open={this.state.confirm}
          confirmButton={'Yes'}
          content={'Are you sure you want to delete this invoice?'}
          onCancel={()=>{this.setState({confirm:false})}}
          onConfirm={()=>{ 
             this.setState({confirm:false})
              this.handleDelete(this.state.selectedinvoice)

           }}
        />


              <Header as={'H1'}>Invoices</Header>
              <Form onValidSubmit={this.handleCreate}>
              <Form.Group  unstackable widths={4}>
              <Form.Field>
               <label>From Date </label>
                 <DateInput closable={true}
                  required
                  name="selectedfrom"
                  placeholder="Date"
                  value={this.state.selectedfrom}
                  dateFormat={'MM-DD-YYYY'}
                  iconPosition="left"
                  onChange={this.handleDateChange}
                />
                            
              </Form.Field>
              <Form.Field>
               <label>To Date </label>
                 <DateInput closable={true}
                  required
                  name="selectedto"
                  placeholder="Date"
                  value={this.state.selectedto}
                  dateFormat={'MM-DD-YYYY'}
                  iconPosition="left"
                  onChange={this.handleDateChange}
                />
                            
              </Form.Field>
              <Form.Field>
                  <label>Select Agency </label>
                  <Dropdown
                    name='agency_id'
                    selection
                    fluid
                    options={agencyOptions}
                    onChange={this.handleChangeAgency}
                    placeholder='Choose an option'
                    renderLabel={renderLabelAgency}
                    required
                    validationErrors={{
                      isDefaultRequiredValue: 'You need to select a agency',
                    }}
                    errorLabel={errorLabel}
                  />
                </Form.Field>
                <Form.Field>
                <label>&nbsp;&nbsp;&nbsp;</label>
                
                <Popup
                  content={ this.state.errormessage } 
                  positionFixed
                  on='click'
                  open={this.state.errorpop}
                  onClose={()=>{this.setState({errorpop:false})}}
                  trigger={<Button color='blue' content='Submit' fluid  >Create Invoice</Button> }
                />
                </Form.Field>
                                    </Form.Group>
              </Form>
              <Table singleLine>
    <Table.Header>
      <Table.Row>
       <Table.HeaderCell>#</Table.HeaderCell>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Date</Table.HeaderCell>
        <Table.HeaderCell>Count</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>Total</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>

        {this.state.invoices.map((invoice)=>(
          <Table.Row>
          <Table.Cell>{invoice.invoicenum}</Table.Cell>
          <Table.Cell>{invoice.agency.name}</Table.Cell>
          <Table.Cell>From:<Label color={'green'}><Moment date={invoice.from} format="L"  /> </Label>- To: <Label color={'red'}><Moment date={invoice.to} format="L"  /> </Label> </Table.Cell>
          <Table.Cell>{invoice.count}</Table.Cell>
          <Table.Cell><Label color={'violet'}>{invoice.status}</Label></Table.Cell>
          <Table.Cell>${invoice.total}</Table.Cell>
          <Table.Cell><Button as={Link} to={{pathname: "/invoice/", state: { "schedules": invoice.patients, "from":invoice.from, "to":invoice.to, "id":invoice._id } }}color={'blue'} fluid>Detail</Button></Table.Cell>
          <Table.Cell> <Button color={'red'}   onClick={() => { this.setState({confirm:true, selectedinvoice:invoice._id}) }}  fluid>Delete</Button></Table.Cell>
         </Table.Row>
        ))}            
      
    </Table.Body>
  </Table>
          </Container>

      </div>
   );
 }
}
 
export default Invoices;
