import React from 'react'; 
import { Table, Container, Button, Header, Icon, Modal, Dimmer, Loader, Segment, Label, Confirm, Popup, Pagination, Input} from 'semantic-ui-react'
import axios from 'axios';
import Moment from 'react-moment';  
import moment from 'moment';
import Auth from '../../validators/auth';
import {
  DateInput,
  TimeInput,
  DateTimeInput,
  DatesRangeInput
} from 'semantic-ui-calendar-react';
import {
  Form, Dropdown} from 'formsy-semantic-ui-react';
  import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link
  } from "react-router-dom";
class Payrolls extends React.Component {
  constructor(props) {
    super(props) 
    this.state = {
      payrolls:[],
       selectedfrom:null,
       selectedto:null,
       selectedtherapist:null,
       therapists:[],
       confirm:false,
       selectedpayrol:null
   };
  } 

  componentDidMount(){

    axios.get(`https://api.pureptinc.com/therapists`)
        .then(res => { 
           
            this.setState({
              therapists:res.data.therapists,
                dimmer:false
              })
        
        })

        this.refreshContent()
  }

  handleChangeTherapist= (e, { value }) => { 
  
    this.setState({
      selectedtherapist:value,
      selectedtherapistname: e.target.innerText
    })
     
   }

  refreshContent() {
    
    const authenteicated =  Auth.isAuthenticated(); 

    if( authenteicated.role === 'therapist' ){
      axios.get(`https://api.pureptinc.com/payrolls`, { params: { therapistid:authenteicated.userid  } } )
      .then(res => { 
        console.log(res)
          this.setState({
            payrolls:res.data.schedules, 
            })
      
      })
    }
    else {
      axios.get(`https://api.pureptinc.com/payrolls`, { params: { } } )
      .then(res => { 
        console.log(res)
          this.setState({
            payrolls:res.data.schedules, 
            })
      
      })
    }

    
  }

  handleDateChange = (event, {name, value}) => {
    
    this.setState({  [name]: value  })
    
  }

  handleDelete = id => {
    axios.delete(`https://api.pureptinc.com/payrolls/`+id).then(res => {this.refreshContent();}); 
  }

  handleCreate = () => {

    axios.get(`https://api.pureptinc.com/schedules/payrolls`, { params: {
      therapistid:this.state.selectedtherapist,
      from:moment(this.state.selectedfrom, 'MM-DD-YYYY').format(),
      to:moment(this.state.selectedto, 'MM-DD-YYYY').format(),
    } } )
    .then(res => { 
        
       
        const invoicedata = res.data[0].schedules[0];
        axios.post(`https://api.pureptinc.com/payrolls/`, { 
            therapistid:this.state.selectedtherapist,
            therapistname:this.state.selectedtherapistname,   
            company_id:"5f777a3e2b5082092ac62dc0",  
            from:moment(this.state.selectedfrom, 'MM-DD-YYYY').format(),
            to:moment(this.state.selectedto, 'MM-DD-YYYY').format(),
            created_at: Date.now(),
            updated_at: Date.now(),
            count:Number(invoicedata.count),
            total:Number(invoicedata.total),
            schedules:invoicedata.schedules
            
        })
        .then(res => {
          
          this.refreshContent()
          
        })  
      

    }) 

   
    
  }

  
 
 render() {

  const therapistsOptions = this.state.therapists.map((p, index) => {
    return {...p, key: index, text: p.fname + ' ' + p.lname, value:p._id}
  })
   const renderLabelTherapist = (label) => ({
    color: 'blue',
    content: ' ' + label.fname + ' ' + label.lname,
    
  })

  const errorLabel = <Label color="red" pointing/>

  const authdata = Auth.isAuthenticated()

   return (
      <div>   

<Confirm
          size={'tiny'}
          open={this.state.confirm}
          confirmButton={'Yes'}
          content={'Are you sure you want to delete this payroll?'}
          onCancel={()=>{this.setState({confirm:false})}}
          onConfirm={()=>{ 
             this.setState({confirm:false})
              this.handleDelete(this.state.selectedpayrol)

           }}
        />
          
          <Container>
              <Header as={'H1'}>Payrolls</Header>
           


              {
          authdata.role === 'company' ?    <Form onValidSubmit={this.handleCreate}>
          <Form.Group  unstackable widths={4}>
          <Form.Field>
           <label>From Date </label>
             <DateInput closable={true}
              required
              name="selectedfrom"
              placeholder="Date"
              value={this.state.selectedfrom}
              dateFormat={'MM-DD-YYYY'}
              iconPosition="left"
              onChange={this.handleDateChange}
            />
                        
          </Form.Field>
          <Form.Field>
           <label>To Date </label>
             <DateInput closable={true}
              required
              name="selectedto"
              placeholder="Date"
              value={this.state.selectedto}
              dateFormat={'MM-DD-YYYY'}
              iconPosition="left"
              onChange={this.handleDateChange}
            />
                        
          </Form.Field>
          <Form.Field>
              <label>Select Thrapist </label>
              <Dropdown
                name='agency_id'
                selection
                fluid
                options={therapistsOptions}
                onChange={this.handleChangeTherapist}
                placeholder='Choose an option'
                renderLabel={renderLabelTherapist}
                required
                validationErrors={{
                  isDefaultRequiredValue: 'You need to select a therapist',
                }}
                errorLabel={errorLabel}
              />
            </Form.Field>
            <Form.Field>
            <label>&nbsp;&nbsp;&nbsp;</label>
            <Button color='blue' content='Submit' fluid  >Create Payroll</Button> 
            </Form.Field>
                                </Form.Group>
          </Form> : null
        }
              <Table singleLine>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Date</Table.HeaderCell>
        <Table.HeaderCell>Count</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>Total</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
        {
          authdata.role === 'company' ? <Table.HeaderCell></Table.HeaderCell> : null
        }
      </Table.Row>
    </Table.Header>

    <Table.Body>

        {this.state.payrolls.map((payroll)=>(
          <Table.Row>
          <Table.Cell>{payroll.therapistname}</Table.Cell>
          <Table.Cell>From:<Label color={'green'}><Moment date={payroll.from} format="L"  /> </Label>- To: <Label color={'red'}><Moment date={payroll.to} format="L"  /> </Label> </Table.Cell>
          <Table.Cell>{payroll.count}</Table.Cell>
          <Table.Cell><Label color={'violet'}>{payroll.status}</Label></Table.Cell>
          <Table.Cell>${payroll.total}</Table.Cell>
          <Table.Cell><Button as={Link} to={{pathname: "/payroll/", state: { "schedules": payroll.schedules, "from":payroll.from, "to":payroll.to, "id":payroll._id } }}color={'blue'} fluid>Detail</Button></Table.Cell>
          

{
          authdata.role === 'company' ? <Table.Cell> <Button color={'red'}   onClick={() => { this.setState({selectedpayrol:payroll._id, confirm:true}) }}  fluid>Delete</Button></Table.Cell> : null
        }
         </Table.Row>
        ))}            
      
    </Table.Body>
  </Table>
          </Container>

      </div>
   );
 }
}
 
export default Payrolls;
