import React from "react";
import {  Grid, Table, Radio, Button, Label, Segment, Header, GridColumn } from "semantic-ui-react";
import moment from 'moment'
import {
  Form, Dropdown, Checkbox
} from 'formsy-semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import {
  DateInput,
  TimeInput,
  DateTimeInput,
  DatesRangeInput
} from 'semantic-ui-calendar-react';
import axios from 'axios';
import 'semantic-ui-css/semantic.min.css'


class PhysicalTherapyNote extends React.Component {
  constructor(props) {
    super(props); 
     
    this.props=props;
    this.state = {
      "job_id": this.props.job_id,
      "type":this.props.type, 
      "created_at": new Date,
      "updated_at": new Date, 
      agency:this.props.agency,
      jobdetail:{},
      data:{
        "timein": "",
        "timeout": "",
        "date": "",
        "patientname": "",
        "mr": "",
        "patient_status": "", 
        "item_visit": [
          "Bed Mobility Training",
          "Transfers Training",
          "Gait Training",
          "Stairs Training",
          "Balance Training",
          "Strengthening",
          "ROM",
          "Instruct in HEP",
          "Safety Education",
          "Decrease Pain",
          "Stretching",
          "CG Training",
          "Ther ex",
          "ROM"
        ],
        strength_lle:"",
        balance_stand:"",
        strength_lue:"",
        gait_assitant:"",
        transfer_sit_stand:[],
        bed_sup:"",
        "item_other": "",
        "pain_status": true,
        "pain_location": [],
        "pain_intensity": "",
        "pain_relief_measures": "Rest/medication",
        "pain_patient_complain": "General weakness and pain limiting the ability of ambulating and performing ADL.",
        "vitalbp": "",
        "vitalhr": "",
        "vitalrr": "",
        "vitaltemp": "",
        "objectives": [],
        "objective_notes": "",
        "universal_precautions_status": true,
        "next_visit": "Will Continue with POC to increase bed mobility, transfer, balance, gait, endurance, reduce pain, educate home safety and stretching.",
        "pt_conferance": false,
        "status_follow":true,
        "gait_assistive_device":[],
        "homebound_status":"Residual weakness",
        index:1,
        "cognition":[
          'Alert', 'Oriented', 'Forgetful'
        ]
      }
    }
    
  }

  componentDidMount(){


    axios.get(`https://api.pureptinc.com/jobs/`+this.state.job_id )
    .then(res => {
      console.log(res.data[0])
       this.setState({jobdetail:res.data[0]})
    }) 


    if(this.props.new){

      axios.get(`https://api.pureptinc.com/forms/`, {params: {job_id:this.props.job_id,type: 'Note',}} )
      .then(res => { 
         this.setState({index:res.data.length + 1})

         if(res.data.length > 0) {
          const notedata = res.data.slice(-1)[0]
          this.setState(prevState => ({
        
            data: {                    
                ...prevState.data,    
                strength_lle:notedata.data.strength_lle,
                strength_rue:notedata.data.strength_rue,
                strength_rle:notedata.data.strength_rle,
                strength_lue:notedata.data.strength_lue,
                balance_stand:notedata.data.balance_stand, 
                gait_assitant:notedata.data.gait_assitant,
                transfer_sit_stand:notedata.data.transfer_sit_stand,
                bed_sup:notedata.data.bed_sup,
                pain_location:notedata.data.pain_location,
                pain_intensity:notedata.data.pain_severity,
                vitalrr:notedata.data.vitalrr,
                vitalhr:notedata.data.vitalhr,
                vitaltemp:notedata.data.vitaltemp,
                vitalbp:notedata.data.vitalbp,
                gait_assistive_device:notedata.data.gait_assistive_device,
                date:notedata.data.date,
                timein:notedata.data.timein,
                timeout:notedata.data.timeout,
            }
          })) 
         }

         else {
          axios.get('https://api.pureptinc.com/forms/', {params: {
            job_id:this.props.job_id,
            type: 'Evaluation', 
           
           } 
           
           }).then(res => {
            console.log(res.data)
             if(res.data.length > 0){
              const evalform = res.data[0]
           
              this.setState(prevState => ({
                data: {                    
                    ...prevState.data,    
                    strength_lle:evalform.data.strength_lle,
                    strength_rue:evalform.data.strength_rue,
                    strength_rle:evalform.data.strength_rle,
                    strength_lue:evalform.data.strength_lue,
                    balance_stand:evalform.data.balance_stand, 
                    gait_assitant:evalform.data.gait_assitant,
                    transfer_sit_stand:evalform.data.transfer_sit_stand,
                    bed_sup:evalform.data.bed_sup,
                    pain_location:evalform.data.pain_location,
                    pain_intensity:evalform.data.pain_severity,
                    vitalrr:evalform.data.vitalrr,
                    vitalhr:evalform.data.vitalhr,
                    vitaltemp:evalform.data.vitaltemp,
                    vitalbp:evalform.data.vitalbp,
                    gait_assistive_device:evalform.data.gait_assistive_device,
                    date:evalform.data.date,
                    timein:evalform.data.timein,
                    timeout:evalform.data.timeout,
                    mr:evalform.data.mr
                }
              })) 
             }
      
          })
         }


      })  
    
  
       
    }
    else {
     
      this.setState({
        data:this.props.data
      })
    }
     
 
    
  }
 
   
  handleDropChange = (event, {name, value}) => {
     
    this.setState(prevState => ({
      data: {                    
        ...prevState.data,    
        [name]: value    
    }
  }))
  }

  checkboxChangeHandler = (event, {name, checked}) => {
     
    this.setState(prevState => ({
      data: {                    
        ...prevState.data,    
        [name]: checked    
    } 
  }))
    
  };
   

  handleChange = (event, data) => {
    console.log(data)
    this.name = event.target.name;
    
    this.setState(prevState => ({
      data: {                    
        ...prevState.data,    
        [event.target.name]: event.target.value   
    }
       
  }))
 }

 handleRadioChange = (event, {name, value}) => {
     
  this.setState(prevState => ({
    data: {                    
      ...prevState.data,    
      [name]: value    
  }
}))
}

 handleDateChange = (event, {name, value}) => {
   
  
 
  this.setState(prevState => ({
  
    data: {                    
      ...prevState.data,    
      [name]: value   
  }
}))
  
}
 
 handleSubmit = () => {
  this.setState({ 
    dimmer: true
  }) 

  axios.post(`https://api.pureptinc.com/forms/`, this.state)
  .then(res => {
    this.setState({loading:false});
    this.props.refreshContent(this.state.job_id)       
    this.props.setCloseForm()       
  })       
   
     
}

 
 
  render() {
 
    const errorLabel = <Label color="red" pointing="left" />;

    const respiratoryrate = [
        { text: '16', value: '16' },
        { text: '17', value: '17' },
        { text: '18', value: '18' },
        { text: '19', value: '19' },
        { text: '20', value: '20' },
        { text: '21', value: '21' }  
      ];

    const vision = [
      { text: 'Lt', value: 'Lt' },
      { text: 'Rt', value: 'Rt' },
      { text: 'Both', value: 'Both' },
      { text: 'Blind', value: 'Blind' } 
    ];  

    const hearing = [
      { text: 'Lt', value: 'Lt' },
      { text: 'Rt', value: 'Rt' },
      { text: 'Both', value: 'Both' },
      { text: 'Deaf', value: 'Deaf' } 
    ];  

    const temp = [
        { text: '98.1', value: '98.1' },
        { text: '98.2', value: '98.2' },
        { text: '98.3', value: '98.3' },
        { text: '98.4', value: '98.4' },
        { text: '98.5', value: '98.5' },
        { text: '98.6', value: '98.6' }  
      ]; 

      const living = [
        { text: 'Apt', value: 'Apt' },
        { text: 'House', value: 'House' },
        { text: 'B&C', value: 'B&C' },
        { text: 'Mobile', value: 'Mobile' },
        { text: 'Facility', value: 'Facility' },
      ];    

      const equipments = [
        {name:'ptequipment', text:"FWW", value: "FWW"},
        {name:'ptequipment', text:"4WW", value: "4WW"},
        {name:'ptequipment', text:"PUW", value: "PUW"},
        {name:'ptequipment', text: "Shower chair/tub bench", value: "Shower chair/tub bench"},
        {name:'ptequipment', text: "Raised toilet seat", value: "Raised toilet seat"},
        {name:'ptequipment', text: "SPC", value: "SPC"},
        {name:'ptequipment', text: "Hosp bed", value: "Hosp bed"},
        {name:'ptequipment', text: "Hoyer lift", value: "Hoyer lift"},
        {name:'ptequipment', text: "HW", value: "HW"},
        {name:'ptequipment', text: "Oxygen tube", value: "Oxygen tube"},
        {name:'ptequipment', text: "Commode", value: "Commode"},
        {name:'ptequipment', text: "W/C", value: "W/C"},
        {name:'ptequipment', text: "QC", value: "QC"}, 
        {name:'ptequipment', text: "Crutches", value: "Crutches"} 
    ]

    const grades = [
        { text: 'P/P-', value: 'P/P-' },
        { text: 'P+/P', value: 'P+/P' },
        { text: 'F-/P+', value: 'F-/P+' },
        { text: 'F/P+', value: 'F/P+' },
        { text: 'F+/P+', value: 'F+/P+' },
        { text: 'F+/F-', value: 'F+/F-' },
        { text: 'G-/F-', value: 'G-/F-' },
        { text: 'G-/F', value: 'G-/F' },
        { text: 'G/F', value: 'G/F' },
        { text: 'G+/F+', value: 'G+/F+' },
        { text: 'G+/G-', value: 'G+/G-' } 

    ]

    const strenght = [
      { text: '0/5 MMT', value: '0/5 MMT' },
      { text: '1-/5 MMT', value: '1-/5 MMT' },
      { text: '1/5 MMT', value: '1/5 MMT' },
      { text: '1+/5 MMT', value: '1+/5 MMT' },
      { text: '2-/5 MMT', value: '2-/5 MMT' },
      { text: '2/5 MMT', value: '2/5 MMT' },
      { text: '2+/5 MMT', value: '2+/5 MMT' },
      { text: '3-/5 MMT', value: '3-/5 MMT' },
      { text: '3/5 MMT', value: '3/5 MMT' },
      { text: '3+/5 MMT', value: '3+/5 MMT' }, 
      { text: '4-/5 MMT', value: '4-/5 MMT' },
      { text: '4/5 MMT', value: '4/5 MMT' },
      { text: '4+/5 MMT', value: '4+/5 MMT' } 
      
  ]

  const pains = [
    { text: 'C/S', value: 'C/S' },
    { text: 'T/S', value: 'T/S' },
    { text: 'L/S', value: 'L/S' },
    { text: 'Shoulders', value: 'Shoulders' },
    { text: 'Elbows', value: 'Elbows' },
    { text: 'Wrists', value: 'Wrists' },
    { text: 'Hips', value: 'Hips' },
    { text: 'Knees', value: 'Knees' },
    { text: 'Ankles', value: 'Ankles' },
    { text: 'Lower Back', value: 'Lower Back' }   
  ];


const note = [
  { text: 'Extension and Flexion. Add-Abd to both shoulders. Pendulum Ex. to both Shoulders.', value: 'Extension and Flexion. Add-Abd to both shoulders. Pendulum Ex. to both Shoulders.' },
  { text: 'Instruction to Caregiver with home exercise program and safety ', value: 'Instruction to Caregiver with home exercise program and safety ' },
  { text: 'Williams Ex’s to the lower back and bridge Ex’s. ', value: 'Williams Ex’s to the lower back and bridge Ex’s. ' },
  { text: 'Standing balance and strengthening exercise including marching in place with big arm swings', value: 'Standing balance and strengthening exercise including marching in place with big arm swings' },
  { text: 'Touch-down weight-bearing for affected R-side. ', value: 'Touch-down weight-bearing for affected R-side. ' },
  { text: 'Knee straightening stretch, Knee dangling/swinging and Knee bending (sitting knee flexion) ', value: 'Knee straightening stretch, Knee dangling/swinging and Knee bending (sitting knee flexion) ' },
  { text: 'Scapula stabilization and weight bearing exercises to the affected side.', value: 'Scapula stabilization and weight bearing exercises to the affected side.' },
  { text: 'Fine motor training with tendon gliding and nerve gliding exercises', value: 'Fine motor training with tendon gliding and nerve gliding exercises' },
  { text: 'Standing iliotibial band stretch, knee to chest and supine hamstring stretch', value: 'Standing iliotibial band stretch, knee to chest and supine hamstring stretch' },
  { text: 'Pursed Lip breathing and deep breathing exercises', value: 'Pursed Lip breathing and deep breathing exercises' },
  { text: 'Sitting Balance Exs with upper body twist, hip marching and arm raises.', value: 'Sitting Balance Exs with upper body twist, hip marching and arm raises.' },
  {text:'Standing balance exs with side walking, heel to toe walk and marching.', value:'Standing balance exs with side walking, heel to toe walk and marching.'},
  {text:'Strengthening (Ankle pump, SLR, Add-Abd Hips, Quad- Gluteal- Hamstring muscles) all 10 x 2. ', value:'Strengthening (Ankle pump, SLR, Add-Abd Hips, Quad- Gluteal- Hamstring muscles) all 10 x 2. '},
  {text:'Strengthening  Ex`s (chair march, arm swings, shoulder circles, ankle loosening, spine twists) all 10 x 2', value:'Strengthening  Ex`s (chair march, arm swings, shoulder circles, ankle loosening, spine twists) all 10 x 2'},
  {text:'Sup to Sit, Sit to Stand and lateral weight shifts with head turns', value:'Sup to Sit, Sit to Stand and lateral weight shifts with head turns'},
  {text:'Passive stretching and Positioning in bed.', value:'Passive stretching and Positioning in bed.'}
  
]
const edema = [
  { text: 'Min', value: 'min' },
  { text: 'Mod', value: 'mod' },
  { text: 'Sever', value: 'sever' }  
]

const problems = [
  { text: 'Ind w/bed mob', value: 'Ind w/bed mob' },
  { text: 'Strength', value: 'Strength' },
  { text: 'Ind w/ transfers', value: 'Ind w/ transfers' },
  { text: 'Ind w/ HEP', value: 'Ind w/ HEP' },
  { text: 'Ind w/ HEP', value: 'Ind w/ HEP' },
  { text: 'Ind w/ GT', value: 'Ind w/ GT' },
  { text: 'Safety', value: 'Safety' },
  { text: 'Ind w/ stairs', value: 'Ind w/ stairs' },
  { text: 'CG knowledge', value: 'CG knowledge' },
  { text: 'Balance', value: 'Balance' } 
]

const relateditems = [
 
  { text: 'Bed Mobility Training', value: 'Bed Mobility Training' },
  { text: 'Transfers Training', value: 'Transfers Training' },
  { text: 'Gait Training', value: 'Gait Training' },
  { text: 'Stairs Training', value: 'Stairs Training' },
  { text: 'Balance Training', value: 'Balance Training' },
  { text: 'Strengthening', value: 'Strengthening' },
  { text: 'Stretching', value: 'Stretching' },
  { text: 'ROM', value: 'ROM' },
  { text: 'Instruct in HEP', value: 'Instruct in HEP' },
  { text: 'Safety Education', value: 'Safety Education' },
  { text: 'CG Training', value: 'CG Training' },
  { text: 'Decrease Pain', value: 'Decrease Pain' },
  { text: 'Ther ex', value: 'Ther ex' },
  { text: 'ROM', value: 'ROM' },
]

const txplan = [
 
  { text: 'Bed Mobility Training', value: 'Bed Mobility Training' },
  { text: 'Transfers Training', value: 'Transfers Training' },
  { text: 'Gait Training', value: 'Gait Training' },
  { text: 'Stairs Training', value: 'Stairs Training' },
  { text: 'Balance Training', value: 'Balance Training' },
  { text: 'Strengthening', value: 'Strengthening' },
  { text: 'Stretching', value: 'Stretching' },
  { text: 'ROM', value: 'ROM' },
  { text: 'Instruct in HEP', value: 'Instruct in HEP' },
  { text: 'Safety Education', value: 'Safety Education' },
  { text: 'CG Training', value: 'CG Training' },
  { text: 'Decrease Pain', value: 'Decrease Pain' },
  { text: 'Ther ex', value: 'Ther ex' },
  { text: 'ROM', value: 'ROM' },
]


const weeks = [
  { text: '1 week', value: '1 week' },
  { text: '2 weeks', value: '2 weeks' },
  { text: '3 weeks', value: '3 weeks' },
  { text: '4 weeks', value: '4 weeks' },
  { text: '5 weeks', value: '5 weeks' },
  { text: '6 weeks', value: '6 weeks' },
  { text: '7 weeks', value: '7 weeks' },
  { text: '8 weeks', value: '8 weeks' },
  { text: '9 weeks', value: '9 weeks' }

]

const goals = [
  { text: 'Unable', value: 'Unable' },
  { text: 'Total-A', value: 'Total-A' },
  { text: 'MAX-A', value: 'sever' },
  { text: 'MAX/MOD-A', value: 'MAX/MOD-A' },
  { text: 'MOD-A', value: 'MOD-A' },
  { text: 'MOD/MIN-A', value: 'MOD/MIN-A' },
  { text: 'MIN-A', value: 'MIN-A' },
  { text: 'MIN-A/CGA', value: 'MIN-A/CGA' },
  { text: 'CGA', value: 'CGA' },
  { text: 'CGA/SBA', value: 'CGA/SBA' },
  { text: 'SBA', value: 'SBA' },
  { text: 'SBA/S', value: 'SBA/S' },
  { text: 'S', value: 'S' },
  { text: 'S/INDP', value: 'S/INDP' },
  { text: 'INDP', value: 'INDP' }
]

const patientstatus = [
  { text: 'Alert', value: 'Alert' },
  { text: 'Oriented', value: 'Oriented' },
  { text: 'Forgetful', value: 'Forgetful' },
  { text: 'Confused', value: 'Confused' },
  { text: 'Disoriented', value: 'Disoriented' },
]

 




    return (
      <div className="container"> 
        <div className="formcontainer">
        <Header textAlign="center">PHYSICAL THERAPY CLINICAL NOTE</Header>
        <Header.Subheader>Agency: {this.state.agency}</Header.Subheader>
        <Form onValidSubmit={this.handleSubmit}>
        <Form.Group widths="equal">

        <Form.Input   disabled  label="Patient:"  placeholder="John Doe" value={this.state.jobdetail.patientname}    onChange={this.handleChange}  name='patientname'  /> 
        <Form.Input  value={this.state.data.mr} fluid     label="MR:"   onChange={this.handleChange}  name='mr'  />

        </Form.Group>

        <Form.Group widths="2">

        <Form.Dropdown value={this.state.data.cognition} multiple cognition onChange={this.handleDropChange} fluid label="Cognition:" name="cognition" placeholder="Select Reason" validationErrors={{  isDefaultRequiredValue: 'You need to select a reason', }} errorLabel={errorLabel}  options={patientstatus} selection   />          
        <Form.Checkbox defaultChecked={this.state.data.status_follow} label="Can follow instructions" toggle name="status_follow"  onChange={this.checkboxChangeHandler} ></Form.Checkbox>    
        </Form.Group>


        <Form.Group widths="2">

        <Form.Dropdown value={this.state.data.item_visit}  onChange={this.handleDropChange} fluid label="Items related to visit:" name="item_visit" placeholder="Select Reason" validationErrors={{  isDefaultRequiredValue: 'You need to select a item', }} errorLabel={errorLabel}  options={relateditems} multiple selection   />          
        <Form.Input value={this.state.data.item_other} fluid     label="Other:"   onChange={this.handleChange}  name='item_other'  />
        </Form.Group>

        <Header>Subjective (Patient feedback):</Header>

        <Form.Group widths='5'>
            <Form.Field>
            <lable>&nbsp;</lable>
            <Form.Checkbox  defaultChecked={this.state.data.pain_status} label="Pain" toggle name="pain_status"  onChange={this.checkboxChangeHandler} ></Form.Checkbox>   
            </Form.Field>   
            <Form.Dropdown
                            fluid     
                            label="Location" 
                            name="pain_location"
                            placeholder="Select Value" 
                            validationErrors={{
                            isDefaultRequiredValue: 'You need to select a value',
                            }}
                            errorLabel={errorLabel}
                            options={pains}
                            selection
                            multiple
                            value={this.state.data.pain_status}
                            onChange={this.handleDropChange}
                            value={this.state.data.pain_location}
                        />  
            <Form.Input value={this.state.data.pain_intensity} fluid     label="Intensity:"   onChange={this.handleChange}  name='pain_intensity'  />
            <Form.Input value={this.state.data.pain_relief_measures} fluid     label="Relief measures:"   onChange={this.handleChange}  name='pain_relief_measures'  />
            <Form.Input value={this.state.data.pain_patient_complain} fluid     label="Patient Complains of:"   onChange={this.handleChange}  name='pain_patient_complain'  />
        </Form.Group>


        <Header>Objective Tx performed this visit:</Header>

        <Form.Group widths="4">
                             <Form.Input  value={this.state.data.vitalbp} fluid     label="BP:"  placeholder="Blood Preasure"   onChange={this.handleChange}  name='vitalbp'  />
                             <Form.Input  value={this.state.data.vitalhr} fluid     label="HR:"  placeholder="Heart Rate"   onChange={this.handleChange}  name='vitalhr'  />
                             <Form.Dropdown
                             fluid
                                   label="RR:" 
                                    name="vitalrr"
                                    placeholder="Select Value" 
                                    validationErrors={{
                                    isDefaultRequiredValue: 'You need to select a value',
                                    }}
                                    errorLabel={errorLabel}
                                    options={respiratoryrate}
                                    selection 
                                    clearable
                                    onChange={this.handleDropChange}
                                    value={this.state.data.vitalrr}
                                />

                                <Form.Dropdown
                                   fluid     
                                   label="Temp:" 
                                    name="vitaltemp"
                                    placeholder="Select Value" 
                                    validationErrors={{
                                    isDefaultRequiredValue: 'You need to select a value',
                                    }}
                                    errorLabel={errorLabel}
                                    options={temp}
                                    selection
                                    clearable
                                    onChange={this.handleDropChange}
                                    value={this.state.data.vitaltemp}
                                />
         </Form.Group>

         <Form.Dropdown  fluid label="Objectives:"  name="objectives"  placeholder="Select Objective"  validationErrors={{ isDefaultRequiredValue: 'You need to select a value',   }}  errorLabel={errorLabel}   options={note}  selection multiple  clearable   onChange={this.handleDropChange}  value={this.state.data.objectives} />
         <Form.TextArea value={this.state.data.objective_notes}  label="Objective Notes" name='objective_notes'   onChange={this.handleChange}  ></Form.TextArea>

        <Form.Checkbox defaultChecked={this.state.data.universal_precautions_status} label="Universal Precautions" toggle name="universal_precautions_status"  onChange={this.checkboxChangeHandler} ></Form.Checkbox> 
        
        <Header>Assessment. Patient progressing towards our goal as follows:</Header>

        <Form.Group widths="equal">
                
        <Form.Dropdown
            fluid     
            label="Bed Mobility:" 
            name="bed_sup"
            placeholder="Select Value" 
            validationErrors={{
            isDefaultRequiredValue: 'You need to select a value',
            }}
            errorLabel={errorLabel}
            options={goals}
            selection
            clearable
            onChange={this.handleDropChange}
            value={ this.state.data.bed_sup}
                         /> 

        <Form.Dropdown
            fluid     
            label="Sit to stand:" 
            name="transfer_sit_stand"
            placeholder="Select Value" 
            validationErrors={{
            isDefaultRequiredValue: 'You need to select a value',
            }}
            errorLabel={errorLabel}
            options={goals}
            selection
            clearable
            onChange={this.handleDropChange}
            value={ this.state.data.transfer_sit_stand}
         />      
         
         <Form.Dropdown
            fluid     
            label="Gait:"
            name="gait_assitant"
            placeholder="Select Value" 
            validationErrors={{
            isDefaultRequiredValue: 'You need to select a value',
            }}
            errorLabel={errorLabel}
            options={goals}
            selection
            clearable
            onChange={this.handleDropChange}  
            value={ this.state.data.gait_assitant}  /> 

                         
          <Form.Dropdown
            fluid     
            label="Assistive Device:"
            name="gait_assistive_device"
            placeholder="Select Value" 
            validationErrors={{
            isDefaultRequiredValue: 'You need to select a value',
            }}
            errorLabel={errorLabel}
            options={equipments}
            selection
            clearable
            onChange={this.handleDropChange}
            value={ this.state.data.gait_assistive_device}
        />                      


          
        </Form.Group> 

        <Form.Group widths="equal">
        <Form.Dropdown
            fluid     
            label="Standing Balance:" 
            name="balance_stand"
            placeholder="Select Value" 
            validationErrors={{
            isDefaultRequiredValue: 'You need to select a value',
            }}
            errorLabel={errorLabel}
            options={grades}
            selection
            clearable
            onChange={this.handleDropChange}
            value={ this.state.data.balance_stand} 
                                />        
        <Form.Dropdown
            fluid     
            label="Strength LUE:" 
            name="strength_lue"
            placeholder="Select Value" 
            validationErrors={{
            isDefaultRequiredValue: 'You need to select a value',
            }}
            errorLabel={errorLabel}
            options={strenght}
            selection
            clearable
            onChange={this.handleDropChange}
            value={ this.state.data.strength_lue}
                         />
                         <Form.Dropdown
            fluid     
            label="Strength LLE:" 
            name="strength_lle"
            placeholder="Select Value" 
            validationErrors={{
            isDefaultRequiredValue: 'You need to select a value',
            }}
            errorLabel={errorLabel}
            options={strenght}
            selection
            clearable
            onChange={this.handleDropChange}
            value={ this.state.data.strength_lle}
                         /> 

<Form.Dropdown
            fluid     
            label="Strength RUE:" 
            name="strength_rue"
            placeholder="Select Value" 
            validationErrors={{
            isDefaultRequiredValue: 'You need to select a value',
            }}
            errorLabel={errorLabel}
            options={strenght}
            selection
            clearable
            onChange={this.handleDropChange}
            value={ this.state.data.strength_rue}
                         />
                         <Form.Dropdown
            fluid     
            label="Strength RLE:" 
            name="strength_rle"
            placeholder="Select Value" 
            validationErrors={{
            isDefaultRequiredValue: 'You need to select a value',
            }}
            errorLabel={errorLabel}
            options={strenght}
            selection
            clearable
            onChange={this.handleDropChange}
            value={ this.state.data.strength_rle}
                         /> 
        </Form.Group>

        <Header>Homebound Status:</Header>
        <Form.Group widths='3'>
                    
                    <Radio checked={this.state.data.homebound_status === 'Unsafe to leave home unassisted'}  label='Unsafe to leave home unassisted' name='homebound_status' value='Unsafe to leave home unassisted'   onChange={this.handleRadioChange} />
                    <Radio checked={this.state.data.homebound_status === 'Need assistance for all activities'}  label='Need assistance for all activities' name='homebound_status' value='Need assistance for all activities'   onChange={this.handleRadioChange} />
                    <Radio checked={this.state.data.homebound_status === 'Residual weakness'}  label='Residual weakness' name='homebound_status'  value='Residual weakness'    onChange={this.handleRadioChange} />
         </Form.Group>

         <Header>Plan for next visit:</Header>
         <Form.TextArea value={this.state.data.next_visit}  name='next_visit'   onChange={this.handleChange}  ></Form.TextArea>
         <Form.Checkbox value={this.state.data.pt_conferance} label="Conference with P.T." toggle name="pt_conferance"  onChange={this.checkboxChangeHandler} ></Form.Checkbox> 
    

         <Form.Group widths='3'>
            <DateInput closable={true}   name="date"  value={this.state.data.date}  placeholder="Date"   dateFormat={'MM-DD-YYYY'} iconPosition="left"  onChange={this.handleDateChange}  />
            <TimeInput closable={true}
                    
                   name="timein"
                   placeholder="Time In"
                   value={this.state.data.timein}
                   timeFormat={'ampm'}
                   iconPosition="left"
                   onChange={this.handleDateChange}
                  
                 />
             <TimeInput closable={true}
                    
                   name="timeout"
                   placeholder="Time Out"
                   value={this.state.data.timeout}
                   timeFormat={'ampm'}
                   iconPosition="left"
                   onChange={this.handleDateChange}
                 />    
          </Form.Group>
                                    
          <Button content='Submit' disabled={!this.props.new} primary fluid >Submit</Button>
     </Form>

     
        </div>
      </div>
    );
  }
  
};

export default PhysicalTherapyNote;
