import React from "react";
import {  Grid, Table, Radio, Button, Label, Segment, Header, Divider } from "semantic-ui-react";
import moment from 'moment'
import {
  Form, Dropdown, Checkbox
} from 'formsy-semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import {
  DateInput,
  TimeInput,
  DateTimeInput,
  DatesRangeInput
} from 'semantic-ui-calendar-react';
import axios from 'axios';
import 'semantic-ui-css/semantic.min.css'


class OccupationalTherapyEval extends React.Component {
  constructor(props) {
    super(props);  
    this.props=props;
    this.state = {
      "job_id": this.props.job_id,
      "type":this.props.type, 
      "agency":this.props.agency,
      "created_at": new Date,
      "updated_at": new Date, 
      "jobdetail":{},
      "data": { 
        "goals_strenght_weeks": "",
        "goals_balance_grades":"",
        "goals_rom_weeks":"",
        "goals_transfer_grades": "",
        "goals_pain_grades": "",
        "livingtype": "",
        "limit_speach": false,
        "limit_respiratory": false,   
        "priorlvloffunction": "Caregiver’s assistance with all activities, ADL’s and ambulation.",
        "gender": "",
        "patientname": this.props.patientname,
        "md": "",
        "mr": "",
        "age": "",
        "vitalbp": "",
        "vitalhr": "",
        "vitalrr": "",
        "vitaltemp": "",
        "txplan": [
          "Transfer",
          "Coordination",
          "ADL Training",
          "Balance",
          "Strength",
          "Pain",
          "ROM",
          "CG Training",
          "Ambulate"
        ],
        "vitalptequipment": [],
        "livingstairs": "", 
        "gait_analysis": "",
        "gait_posture":"", 
        "limit_vision": [],
        "limit_hearing": [],
        "limit_other": "",
        "cogition_alert": "", 
        "pain_location": [], 
        "txplan_other": "",
        "txplan_freq": "", 
        "goals_transfer_weeks": "", 
        "goals_balance_weeks": "",
        "goals_pain_weeks": "",
        "goals_strenght_grades": "",
        "goals_rom_grades": "",
        "homeboundstatus": "Unable to leave home unassisted",
        "pain_note": "MD will be notified if the pain level increases more than above",
        "complition_date":"",
        "discharge_plan":"",
        "date":"",
        "timein": "",
        "timeout":"",
        "ranges": [],
        "frequency": "",
        "otrefreason": "",
        "funcstatus":"",
        "pain_severity":"",
        "livingstatus": "",
        "elevator": false,
        "stairs": false,
        "reqequipment": [],
        "adls_bathorshower": "",
        "adls_washface": "",
        "adls_shave": "",
        "adls_dressing": "",
        "adls_grooming": "",
        "adls_ambulate": "",
        "adls_toileting": "",
        "adls_toilet_transfer": "",
        "adls_motor": "",
        "adls_write_sign": "",
        "adls_stairs": "",
        "adls_commode":"",
        "adls_sitting_balance": "",
        "grasp_left":"",
        "grasp_right": "",
        "release_left": "",
        "release_right": "",
        "cord_left": "",
        "cord_right":"",
        "ue_used": "",
        "goals_adl_weeks": "",
        "goals_adl_grades": "",
        "comments_goals":"",
        "poc_discussed": "",
        "rehab_potential": "",
        "safety_measures":"",
        "muscle_lue": "",
        "muscle_left":"",
        "muscle_rue": "",
        "muscle_right":"",
        "ub_strength": "",
      }
    }
    
  }

  componentDidMount(){

    axios.get(`https://api.pureptinc.com/jobs/`+this.state.job_id )
    .then(res => {
    
       this.setState({jobdetail:res.data[0]})
    })   

    if(this.props.new){
     
    }
    else {
      this.setState({
        data:this.props.data
      })
    }
      
  }
 
  handleDropChange = (event, {name, value}) => {
     
    this.setState(prevState => ({
      data: {                    
        ...prevState.data,    
        [name]: value    
    }
  }))
  }
  handleDropChangeM = (event, {name, value}) => {
     
    this.setState(prevState => ({
      [name]: value    
  }))
  }

  checkboxChangeHandler = (event, {name, checked}) => {
     
    this.setState(prevState => ({
      data: {                    
        ...prevState.data,    
        [name]: checked    
    } 
  }))
    
  };
   

  handleChange = (event, data) => {
   
    this.name = event.target.name; 
    this.setState(prevState => ({
      data: {                    
        ...prevState.data,    
        [event.target.name]: event.target.value   
    }
       
  }))
 }


 handleChangeMotion = (event, data) => {
   
  this.name = event.target.name;
  
  this.setState(prevState => ({
    [event.target.name]: event.target.value    
}))
}

 handleDateChange = (event, {name, value}) => {
   
  
 
  this.setState(prevState => ({
  
    data: {                    
      ...prevState.data,    
      [name]: value   
  }
}))
  
}

handleRadioChange = (event, {name, value}) => {
     
  this.setState(prevState => ({
    data: {                    
      ...prevState.data,    
      [name]: value    
  }
}))
}

addmotion = () => {

  const oldarray = this.state.data.ranges
  oldarray.push({
    joint:this.state.joint,
    movement:this.state.movement,
    range:this.state.range,
    rangerl:this.state.rangerl,
    prom:this.state.prom,
    arom:this.state.arom,
  }) 

  const rangesarray = oldarray

  this.setState(prevState => ({
    data: {                    
      ...prevState.data,    
        ranges: rangesarray    
    }
  }))


}
 
 handleSubmit = () => {
  this.setState({ 
    dimmer: true
  }) 

  axios.post(`https://api.pureptinc.com/forms/`, this.state)
  .then(res => {
    this.setState({loading:false});
    this.props.refreshContent(this.state.job_id)       
    this.props.setCloseForm()       
  })       
   
     
}
 
  render() {
 
    const errorLabel = <Label color="red" pointing="left" />;

    const respiratoryrate = [
        { text: '16', value: '16' },
        { text: '17', value: '17' },
        { text: '18', value: '18' },
        { text: '19', value: '19' },
        { text: '20', value: '20' },
        { text: '21', value: '21' }  
      ];

      const pains = [
        { text: 'C/S', value: 'C/S' },
        { text: 'T/S', value: 'T/S' },
        { text: 'L/S', value: 'L/S' },
        { text: 'Shoulders', value: 'Shoulders' },
        { text: 'Elbows', value: 'Elbows' },
        { text: 'Wrists', value: 'Wrists' },
        { text: 'Hips', value: 'Hips' },
        { text: 'Knees', value: 'Knees' },
        { text: 'Ankles', value: 'Ankles' },
        { text: 'Lower Back', value: 'Lower Back' }   
      ];
  

    const vision = [
      { text: 'Lt', value: 'Lt' },
      { text: 'Rt', value: 'Rt' },
      { text: 'Both', value: 'Both' },
      { text: 'Blind', value: 'Blind' } 
    ];  

    const movement = [
      { text: 'Flexion', value: 'Flexion' },
      { text: 'Hyperextension', value: 'Hyperextension' },
      { text: 'Pronation', value: 'Pronation' },
      { text: 'Supination', value: 'Supination' },
      { text: 'Extension', value: 'Extension' },
      { text: 'Radial Deviation', value: 'Radial Deviation' },
      { text: 'Ulnar Deviation', value: 'Ulnar Deviation' },
      { text: 'Abduction', value: 'Abduction' }    
    ];  

    const joint = [
      
      { text: 'Elbow', value: 'Elbow' },
      { text: 'Forearm', value: 'Forearm' },
      { text: 'Wrist', value: 'Wrist' },
      { text: 'Shoulder', value: 'Shoulder' } 
    ];

    const rom = [
      { text: 'WFL', value: 'WFL' },
      { text: 'WNL', value: 'WNL' }  
    ]; 

    const hearing = [
      { text: 'Lt', value: 'Lt' },
      { text: 'Rt', value: 'Rt' },
      { text: 'Both', value: 'Both' },
      { text: 'Deaf', value: 'Deaf' } 
    ];  

    const temp = [
        { text: '98.1', value: '98.1' },
        { text: '98.2', value: '98.2' },
        { text: '98.3', value: '98.3' },
        { text: '98.4', value: '98.4' },
        { text: '98.5', value: '98.5' },
        { text: '98.6', value: '98.6' }  
      ]; 

      const living = [
        { text: 'Apt', value: 'Apt' },
        { text: 'House', value: 'House' },
        { text: 'B&C', value: 'B&C' },
        { text: 'Mobile', value: 'Mobile' },
        { text: 'Facility', value: 'Facility' },
      ]; 
      
      const livingstatus = [
        { text: 'Alone', value: 'Alone' },
        { text: 'With family/friend', value: 'With family/friend' },
        { text: 'Caregiver', value: 'Caregiver' } 
      ]; 

    const equipments = [
        {name:'ptequipment', text:"FWW", value: "FWW"},
        {name:'ptequipment', text:"4WW", value: "4WW"},
        {name:'ptequipment', text:"PUW", value: "PUW"},
        {name:'ptequipment', text: "Shower chair/tub bench", value: "Shower chair/tub bench"},
        {name:'ptequipment', text: "Raised toilet seat", value: "Raised toilet seat"},
        {name:'ptequipment', text: "SPC", value: "SPC"},
        {name:'ptequipment', text: "Hosp bed", value: "Hosp bed"},
        {name:'ptequipment', text: "Hoyer lift", value: "Hoyer lift"},
        {name:'ptequipment', text: "HW", value: "HW"},
        {name:'ptequipment', text: "Oxygen tube", value: "Oxygen tube"},
        {name:'ptequipment', text: "Commode", value: "Commode"},
        {name:'ptequipment', text: "W/C", value: "W/C"},
        {name:'ptequipment', text: "QC", value: "QC"}, 
        {name:'ptequipment', text: "Crutches", value: "Crutches"} 
    ]

    const grades = [
        { text: 'P/P-', value: 'P/P-' },
        { text: 'P+/P', value: 'P+/P' },
        { text: 'F-/P+', value: 'F-/P+' },
        { text: 'F/P+', value: 'F/P+' },
        { text: 'F+/P+', value: 'F+/P+' },
        { text: 'F+/F-', value: 'F+/F-' },
        { text: 'G-/F-', value: 'G-/F-' },
        { text: 'G-/F', value: 'G-/F' },
        { text: 'G/F', value: 'G/F' },
        { text: 'G+/F+', value: 'G+/F+' },
        { text: 'G+/G-', value: 'G+/G-' } 

    ]

    const strenght = [
      { text: '0/5 MMT', value: '0/5 MMT' },
      { text: '1-/5 MMT', value: '1-/5 MMT' },
      { text: '1/5 MMT', value: '1/5 MMT' },
      { text: '1+/5 MMT', value: '1+/5 MMT' },
      { text: '2-/5 MMT', value: '2-/5 MMT' },
      { text: '2/5 MMT', value: '2/5 MMT' },
      { text: '2+/5 MMT', value: '2+/5 MMT' },
      { text: '3-/5 MMT', value: '3-/5 MMT' },
      { text: '3/5 MMT', value: '3/5 MMT' },
      { text: '3+/5 MMT', value: '3+/5 MMT' }, 
      { text: '4-/5 MMT', value: '4-/5 MMT' },
      { text: '4/5 MMT', value: '4/5 MMT' },
      { text: '4+/5 MMT', value: '4+/5 MMT' } 
      
  ]

  const pain = [
    { text: '0/10', value: '0/10' },
    { text: '1/10', value: '1/10' },
    { text: '2/10', value: '2/10' },
    { text: '3/10', value: '3/10' },
    { text: '4/10', value: '4/10' },
    { text: '5/10', value: '5/10' },
    { text: '6/10', value: '6/10' },
    { text: '7/10', value: '7/10' },
    { text: '8/10', value: '8/10' },
    { text: '9/10', value: '9/10' },
    { text: '10/10', value: '10/10' } 
]

const edema = [
  { text: 'Min', value: 'min' },
  { text: 'Mod', value: 'mod' },
  { text: 'Sever', value: 'sever' }  
]

const problems = [
  { text: 'Bed Mobility', value: 'Bed Mobility' },
  { text: 'Transfers', value: 'Transfers' },
  { text: 'Gait', value: 'Gait' },
  { text: 'Stairs', value: 'Stairs' },
  { text: 'Balance', value: 'Balance' },
  { text: 'Strength', value: 'Strength' },
  { text: 'Safety', value: 'Safety' },
  { text: 'ROM', value: 'ROM' },
  { text: 'HEP', value: 'HEP' },
  { text: 'CG Knowledge', value: 'BCG Knowledge' },
  { text: 'Pain', value: 'Pain' },
  { text: 'ROM', value: 'ROM' }   
]

const defaultproblems = [
  'Bed Mobility', 'Transfers', 'Gait', 'Stairs', 'Balance', 'Strength', 'Safety', 'ROM', 'HEP', 'BCG Knowledge', 'Pain'
]

const txplan = [
 
   
  { text: 'Transfer', value: 'Transfer' },
  { text: 'Coordination', value: 'Coordination' },
  { text: 'ADL Training', value: 'ADL Training' },
  { text: 'Balance', value: 'Balance' },
  { text: 'Strength', value: 'Strength' },
  { text: 'Pain', value: 'Pain' },
  { text: 'ROM', value: 'ROM' },
  { text: 'HEP', value: 'HEP' }, 
  { text: 'CG Training', value: 'CG Training' },
  { text: 'Ambulate', value: 'Ambulate' },
  
]

const defaulttxplan = [
  
  'Transfer',  'Coordination', 'ADL Training', 'Balance', 'Strength', 'Pain', 'ROM', 'CG Training', 'Ambulate'
  
]


const complition_weeks = [
  { text: '1 Week', value: '1 Week' },
  { text: '1 to 3 weeks', value: '1 to 3 weeks' },
  { text: '2 to 4 weeks', value: '2 to 4 weeks' },
  { text: '3 to 5 weeks', value: '3 to 5 weeks' },
  { text: '4 to 6 weeks', value: '4 to 6 weeks' },
  { text: '5 to 7 weeks', value: '5 to 7 weeks' },
  { text: '6 to 8 weeks', value: '6 to 8 weeks' },
  { text: '7 to 9 weeks', value: '7 to 9 weeks' },
  { text: '8 to 10 weeks', value: '8 to 10 weeks' },
  { text: '9 to 11 weeks', value: '9 to 11 weeks' },
  { text: '12 to 14 weeks', value: '12 to 14 weeks' },
  { text: '13 to 15 weeks', value: '13 to 15 weeks' },
  { text: '14 to 16 weeks', value: '14 to 16 weeks' },
  { text: '15 to 17 weeks', value: '15 to 17 weeks' },
  { text: '16 to 18 weeks', value: '16 to 18 weeks' },
  { text: '17 to 19 weeks', value: '17 to 19 weeks' },
  { text: '18 to 20 weeks', value: '18 to 20 weeks' } 
]

const weeks = [
  { text: '1 Week', value: '1 Week' },
  { text: '2 Weeks', value: '2 Weeks' },
  { text: '3 Weeks', value: '3 Weeks' },
  { text: '4 Weeks', value: '4 Weeks' },
  { text: '5 Weeks', value: '5 Weeks' },
  { text: '6 Weeks', value: '6 Weeks' },
  { text: '7 Weeks', value: '7 Weeks' },
  { text: '8 Weeks', value: '8 Weeks' },
  { text: '9 Weeks', value: '9 Weeks' },
  { text: '10 Weeks', value: '10 Weeks' },
  { text: '11 Weeks', value: '11 Weeks' },
  { text: '12 Weeks', value: '12 Weeks' },
  { text: '13 Weeks', value: '13 Weeks' },
  { text: '14 Weeks', value: '14 Weeks' },
  { text: '15 Weeks', value: '15 Weeks' },
  { text: '16 Weeks', value: '16 Weeks' },
  { text: '17 Weeks', value: '17 Weeks' },
  { text: '18 Weeks', value: '18 Weeks' },
  { text: '19 Weeks', value: '19 Weeks' },
  { text: '20 Weeks', value: '20 Weeks' } 
  
] 

const gender = [
  { text: 'Male', value: 'Male' },
  { text: 'Female', value: 'Female' },
  { text: 'Other', value: 'Other' }
]

const goals = [
  { text: 'Unable', value: 'Unable' },
  { text: 'Total-A', value: 'Total-A' },
  { text: 'MAX-A', value: 'sever' },
  { text: 'MAX/MOD-A', value: 'MAX/MOD-A' },
  { text: 'MOD-A', value: 'MOD-A' },
  { text: 'MOD/MIN-A', value: 'MOD/MIN-A' },
  { text: 'MIN-A', value: 'MIN-A' },
  { text: 'MIN-A/CGA', value: 'MIN-A/CGA' },
  { text: 'CGA', value: 'CGA' },
  { text: 'CGA/SBA', value: 'CGA/SBA' },
  { text: 'SBA', value: 'SBA' },
  { text: 'SBA/S', value: 'SBA/S' },
  { text: 'S', value: 'S' },
  { text: 'S/INDP', value: 'S/INDP' },
  { text: 'INDP', value: 'INDP' }
]


    return (
      <div className="container"> 
        <div className="formcontainer">
          <Header textAlign="center">Occupational Therapy Evaluation and Plan of Care</Header>
    
          <Header.Subheader  textAlign="center">Agency: {this.state.agency}</Header.Subheader>
          <Divider></Divider>
          <Form>
          <Form.Group widths="6">

            <Form.Input  fluid   disabled  label="Patient:"  placeholder="John Doe" value={this.state.jobdetail.patientname}  onChange={this.handleChange}  name='patientname'  />
            <Form.Input  fluid     label="MD:"  value={ this.state.data.md}  onChange={this.handleChange}  name='md'  />
            <Form.Input  fluid     label="MR:"  value={ this.state.data.mr}  onChange={this.handleChange}  name='mr'  />
            <Form.Input  fluid     label="Frequency:" value={ this.state.data.frequency}   onChange={this.handleChange}  name='frequency'  />
            <Form.Input  fluid     label="Age:" value={ this.state.data.age}   onChange={this.handleChange}  name='age'  />
            <Form.Dropdown fluid label="Gender:"  value={ this.state.data.gender}  name="gender"  placeholder="Select Gender"   validationErrors={{   isDefaultRequiredValue: 'You need to select a gender',    }} errorLabel={errorLabel} options={gender} selection   clearable    onChange={this.handleDropChange}   />

          </Form.Group>

        <Segment>
        <Grid columns={2} divided>
            <Grid.Row>
                 <Grid.Column>
                     <Form.Input  fluid  value={ this.state.data.otrefreason}     label="Reason for OT Referral:"   onChange={this.handleChange}  name='otrefreason'  />
                     <Form.Input  fluid  value={ this.state.data.funcstatus}    label="Prior Functional Status:"   onChange={this.handleChange}  name='funcstatus'  />
                     <Form.Input value={ this.state.data.homeboundstatus}  fluid     label="Homebound Status:" value="Unable to leave home unassisted"   onChange={this.handleChange}  name='homeboundstatus'  />
                     <Form.Input  fluid  value={ this.state.data.medhistory}    label="Other Pertinent Diagnoses/Medical History: "   onChange={this.handleChange}  name='medhistory'  />
                     
                     <Segment>
                         <Header>
                             Vital Signs 
                         </Header>

                         <Form.Group widths="2">
                             <Form.Input  value={ this.state.data.vitalbp} fluid     label="BP:"  placeholder="Blood Preasure"   onChange={this.handleChange}  name='vitalbp'  />
                             <Form.Input  value={ this.state.data.vitalhr} fluid     label="HR:"  placeholder="Heart Rate"   onChange={this.handleChange}  name='vitalhr'  />
                             </Form.Group>
                             <Form.Group widths="2">
                             <Form.Dropdown
                             fluid
                                   label="RR:" 
                                    name="vitalrr"
                                    placeholder="Select Value" 
                                    validationErrors={{
                                    isDefaultRequiredValue: 'You need to select a value',
                                    }}
                                    errorLabel={errorLabel}
                                    options={respiratoryrate}
                                    selection
                                    clearable
                                    onChange={this.handleDropChange}
                                    value={ this.state.data.vitalrr}
                                />

                                <Form.Dropdown
                                   fluid     
                                   label="Temp:" 
                                    name="vitaltemp"
                                    placeholder="Select Value" 
                                    validationErrors={{
                                    isDefaultRequiredValue: 'You need to select a value',
                                    }}
                                    errorLabel={errorLabel}
                                    options={temp}
                                    selection
                                    clearable
                                    onChange={this.handleDropChange}
                                    value={ this.state.data.vitaltemp}
                                />
                             </Form.Group>

                            
                         
                            
                            
                     </Segment>

                     <Form.Dropdown
                                   fluid     
                                   label="EQUIPMENT PT HAS:" 
                                    name="vitalptequipment"
                                    placeholder="Select Value" 
                                    validationErrors={{
                                    isDefaultRequiredValue: 'You need to select a value',
                                    }}
                                    errorLabel={errorLabel}
                                    options={equipments}
                                    selection
                                    multiple
                                    onChange={this.handleDropChange}
                                    value={ this.state.data.vitalptequipment}
                                />
                                    <Form.Dropdown
                                   fluid     
                                   label="EQUIPMENT RECOMMENDED:" 
                                    name="reqequipment"
                                    placeholder="Select Value" 
                                    validationErrors={{
                                    isDefaultRequiredValue: 'You need to select a value',
                                    }}
                                    errorLabel={errorLabel}
                                    options={equipments}
                                    selection
                                    multiple
                                    onChange={this.handleDropChange}
                                    value={ this.state.data.reqequipment}
                                />
                    
                 </Grid.Column>
                 <Grid.Column>

                    
                    <Segment secondary>
                   

<Header>Pain:</Header> 
                      <Form.Group widths='2'>

                      <Form.Dropdown
                            fluid     
                            label="Location" 
                            name="pain_location"
                            placeholder="Select Value" 
                            validationErrors={{
                            isDefaultRequiredValue: 'You need to select a value',
                            }}
                            errorLabel={errorLabel}
                            options={pains}
                            selection
                            multiple
                            onChange={this.handleDropChange}
                            value={ this.state.data.pain_location}
                        />  
                     <Form.Dropdown
                              fluid     
                              label="Severity (0-10 scale)" 
                              name="pain_severity"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={pain}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.pain_severity}
                               
                         /> 
                      </Form.Group>
                      
                      <Form.Input value={ this.state.data.pain_note} fluid       onChange={this.handleChange}  name='pain_note'  />  
                        <Header>Living Situation</Header>
                                <Form.Dropdown
                                   fluid      
                                    name="livingtype"
                                    label="Type:" 
                                    placeholder="Type" 
                                    validationErrors={{
                                    isDefaultRequiredValue: 'You need to select a type',
                                    }}
                                    errorLabel={errorLabel}
                                    options={living}
                                    selection 
                                    onChange={this.handleDropChange}
                                    value={ this.state.data.livingtype}
                                />
                                <Form.Dropdown
                                   fluid      
                                    name="livingstatus"
                                    label="Living Status:" 
                                    placeholder="Status" 
                                    validationErrors={{
                                    isDefaultRequiredValue: 'You need to select a type',
                                    }}
                                    errorLabel={errorLabel}
                                    options={livingstatus}
                                    selection 
                                    onChange={this.handleDropChange}
                                    value={ this.state.data.livingstatus}
                                />
                                
                                 <Form.Input value={ this.state.data.livingstairs} fluid     label="stairs/steps:"   onChange={this.handleChange}  name='livingstairs'  />
                                 <Form.Checkbox defaultChecked={ this.state.data.elevator} label="Elevator"   onChange={this.checkboxChangeHandler}  name='elevator' ></Form.Checkbox>
                                 <Form.Checkbox defaultChecked={ this.state.data.stairs}  label="No Stairs or Steps"   onChange={this.checkboxChangeHandler}  name='stairs' ></Form.Checkbox>
                    </Segment>
                    
                     
                
                 </Grid.Column>
            </Grid.Row>
        </Grid>
        </Segment>
        <Segment>
            <Grid columns={2} divided>
                <Grid.Row>
                    <Grid.Column>
                        <Header>ADLs:</Header>
                        <Form.Group widths='4'>
                            
                             <Form.Dropdown fluid value={ this.state.data.adls_bathorshower} label="Bath or shower:"  name="adls_bathorshower" placeholder="Select Value"  validationErrors={{ isDefaultRequiredValue: 'You need to select a value', }} errorLabel={errorLabel} options={goals}  selection  clearable onChange={this.handleDropChange}  />
                             <Form.Dropdown fluid value={ this.state.data.adls_washface} label="Wash face/hands:"  name="adls_washface" placeholder="Select Value"  validationErrors={{ isDefaultRequiredValue: 'You need to select a value', }} errorLabel={errorLabel} options={goals}  selection  clearable onChange={this.handleDropChange}  />
                             <Form.Dropdown fluid value={ this.state.data.adls_shave} label="Shave/Shampoo:"  name="adls_shave" placeholder="Select Value"  validationErrors={{ isDefaultRequiredValue: 'You need to select a value', }} errorLabel={errorLabel} options={goals}  selection  clearable onChange={this.handleDropChange}  />
                             <Form.Dropdown fluid value={ this.state.data.adls_dressing} label="Dressing:"  name="adls_dressing" placeholder="Select Value"  validationErrors={{ isDefaultRequiredValue: 'You need to select a value', }} errorLabel={errorLabel} options={goals}  selection  clearable onChange={this.handleDropChange}  />       
                              
                        </Form.Group>

                        <Form.Group widths='4'>
                            
                             <Form.Dropdown fluid value={ this.state.data.adls_grooming} label="Grooming:"  name="adls_grooming" placeholder="Select Value"  validationErrors={{ isDefaultRequiredValue: 'You need to select a value', }} errorLabel={errorLabel} options={goals}  selection  clearable onChange={this.handleDropChange}  />
                             <Form.Dropdown fluid value={ this.state.data.adls_ambulate} label="Ambulate:"  name="adls_ambulate" placeholder="Select Value"  validationErrors={{ isDefaultRequiredValue: 'You need to select a value', }} errorLabel={errorLabel} options={goals}  selection  clearable onChange={this.handleDropChange}  />
                             <Form.Dropdown fluid value={ this.state.data.adls_toileting} label="Toileting:"  name="adls_toileting" placeholder="Select Value"  validationErrors={{ isDefaultRequiredValue: 'You need to select a value', }} errorLabel={errorLabel} options={goals}  selection  clearable onChange={this.handleDropChange}  />
                             <Form.Dropdown fluid value={ this.state.data.adls_toilet_transfer} label="Toilet Transfer:"  name="adls_toilet_transfer" placeholder="Select Value"  validationErrors={{ isDefaultRequiredValue: 'You need to select a value', }} errorLabel={errorLabel} options={goals}  selection  clearable onChange={this.handleDropChange}  />       
                              
                        </Form.Group>

                        <Form.Group widths='4'>
                            
                             <Form.Dropdown fluid value={ this.state.data.adls_motor} label="Fine motor training:"  name="adls_motor" placeholder="Select Value"  validationErrors={{ isDefaultRequiredValue: 'You need to select a value', }} errorLabel={errorLabel} options={goals}  selection  clearable onChange={this.handleDropChange}  />
                             <Form.Dropdown fluid value={ this.state.data.adls_write_sign} label="Write/Sign name:"  name="adls_write_sign" placeholder="Select Value"  validationErrors={{ isDefaultRequiredValue: 'You need to select a value', }} errorLabel={errorLabel} options={goals}  selection  clearable onChange={this.handleDropChange}  />
                             <Form.Dropdown fluid value={ this.state.data.adls_stairs} label="Go up/down stairs:"  name="adls_stairs" placeholder="Select Value"  validationErrors={{ isDefaultRequiredValue: 'You need to select a value', }} errorLabel={errorLabel} options={goals}  selection  clearable onChange={this.handleDropChange}  />
                             <Form.Dropdown fluid value={ this.state.data.adls_commode} label="Get off/on commode:"  name="adls_commode" placeholder="Select Value"  validationErrors={{ isDefaultRequiredValue: 'You need to select a value', }} errorLabel={errorLabel} options={goals}  selection  clearable onChange={this.handleDropChange}  />       
                            
                        </Form.Group>
                        

                        <Form.Group  widths='4'>
                        <Form.Dropdown fluid value={ this.state.data.adls_sitting_balance} label="Sitting Balance:"  name="adls_sitting_balance" placeholder="Select Value"  validationErrors={{ isDefaultRequiredValue: 'You need to select a value', }} errorLabel={errorLabel} options={grades}  selection  clearable onChange={this.handleDropChange}  />   
                        </Form.Group>

                        <Header>Sensory Effects on Therapy:</Header>   
                        <Form.Group widths='2'>
                        <Form.Dropdown
                                   fluid     
                                   label="Vision:" 
                                    name="limit_vision"
                                    placeholder="Select Value" 
                                    validationErrors={{
                                    isDefaultRequiredValue: 'You need to select a value',
                                    }}
                                    errorLabel={errorLabel}
                                    options={vision}
                                    selection
                                    multiple
                                    clearable
                                    onChange={this.handleDropChange}
                                    value={ this.state.data.limit_vision} 
                                /> 
                        <Form.Dropdown
                            fluid     
                            label="Hearing:" 
                            name="limit_hearing"
                            placeholder="Select Value" 
                            validationErrors={{
                            isDefaultRequiredValue: 'You need to select a value',
                            }}
                            errorLabel={errorLabel}
                            options={hearing}
                            selection
                            multiple
                            clearable
                            onChange={this.handleDropChange}
                            value={ this.state.data.limit_hearing} 
                        /> 
                        </Form.Group>
                        <Form.Group widths='3'>
                             <Form.Field>
                                <lable>&nbsp;</lable>
                               <Form.Checkbox defaultChecked={ this.state.data.limit_respiratory}  label="Respiratory" toggle name="limit_respiratory"  onChange={this.checkboxChangeHandler} ></Form.Checkbox>            
                            </Form.Field> 
                            <Form.Field>
                                <lable>&nbsp;</lable>
                               <Form.Checkbox defaultChecked={ this.state.data.limit_speach}  label="Speech" toggle name="limit_speach"  onChange={this.checkboxChangeHandler} ></Form.Checkbox>            
                            </Form.Field> 
                           
                           <Form.Input  value={ this.state.data.limit_other} fluid     label="Other:"   onChange={this.handleChange}  name='limit_other'  />
                        </Form.Group>
                         
                        <Header as="h5" >Coordination (UE functional use)</Header>

                     <Grid celled>
                          <Grid.Row>
                            <Grid.Column width={4}>
                            <Header as="h5" >Grasp</Header>
                            </Grid.Column>
                            <Grid.Column width={6}>
                              <Header as="h5" >Left</Header>
                              <Form.Group widths='3'>
                                    <Form.Field>
                                      <Radio
                                        label='WFL'
                                        name='grasp_left'
                                        value='WFL' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.grasp_left === 'WFL'}
                                      />
                                    </Form.Field>
                                    <Form.Field>
                                      <Radio
                                        label='DIFF'
                                        name='grasp_left'
                                        value='DIFF' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.grasp_left === 'DIFF'}
                                      />
                                    </Form.Field>  
                                    <Form.Field>
                                      <Radio
                                        label='U'
                                        name='grasp_left'
                                        value='U' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.grasp_left === 'U'}
                                      />
                                    </Form.Field>  
                              </Form.Group>
                            </Grid.Column>
                            <Grid.Column width={6}>


                            <Header as="h5" >Right</Header>
                              <Form.Group widths='3'>
                                    <Form.Field>
                                      <Radio
                                        label='WFL'
                                        name='grasp_right'
                                        value='WFL' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.grasp_right === 'WFL'}
                                      />
                                    </Form.Field>
                                    <Form.Field>
                                      <Radio
                                        label='DIFF'
                                        name='grasp_right'
                                        value='DIFF' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.grasp_right === 'DIFF'}
                                      />
                                    </Form.Field>  
                                    <Form.Field>
                                      <Radio
                                        label='U'
                                        name='grasp_right'
                                        value='U' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.grasp_right === 'U'}
                                      />
                                    </Form.Field>  
                              </Form.Group>



                            </Grid.Column>
                          </Grid.Row>



                          <Grid.Row>
                            <Grid.Column width={4}>
                            <Header as="h5" >Release</Header>
                            </Grid.Column>
                            <Grid.Column width={6}>
                              <Header as="h5" >Left</Header>
                              <Form.Group widths='3'>
                                    <Form.Field>
                                      <Radio
                                        label='WFL'
                                        name='release_left'
                                        value='WFL' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.release_left === 'WFL'}
                                      />
                                    </Form.Field>
                                    <Form.Field>
                                      <Radio
                                        label='DIFF'
                                        name='release_left'
                                        value='DIFF' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.release_left === 'DIFF'}
                                      />
                                    </Form.Field>  
                                    <Form.Field>
                                      <Radio
                                        label='U'
                                        name='release_left'
                                        value='U' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.release_left === 'U'}
                                      />
                                    </Form.Field>  
                              </Form.Group>
                            </Grid.Column>
                            <Grid.Column width={6}>


                            <Header as="h5" >Right</Header>
                              <Form.Group widths='3'>
                                    <Form.Field>
                                      <Radio
                                        label='WFL'
                                        name='release_right'
                                        value='WFL' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.release_right === 'WFL'}
                                      />
                                    </Form.Field>
                                    <Form.Field>
                                      <Radio
                                        label='DIFF'
                                        name='release_right'
                                        value='DIFF' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.release_right === 'DIFF'}
                                      />
                                    </Form.Field>  
                                    <Form.Field>
                                      <Radio
                                        label='U'
                                        name='release_right'
                                        value='U' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.release_right === 'U'}
                                      />
                                    </Form.Field>  
                              </Form.Group>



                            </Grid.Column>
                          </Grid.Row>

                          <Grid.Row>
                            <Grid.Column width={4}>
                            <Header as="h5" >Coordination</Header>
                            </Grid.Column>
                            <Grid.Column width={6}>
                              <Header as="h5" >Left</Header>
                              <Form.Group widths='3'>
                                    <Form.Field>
                                      <Radio
                                        label='WFL'
                                        name='cord_left'
                                        value='WFL' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.cord_left === 'WFL'}
                                      />
                                    </Form.Field>
                                    <Form.Field>
                                      <Radio
                                        label='DIFF'
                                        name='cord_left'
                                        value='DIFF' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.cord_left === 'DIFF'}
                                      />
                                    </Form.Field>  
                                    <Form.Field>
                                      <Radio
                                        label='U'
                                        name='cord_left'
                                        value='U' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.cord_left === 'U'}
                                      />
                                    </Form.Field>  
                              </Form.Group>
                            </Grid.Column>
                            <Grid.Column width={6}>


                            <Header as="h5" >Right</Header>
                              <Form.Group widths='3'>
                                    <Form.Field>
                                      <Radio
                                        label='WFL'
                                        name='cord_right'
                                        value='WFL' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.cord_right === 'WFL'}
                                      />
                                    </Form.Field>
                                    <Form.Field>
                                      <Radio
                                        label='DIFF'
                                        name='cord_right'
                                        value='DIFF' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.cord_right === 'DIFF'}
                                      />
                                    </Form.Field>  
                                    <Form.Field>
                                      <Radio
                                        label='U'
                                        name='cord_right'
                                        value='U' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.cord_right === 'U'}
                                      />
                                    </Form.Field>  
                              </Form.Group>



                            </Grid.Column>
                          </Grid.Row>

                          <Header as="h5" >Right/left UE used as</Header>
                              <Form.Group widths='3'>
                                    <Form.Field>
                                      <Radio
                                        label='Non-Functional'
                                        name='ue_used'
                                        value='Non-Functional' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.ue_used === 'Non-Functional'}
                                      />
                                    </Form.Field>
                                    <Form.Field>
                                      <Radio
                                        label='Gross Assist'
                                        name='ue_used'
                                        value='Gross Assist' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.ue_used === 'Gross Assist'}
                                      />
                                    </Form.Field>  
                                    <Form.Field>
                                      <Radio
                                        label='Bilateral Integration'
                                        name='ue_used'
                                        value='Bilateral Integration' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.ue_used === 'Bilateral Integration'}
                                      />
                                    </Form.Field>  
                                    <Form.Field>
                                      <Radio
                                        label='Functional Assist'
                                        name='ue_used'
                                        value='Functional Assist' 
                                        onChange={this.handleRadioChange}
                                        checked={ this.state.data.ue_used === 'Functional Assist'}
                                      />
                                    </Form.Field> 
                              </Form.Group>

                        </Grid>

                    </Grid.Column>
                    <Grid.Column>
                   
                    <Header>Muscle strength against gravity:</Header> 
                    
                    <Form.Group  widths='2'>
                         <Form.Dropdown
                              fluid     
                              label="LUE:" 
                              name="muscle_lue"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={strenght}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.muscle_lue}
                         />
                         <Form.Dropdown
                              fluid     
                              label="Left Hand:" 
                              name="muscle_left"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={strenght}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.muscle_left}
                         /> 
                        
                    </Form.Group>     

                    <Form.Group  widths='2'>
                         <Form.Dropdown
                              fluid     
                              label="RUE:" 
                              name="muscle_rue"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={strenght}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.muscle_rue}
                         />
                         <Form.Dropdown
                              fluid     
                              label="Right Hand:" 
                              name="muscle_right"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={strenght}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.muscle_right}
                         /> 
                        
                    </Form.Group> 

                    
                    <Form.Group  widths='2'>
                         <Form.Dropdown
                              fluid     
                              label="UB Strength with:" 
                              name="ub_strength"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel}
                              options={strenght}
                              selection
                              clearable
                              onChange={this.handleDropChange}
                              value={ this.state.data.ub_strength}
                         />
                       
                        
                    </Form.Group> 
                     
                    <Header>Range of Motion/Mobility:</Header> 

                    <Table unstackable>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Joint</Table.HeaderCell>
                          <Table.HeaderCell>Movement</Table.HeaderCell>
                          <Table.HeaderCell>Range</Table.HeaderCell>
                          <Table.HeaderCell></Table.HeaderCell>
                          <Table.HeaderCell>PROM</Table.HeaderCell>
                          <Table.HeaderCell>AROM</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                           
                        
                      <Table.Body> 

                      {this.state.data.ranges.map((range)=>(

                          <Table.Row >
                            <Table.Cell>{range.joint}</Table.Cell>
                            <Table.Cell>{range.movement}</Table.Cell>
                            <Table.Cell>{range.range}</Table.Cell>
                            <Table.Cell>{range.rangerl}</Table.Cell>
                            <Table.Cell>{range.prom}</Table.Cell>
                            <Table.Cell>{range.arom}</Table.Cell>
                          </Table.Row>
                        
                      ))}           
                         
                      </Table.Body>
                    </Table>

                    <Form.Group widths={'3'}>

                        <Form.Dropdown value={ this.state.joint} onChange={this.handleDropChangeM} clearable fluid name="joint" label="Joint" options={joint} selection  />
                        <Form.Dropdown value={ this.state.movement} onChange={this.handleDropChangeM} clearable fluid name="movement" label="Movement" options={movement} selection  />
                        <Form.Input value={ this.state.range} fluid   label="Range"  onChange={this.handleChangeMotion}  name='range'  />
                         
                    
                    </Form.Group> 

                    <Form.Group widths={'3'}> 
                       
                        <Form.Input value={ this.state.rangerl} fluid   label="&nbsp;"  onChange={this.handleChangeMotion}  name='rangerl'  />
                        <Form.Input value={ this.state.prom} fluid   label="PROM"  onChange={this.handleChangeMotion}  name='prom'  />
                        <Form.Input value={ this.state.arom} fluid   label="AROM"  onChange={this.handleChangeMotion}  name='arom'  />
                    
                    </Form.Group> 

                    <Button onClick={this.addmotion} fluid primary>Add Motion/Mobility</Button>

                    </Grid.Column>
                    
                </Grid.Row>
            </Grid>
        </Segment>
        <Segment secondary>
            <Grid celled>
              <Grid.Row>
                
                 <Grid.Column width={8}>
                 <Header>Treatment Plan:</Header>
                 <Form.Dropdown
                              fluid     
                              label="Plan" 
                              name="txplan"
                              placeholder="Select Value" 
                              validationErrors={{
                              isDefaultRequiredValue: 'You need to select a value',
                              }}
                              errorLabel={errorLabel} 
                              options={txplan}
                              selection
                              multiple 
                              clearable
                              onChange={this.handleDropChange}
                              defaultValue={ this.state.data.txplan}
                       /> 
                         
                        <Form.Input value={ this.state.data.txplan_other} fluid      label="Other"  onChange={this.handleChange}  name='txplan_other'  />
                        <Form.Input value={ this.state.data.txplan_freq} fluid      label="FREQ AND DUR"  onChange={this.handleChange}  name='txplan_freq'  />     

                        <Header>Rehab Potential</Header>
                <Form.Group widths='4'>
                     <Form.Field>
                      <Radio
                        label='Excellent'
                        name='rehab_potential'
                        value='Excellent' 
                        onChange={this.handleRadioChange}
                        checked={ this.state.data.rehab_potential === 'Excellent'}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label='Good'
                        name='rehab_potential'
                        value='Good' 
                        onChange={this.handleRadioChange}
                        checked={ this.state.data.rehab_potential === 'Good'}
                      />
                    </Form.Field>  
                    <Form.Field>
                      <Radio
                        label='Fair'
                        name='rehab_potential'
                        value='Fair' 
                        onChange={this.handleRadioChange}
                        checked={ this.state.data.rehab_potential === 'Fair'}
                      />
                    </Form.Field>  
                    <Form.Field>
                      <Radio
                        label='Poor'
                        name='rehab_potential'
                        value='Poor' 
                        onChange={this.handleRadioChange}
                        checked={ this.state.data.rehab_potential === 'Poor'}
                      />
                    </Form.Field>  
                     </Form.Group>   

                      <Header>Safety Measures</Header>
                <Form.Group widths='2'>
                     <Form.Field>
                      <Radio
                        label='Living area appears Safe'
                        name='safety_measures'
                        value='Living area appears Safe' 
                        onChange={this.handleRadioChange}
                        checked={ this.state.data.safety_measures === 'Living area appears Safe'}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label='At risk to fall'
                        name='safety_measures'
                        value='At risk to fall' 
                        onChange={this.handleRadioChange}
                        checked={ this.state.data.safety_measures === 'At risk to fall'}
                      />
                    </Form.Field>  
                    
                     </Form.Group>                              
                 </Grid.Column>
                 <Grid.Column width={8} > 
                 <Header >Goals:</Header>
                   <Grid  divided >
                        <Grid.Row>
                            <Grid.Column width={6} >ADL Training in</Grid.Column>
                            <Grid.Column width={5} > <Form.Dropdown value={ this.state.data.goals_adl_weeks} onChange={this.handleDropChange} clearable fluid name="goals_adl_weeks" placeholder="Select Week" options={weeks} selection  /> </Grid.Column> 
                            <Grid.Column width={5}> <Form.Dropdown value={ this.state.data.goals_adl_grades} onChange={this.handleDropChange} clearable fluid name="goals_adl_grades" placeholder="Select Grade" options={goals} selection  />  </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={6} >Transfer in </Grid.Column>
                            <Grid.Column width={5} > <Form.Dropdown value={ this.state.data.goals_transfer_weeks} onChange={this.handleDropChange} clearable fluid name="goals_transfer_weeks" placeholder="Select Week" options={weeks} selection  /> </Grid.Column> 
                            <Grid.Column width={5}> <Form.Dropdown value={ this.state.data.goals_transfer_grades} onChange={this.handleDropChange} clearable fluid name="goals_transfer_grades" placeholder="Select Grade" options={goals} selection  />  </Grid.Column>
                        </Grid.Row>      
                        <Grid.Row>
                            <Grid.Column width={6} >Balance in </Grid.Column>
                            <Grid.Column width={5} > <Form.Dropdown value={ this.state.data.goals_balance_weeks} onChange={this.handleDropChange} clearable fluid name="goals_balance_weeks" placeholder="Select Week" options={weeks} selection  /> </Grid.Column> 
                            <Grid.Column width={5}> <Form.Dropdown value={ this.state.data.goals_balance_grades} onChange={this.handleDropChange} clearable fluid name="goals_balance_grades" placeholder="Select Grade" options={grades} selection  />  </Grid.Column>
                        </Grid.Row>    
                        <Grid.Row>
                            <Grid.Column width={6} >Strength in </Grid.Column>
                            <Grid.Column width={5} > <Form.Dropdown value={ this.state.data.goals_strenght_weeks} onChange={this.handleDropChange} clearable fluid name="goals_strenght_weeks" placeholder="Select Week" options={weeks} selection  /> </Grid.Column> 
                            <Grid.Column width={5}> <Form.Dropdown value={ this.state.data.goals_strenght_grades} onChange={this.handleDropChange} clearable fluid name="goals_strenght_grades" placeholder="Select Grade" options={strenght} selection  />  </Grid.Column>
                           
                        </Grid.Row>   
                        <Grid.Row>
                            <Grid.Column width={6} >ROM in </Grid.Column>
                            <Grid.Column width={5} > <Form.Dropdown  value={ this.state.data.goals_rom_weeks} onChange={this.handleDropChange} clearable fluid name="goals_rom_weeks" placeholder="Select Week" options={weeks} selection  /> </Grid.Column> 
                            <Grid.Column width={5}> <Form.Dropdown value={ this.state.data.goals_rom_grades} onChange={this.handleDropChange} clearable fluid name="goals_rom_grades" placeholder="Select Grade" options={rom} selection  />  </Grid.Column>
                             
                        </Grid.Row>   
                        <Grid.Row>
                            <Grid.Column width={6} >Pain in </Grid.Column>
                            <Grid.Column width={5} > <Form.Dropdown value={ this.state.data.goals_pain_weeks} onChange={this.handleDropChange} clearable fluid name="goals_pain_weeks" placeholder="Select Week" options={weeks} selection  /> </Grid.Column> 
                            <Grid.Column width={5}> <Form.Dropdown value={ this.state.data.goals_pain_grades} onChange={this.handleDropChange} clearable fluid name="goals_pain_grades" placeholder="Select Grade" options={pain} selection  />  </Grid.Column>
                        </Grid.Row> 
                        <Grid.Row>
                        <Grid.Column width={16} >  <Form.TextArea value={ this.state.data.comments_goals} fluid      label="Comments towards the Goal"  onChange={this.handleChange}  name='comments_goals'  />   </Grid.Column>
                        <Grid.Column  width={16} ><Form.Dropdown label="Completion Date" value={ this.state.data.complition_date} onChange={this.handleDropChange} clearable fluid name="complition_date" placeholder="Select Week" options={complition_weeks} selection  />       </Grid.Column>                     
                        </Grid.Row>
                   </Grid>
                 </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={'8'}>
                <Header>POC Discussed With</Header>
                <Form.Group widths='4'>
                     <Form.Field>
                      <Radio
                        label='Patient'
                        name='poc_discussed'
                        value='Patient' 
                        onChange={this.handleRadioChange}
                        checked={ this.state.data.poc_discussed === 'Patient'}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label='Family'
                        name='poc_discussed'
                        value='Family' 
                        onChange={this.handleRadioChange}
                        checked={ this.state.data.poc_discussed === 'Family'}
                      />
                    </Form.Field>  
                    <Form.Field>
                      <Radio
                        label='Caregiver'
                        name='poc_discussed'
                        value='Caregiver' 
                        onChange={this.handleRadioChange}
                        checked={ this.state.data.poc_discussed === 'Caregiver'}
                      />
                    </Form.Field>  
                     </Form.Group>
                  
                </Grid.Column>
                <Grid.Column  width={'8'}>
                <Form.TextArea value={ this.state.data.discharge_plan} fluid      label="Discharge Plan"  onChange={this.handleChange}  name='discharge_plan'  /> 
                </Grid.Column>
                
              </Grid.Row>

           
            </Grid>
            <Form.Group widths='3'>
            <DateInput label="Date" closable={true} required name="date"  value={this.state.data.date}  placeholder="Date"   dateFormat={'MM-DD-YYYY'} iconPosition="left"  onChange={this.handleDateChange}  />
            <TimeInput closable={true}
                   required
                   name="timein"
                   label="Time In"
                   value={this.state.data.timein}
                   timeFormat={'ampm'}
                   iconPosition="left"
                   onChange={this.handleDateChange}
                  
                 />
             <TimeInput closable={true} 
                   required
                   name="timeout"
                   label="Time Out"
                   value={this.state.data.timeout}
                   timeFormat={'ampm'}
                   iconPosition="left"
                   onChange={this.handleDateChange}
                 />    
          </Form.Group>
        </Segment>  
        <Button onClick={this.handleSubmit} primary fluid >Submit</Button> 
      </Form>
     
        </div>
      </div>
    );
  }
  
};

export default OccupationalTherapyEval;
