import React from "react";
import {  Grid, Table, Popup, Button, Label, Segment, Header, Divider } from "semantic-ui-react";
import moment from 'moment'
import {
  Form, Dropdown, Checkbox
} from 'formsy-semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import {
  DateInput,
  TimeInput,
  DateTimeInput,
  DatesRangeInput
} from 'semantic-ui-calendar-react';
import axios from 'axios';
import 'semantic-ui-css/semantic.min.css'


class OccupationalTherapyOrder extends React.Component {
  constructor(props) {
    super(props); 
   
   
    this.props=props;
    this.state = {
      "job_id": this.props.job_id,
      "type":this.props.type, 
      "created_at": new Date,
      "updated_at": new Date, 
      agency:this.props.agency,
      jobdetail:{},
      data:{
        "orderdate": "",
        "ordertime": "",
        "date": "",
        "time": "",
        "report": "Case Manager",
        "patientname": "",
        "mr": "",
        "physicianname": "",
        "physicianaddress": "",
        "physicianphone": "",
        "physicianfax": "",
        "note": ""
      }
    }


    
  }

  componentDidMount(){
    axios.get(`https://api.pureptinc.com/jobs/`+this.state.job_id )
    .then(res => {
      console.log(res.data[0])
       this.setState({jobdetail:res.data[0]})
    })   
    if(this.props.new){
   
    }
    else {
     
      this.setState({
        data:this.props.data
      })
    }
     
 
    
  }
 
   

  handleChange = (event, data) => {
    console.log(data)
    this.name = event.target.name;
    
    this.setState(prevState => ({
      data: {                  
          ...prevState.data,     
          [event.target.name]: event.target.value       
      }
  }))
 }

 handleDateChange = (event, {name, value}) => {
   
  
 
  this.setState(prevState => ({
    data: {                    
        ...prevState.data,    
        [name]: value    
    }
}))
  
}
handleSubmit = () => {
  this.setState({ 
    dimmer: true
  }) 

  axios.post(`https://api.pureptinc.com/forms/`, this.state)
  .then(res => {
    this.setState({loading:false});
    this.props.refreshContent(this.state.job_id)       
    this.props.setCloseForm()       
  })       
   
     
}

 
 
  render() {
 
    const errorLabel = <Label color="red" pointing="left" />;

    const respiratoryrate = [
        { text: '16', value: '16' },
        { text: '17', value: '17' },
        { text: '18', value: '18' },
        { text: '19', value: '19' },
        { text: '20', value: '20' },
        { text: '21', value: '21' }  
      ];

    const vision = [
      { text: 'Lt', value: 'Lt' },
      { text: 'Rt', value: 'Rt' },
      { text: 'Both', value: 'Both' },
      { text: 'Blind', value: 'Blind' } 
    ];  

    const hearing = [
      { text: 'Lt', value: 'Lt' },
      { text: 'Rt', value: 'Rt' },
      { text: 'Both', value: 'Both' },
      { text: 'Deaf', value: 'Deaf' } 
    ];  

    const temp = [
        { text: '98.1', value: '98.1' },
        { text: '98.2', value: '98.2' },
        { text: '98.3', value: '98.3' },
        { text: '98.4', value: '98.4' },
        { text: '98.5', value: '98.5' },
        { text: '98.6', value: '98.6' }  
      ]; 

      const living = [
        { text: 'Apt', value: 'Apt' },
        { text: 'House', value: 'House' },
        { text: 'B&C', value: 'B&C' },
        { text: 'Mobile', value: 'Mobile' },
        { text: 'Facility', value: 'Facility' },
      ];    

    const equipments = [
        {id: 1, name:'ptequipment', text:"FWW/4WW/PUW", value: "FWW/4WW/PUW", isChecked: false},
        {id: 2, name:'ptequipment', text: "Shower chair/tub bench", value: "Shower chair/tub bench", isChecked: false},
        {id: 3, name:'ptequipment', text: "Raised toilet seat", value: "Raised toilet seat", isChecked: false},
        {id: 4, name:'ptequipment', text: "SPC", value: "SPC", isChecked: false},
        {id: 5, name:'ptequipment', text: "Hosp bed", value: "Hosp bed", isChecked: false},
        {id: 6, name:'ptequipment', text: "Hoyer lift", value: "Hoyer lift", isChecked: false},
        {id: 7, name:'ptequipment', text: "HW", value: "HW", isChecked: false},
        {id: 8, name:'ptequipment', text: "Oxygen tube", value: "Oxygen tube", isChecked: false},
        {id: 9, name:'ptequipment', text: "Commode", value: "Commode", isChecked: false},
        {id: 10, name:'ptequipment', text: "W/C", value: "W/C", isChecked: false},
        {id: 11, name:'ptequipment', text: "QC", value: "QC", isChecked: false}, 
        {id: 12, name:'ptequipment', text: "Crutches", value: "Crutches", isChecked: false} 
    ]

    const grades = [
        { text: 'P/P-', value: 'P/P-' },
        { text: 'P+/P', value: 'P+/P' },
        { text: 'F-/P+', value: 'F-/P+' },
        { text: 'F/P+', value: 'F/P+' },
        { text: 'F+/P+', value: 'F+/P+' },
        { text: 'F+/F-', value: 'F+/F-' },
        { text: 'G-/F-', value: 'G-/F-' },
        { text: 'G-/F', value: 'G-/F' },
        { text: 'G/F', value: 'G/F' },
        { text: 'G+/F+', value: 'G+/F+' },
        { text: 'G+/G-', value: 'G+/G-' } 

    ]

    const strenght = [
      { text: '0/5 MMT', value: '0/5 MMT' },
      { text: '1-/5 MMT', value: '1-/5 MMT' },
      { text: '1/5 MMT', value: '1/5 MMT' },
      { text: '1+/5 MMT', value: '1+/5 MMT' },
      { text: '2-/5 MMT', value: '2-/5 MMT' },
      { text: '2/5 MMT', value: '2/5 MMT' },
      { text: '2+/5 MMT', value: '2+/5 MMT' },
      { text: '3-/5 MMT', value: '3-/5 MMT' },
      { text: '3/5 MMT', value: '3/5 MMT' },
      { text: '3+/5 MMT', value: '3+/5 MMT' }, 
      { text: '4-/5 MMT', value: '4-/5 MMT' },
      { text: '4/5 MMT', value: '4/5 MMT' },
      { text: '4+/5 MMT', value: '4+/5 MMT' } 
      
  ]

  const pain = [
    { text: '0', value: '0' },
    { text: '1', value: '1' },
    { text: '2', value: '2' },
    { text: '3', value: '3' },
    { text: '4', value: '4' },
    { text: '5', value: '5' },
    { text: '6', value: '6' },
    { text: '7', value: '7' },
    { text: '8', value: '8' },
    { text: '9', value: '9' },
    { text: '10', value: '10' },

    
]

const edema = [
  { text: 'Min', value: 'min' },
  { text: 'Mod', value: 'mod' },
  { text: 'Sever', value: 'sever' }  
]

const problems = [
  { text: 'Ind w/bed mob', value: 'Ind w/bed mob' },
  { text: 'Strength', value: 'Strength' },
  { text: 'Ind w/ transfers', value: 'Ind w/ transfers' },
  { text: 'Ind w/ HEP', value: 'Ind w/ HEP' },
  { text: 'Ind w/ HEP', value: 'Ind w/ HEP' },
  { text: 'Ind w/ GT', value: 'Ind w/ GT' },
  { text: 'Safety', value: 'Safety' },
  { text: 'Ind w/ stairs', value: 'Ind w/ stairs' },
  { text: 'CG knowledge', value: 'CG knowledge' },
  { text: 'Balance', value: 'Balance' } 
]

const relateditems = [
 
  { text: 'Bed mob trng', value: 'Bed mob trng' },
  { text: 'Ther ex', value: 'Ther ex' },
  { text: 'Transfer trng', value: 'Transfer trng' },
  { text: 'Instruct in HEP', value: 'Instruct in HEP' },
  { text: 'Gt trng', value: 'Gt trng' },
  { text: 'Safety trng', value: 'Safety trng' },
  { text: 'Stair trng', value: 'Stair trng' },
  { text: 'CG trng', value: 'CG trng' },
  { text: 'Balance trng', value: 'Balance trng' },  
  { text: 'Ultrasound', value: 'Ultrasound' },  
]

const txplan = [
 
  { text: 'Bed mob trng', value: 'Bed mob trng' },
  { text: 'Ther ex', value: 'Ther ex' },
  { text: 'Transfer trng', value: 'Transfer trng' },
  { text: 'Instruct in HEP', value: 'Instruct in HEP' },
  { text: 'Gt trng', value: 'Gt trng' },
  { text: 'Safety trng', value: 'Safety trng' },
  { text: 'Stair trng', value: 'Stair trng' },
  { text: 'CG trng', value: 'CG trng' },
  { text: 'Balance trng', value: 'Balance trng' },  

]


const weeks = [
  { text: '1 week', value: '1 week' },
  { text: '2 weeks', value: '2 weeks' },
  { text: '3 weeks', value: '3 weeks' },
  { text: '4 weeks', value: '4 weeks' },
  { text: '5 weeks', value: '5 weeks' },
  { text: '6 weeks', value: '6 weeks' },
  { text: '7 weeks', value: '7 weeks' },
  { text: '8 weeks', value: '8 weeks' },
  { text: '9 weeks', value: '9 weeks' }

]

const goals = [
  { text: 'Unable', value: 'Unable' },
  { text: 'Total-A', value: 'Total-A' },
  { text: 'MAX-A', value: 'sever' },
  { text: 'MAX/MOD-A', value: 'MAX/MOD-A' },
  { text: 'MOD-A', value: 'MOD-A' },
  { text: 'MOD/MIN-A', value: 'MOD/MIN-A' },
  { text: 'MIN-A', value: 'MIN-A' },
  { text: 'MIN-A/CGA', value: 'MIN-A/CGA' },
  { text: 'CGA', value: 'CGA' },
  { text: 'CGA/SBA', value: 'CGA/SBA' },
  { text: 'SBA', value: 'SBA' },
  { text: 'SBA/S', value: 'SBA/S' },
  { text: 'S', value: 'S' },
  { text: 'S/INDP', value: 'S/INDP' },
  { text: 'INDP', value: 'INDP' }
]

const patientstatus = [
  { text: 'Alert', value: 'Alert' },
  { text: 'Oriented', value: 'Oriented' },
  { text: 'Forgetful', value: 'Forgetful' },
  { text: 'Confused', value: 'Confused' },
  { text: 'Disoriented', value: 'Disoriented' },
]




    return (
      <div className="container"> 
        <div className="formcontainer">
        <Header textAlign="center">Telephone Order</Header>
        <Header.Subheader>Agency: {this.state.agency}</Header.Subheader>
        <Divider></Divider>
        <Form onValidSubmit={this.handleSubmit}>
        <Form.Group widths="equal">

        <Form.Input disabled  label="Patient:"  placeholder="John Doe" value={this.state.jobdetail.patientname}  onChange={this.handleChange}  name='patientname'  /> 
        <Form.Input  value={ this.state.data.mr}   fluid   label="MR:"   onChange={this.handleChange}  name='mr'  />

        </Form.Group>
        <Header>PHYSICIAN INFORMATION</Header>
        <Form.Group widths="equal">

            <Form.Input value={ this.state.data.physicianname}     fluid     label="Name:"  placeholder="John Doe"  onChange={this.handleChange}  name='physicianname'  /> 
            <Form.Input value={ this.state.data.physicianaddress}    fluid     label="Address:"   onChange={this.handleChange}  name='physicianaddress'  />
            <Form.Input value={ this.state.data.physicianphone}  fluid     label="Phone:"   onChange={this.handleChange}  name='physicianphone'  />
            <Form.Input value={ this.state.data.physicianfax}  fluid     label="Fax:"   onChange={this.handleChange}  name='physicianfax'  />

        </Form.Group>

       
        <Form.TextArea label="COMMUNICATION NOTES" value={ this.state.data.note}   onChange={this.handleChange}  name='note'  ></Form.TextArea>
        <Form.Group widths='3'>
            <DateInput value={ this.state.data.date} closable={true}    label="Date"   name="date"  value={ this.state.data.date}  placeholder="Date"   dateFormat={'MM-DD-YYYY'} iconPosition="left"  onChange={this.handleDateChange}  />
            <TimeInput value={ this.state.data.time} closable={true}
                     
                   name="time"
                   label="Time"
                   placeholder="Time"
                   value={ this.state.data.time}
                   timeFormat={'ampm'}
                   iconPosition="left"
                   onChange={this.handleDateChange}
                 />
             <Form.Input value={ this.state.data.report} fluid     label="Reported to:"   onChange={this.handleChange}  name='report'  />
          </Form.Group>
          <Segment>
          <Form.TextArea value={ this.state.data.order} label="Orders Detail" onChange={this.handleChange}  name='order'  ></Form.TextArea>

<Form.Group widths='2'>
  <DateInput closable={true} value={ this.state.data.orderdate} label="Order Date"   name="orderdate"  value={ this.state.data.orderdate}  placeholder="Date"   dateFormat={'MM-DD-YYYY'} iconPosition="left"  onChange={this.handleDateChange}  />
  <TimeInput closable={true} value={ this.state.data.ordertime}
          
         name="ordertime"
         label="Order Time"
         placeholder="Time"
         value={ this.state.data.ordertime}
         timeFormat={'ampm'}
         iconPosition="left"
         onChange={this.handleDateChange}
       />
   
</Form.Group>


          </Segment>

          <Button content='Submit' primary fluid >Submit</Button>
          
     </Form> 
     
        </div>
      </div>
    );
  }
  
};

export default OccupationalTherapyOrder;
