import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from "react-router-dom";
import { Sidebar, Segment, Menu, Icon, Image, Label, Button} from 'semantic-ui-react';
import Home from "./screens/home/home";
import Login from "./screens/login/login"
import Agencies from "./screens/agencies/agencies"
import Therapists from "./screens/therapists/therapists"
import AgencyDetail from "./screens/agencies/detail"
import TherapistDetail from "./screens/therapists/detail"
import Users from "./screens/users/users"

import Jobs from "./screens/jobs/jobs"
import Docs  from "./screens/docs/docs" 
import JobDetail from "./screens/jobs/detail"
import Schedule from "./screens/schedule/schedule"
import Auth from './validators/auth'; 
import io from 'socket.io-client';
import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import './App.css';

import Invoices from "./screens/invoices/invoices"
import InvoiceDetail from "./screens/invoices/details";

import Payrolls from "./screens/payrolls/payrolls"
import PayrollDetail from "./screens/payrolls/details";

class App extends React.Component {
  constructor(props) {
    super(props) 
   
    this.props = props;
   
    this.state = {
      notification:0
     }; 

    
 
  } 
  
  componentDidMount(){

    document.title = 'Pure PT Inc | Web Portal'
    const authenteicated =  Auth.isAuthenticated();
    const options = {
     
      secure:true, 
      
          }
  
    const socket = io('https://pure-socket.herokuapp.com', options);
    socket.on('send message', (data) => {
      if(authenteicated.role === 'company'){
        this.setState({notification:this.state.notification + 1})
        toast({
          type: 'success',
          icon: 'briefcase',
          title: 'Notification',
          description: data.agency_name + ' Created a job for ' + data.patientname,
          animation: 'fade',
          time: 5000,
           
      });
      }
    });
     
  }

  resetNotification = () => {
    this.setState({notification:0})
  }

 
 
  render() {
   
    
    const options = { secure:true} 
    const authenteicated =  Auth.isAuthenticated();
    const socket = io('https://pure-socket.herokuapp.com', options); 

    return (
     
      <Router>
  <SemanticToastContainer position="top-right" />
  <Sidebar.Pushable style={{ height: '100vh', transform:'none'}}> 
      
  <Sidebar
        as={Menu} 
        icon='labeled' 
        vertical
        visible={authenteicated.status}
        width='thin'
        compact
        animation='overlay'
      
      >
  
  <Image src='https://pureptinc.com/wp-content/uploads/2020/09/logo.png'  size='tiny' centered />
  
        <Menu.Item as={Link} to="/"   >
          <Icon name='th' />
          Home
        </Menu.Item> 
        
        <Menu.Item as={Link} to="/patients"   >
          <Icon name='clipboard' />
          {authenteicated.role === 'company' ? 
          <Label color='red' style={{marginTop:'-25px', marginLeft:'45px'}}> {this.state.notification}  </Label> : null }
          Patients
         
        </Menu.Item>
        {authenteicated.role === 'company'
          ? <div> <Menu.Item as={Link} to="/agencies"   >
          <Icon name='briefcase' />
          Agenecies
        </Menu.Item>
  
        <Menu.Item as={Link} to="/therapists"   >
          <Icon name='user md' />
          Therapists
        </Menu.Item>
  
        <Menu.Item as={Link} to="/payrolls"   >
          <Icon name='dollar sign' />
          Payroll
        </Menu.Item>

        <Menu.Item as={Link} to="/invoices"   >
          <Icon name='clipboard list' />
          Invoices
        </Menu.Item>

        <Menu.Item as={Link} to="/docs"   >
          <Icon name='file alternate outline' />
          Docs
        </Menu.Item>
  
        <Menu.Item as={Link} to="/users"   >
          <Icon name='users' />
          Users
        </Menu.Item></div>
          : <div></div>
        }

    {authenteicated.role === 'therapist'
          ?    <div>  
          <Menu.Item as={Link} to="/payrolls"   >
            <Icon name='dollar sign' />
            Payroll
          </Menu.Item>

          <Menu.Item as={Link} to="/docs"   >
          <Icon name='file alternate outline' />
          Docs
        </Menu.Item>
  
          </div> : null
        }
       
  
        <Menu.Item onClick={() => { 
          Auth.delSession() 
          window.location.href = '/';
          }}   >
          <Icon name='log out' />
          Log Out
        </Menu.Item>

        <Label size="mini" color='blue' horizontal>
           Version 1.1
        </Label>
        
      </Sidebar> 
          <Sidebar.Pusher >
            <Segment basic> 
              <Switch>
              <Route exact path="/" render={() => (
                !authenteicated.status ? (
                  <Redirect to="/login"/>
                ) : (
                  <Home />
                )
              )}/>
  
               <Route exact path="/agencies" render={() => (
                !authenteicated.status ? (
                  <Redirect to="/login"/>
                ) : (
                  <Agencies />
                )
              )}/>
  
              <Route exact path="/therapists" render={(props) => (
                !authenteicated.status ? (
                  <Redirect to="/login"/>
                ) : (
                  <Therapists  {...props} />
                )
              )}/> 
  
              <Route exact path="/invoices" render={() => (
                 !authenteicated.status ? (
                  <Redirect to="/login"/>
                ) : (
                  <Invoices />
                )
              )}/> 

              <Route exact path="/payrolls" render={() => (
                 !authenteicated.status ? (
                  <Redirect to="/login"/>
                ) : (
                  <Payrolls />
                )
              )}/> 

                <Route exact path="/docs" render={() => (
                 !authenteicated.status ? (
                  <Redirect to="/docs"/>
                ) : (
                  <Docs />
                )
              )}/> 

<             Route exact path="/schedule" render={(props) => (
                 !authenteicated.status ? (
                  <Redirect to="/login"/>
                ) : (
                  <Schedule   {...props} />
                )
              )}/> 
   
  
              <Route exact path="/patients" render={(props) => (
                 !authenteicated.status ? (
                  <Redirect to="/login"/>
                ) : (
                  <Jobs reset={this.resetNotification} socket={socket}  {...props} />
                )
              )}/> 
   
             
              <Route exact path="/users" render={() => (
                  !authenteicated.status ? (
                  <Redirect to="/login"/>
                ) : (
                  <Users />
                )
              )}/>  
  
              <Route  path="/therapist/" render={(props) => (
                 !authenteicated.status ? (
                  <Redirect to="/login"/>
                ) : (
                  <TherapistDetail  {...props}  />
                )
              )}/> 
  
              <Route exact path="/job" render={(props) => (
                 !authenteicated.status ? (
                  <Redirect to="/login"/>
                ) : (
                  <JobDetail  {...props} />
                )
              )}/> 

              <Route exact path="/invoice" render={(props) => (
                 !authenteicated.status ? (
                  <Redirect to="/login"/>
                ) : (
                  <InvoiceDetail  {...props} />
                )
              )}/> 

              <Route exact path="/payroll" render={(props) => (
                 !authenteicated.status ? (
                  <Redirect to="/login"/>
                ) : (
                  <PayrollDetail  {...props} />
                )
              )}/> 
  
    
               <Route  path="/agency/" render={(props) => (
                 !authenteicated.status  ? (
                  <Redirect to="/login"/>
                ) : (
                  <AgencyDetail  {...props}  />
                )
              )}/>      
          <Route path="/login" >
            <Login />
          </Route>
          
        </Switch>
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable> 
  
    </Router>
    ) 
  }
  
}
 
export default App;
