import React from 'react'; 
import { Message,  Table, Container, Modal, Header, Segment, Button, Popup, Label, Icon } from 'semantic-ui-react';
import axios from 'axios';
import Moment from 'react-moment';  
import Auth from '../../validators/auth';
import {
  BrowserRouter as Router, 
  Link
} from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas'
import './home.css';
class Home extends React.Component {
  constructor(props) {
    super(props) 
    this.state = {
      open:false,
      jobs:[],
      payrolls:[],
      invoices:[],
      trimmedDataURL: null
   };
  } 

 
  async refreshContent(data) {
      const authdata = Auth.isAuthenticated()
      const userid = authdata.userid; 
      if(authdata.role === 'therapist'){
        const therapist = await axios.get(`https://api.pureptinc.com/therapists/`+userid)
        const signature = therapist.data[0].signature;
        if(signature === 'false'){
          this.setState({open:true})
        }
        
      }

      else {
        const query = authdata.role === 'agency' ? {'agency_id':userid} : (authdata.role === 'therapist' ? {'therapists':userid} : {'company_id':userid} ) ;
        const postdata =   {query:{'company_id': userid,   archive:false  }, size:5, number:1}
    
        
      
  
          axios.get(`https://api.pureptinc.com/jobs`, { params: postdata } )
          .then(res => {
              console.log(res.data)
              if(res.data.length < 1){
                this.setState({
                  jobs:[], 
                })
              }
              else {
                this.setState({
                  jobs:res.data[0].patients,
                  totalpages:res.data[0].totalpages,
                  dimmer:false
                })
              }
            
          
          })
      }
     


    const params =  authdata.role === 'therapist' ? { therapistid:authdata.userid  } : {};


    axios.get(`https://api.pureptinc.com/payrolls`, { params: params })
  .then(res => { 
    console.log(res)
      this.setState({
        payrolls:res.data.schedules, 
        })
  
  }) 


  const invoicequery = authdata.role === 'agency' ? {'agency._id':userid} : {'agency.company_id':userid}  ;
  const invoicedata = {query:invoicequery, size:5};

  axios.get(`https://api.pureptinc.com/invoices`, { params: invoicedata } )
  .then(res => { 
   
      this.setState({
        invoices:res.data.invoices, 
        })
  
  }) 
  }
  
  

  sigPad = {}

  clear = () => {
    this.sigPad.clear()
  }

  trim = () => {
    this.setState({ trimmedDataURL: this.sigPad.getTrimmedCanvas()
      .toDataURL('image/png') })

      axios.post(`https://api.pureptinc.com/therapists/signature`, {
       base64:this.sigPad.getTrimmedCanvas().toDataURL('image/png')
    })
    .then(res => {
      console.log(res)
      const authdata = Auth.isAuthenticated()
      const userid = authdata.userid; 
      axios.put(`https://api.pureptinc.com/therapists/`+userid, {
       signature:true,
       signatureurl:res.data
      })
      .then(res => {
        this.setState({open:false});
      })
    })
  }

  componentDidMount(){
    this.refreshContent()
  }
 
 render() {
  const authenteicated =  Auth.isAuthenticated();
  const authdata = Auth.isAuthenticated()
    const userid = authdata.userid;

   return (
      <div>   
         <Modal
      onClose={() => this.setState({open:false})} 
      open={this.state.open}
      size={'small'}
    >
       <Header icon>
        <Icon name='edit' />
        Signature
      </Header>
      <Modal.Content  >
      <SignatureCanvas penColor='black'
    canvasProps={{width: 680, height: 300, className: 'sigCanvas'}} ref={(ref) => { this.sigPad = ref }} />
      </Modal.Content>
      <Modal.Actions>
        <Button   color='red' inverted onClick={() => this.clear()}>
          <Icon name='remove' /> Reset
        </Button>
        <Button color='green' inverted onClick={() => this.trim()}>
          <Icon name='checkmark' /> Save
        </Button>
      </Modal.Actions>
    </Modal>
         <Container>
              <Header as={'H1'}>Latest Patients</Header>
              <Segment>
              <Table  basic='very' >


              <Table.Header>
             <Table.Row>
               <Table.HeaderCell onClick={() => {  this.sortContent('created_at')}}>Created</Table.HeaderCell> 
               <Table.HeaderCell onClick={() => {  this.sortContent('patientname')}}>Patient Name</Table.HeaderCell>
               <Table.HeaderCell onClick={() => {  this.sortContent('therapist_name')}}>Therapist</Table.HeaderCell> 
               <Table.HeaderCell onClick={() => {  this.sortContent('agency_name')}}>Agency</Table.HeaderCell>
               <Table.HeaderCell onClick={() => {  this.sortContent('assgined')}}>Assigned</Table.HeaderCell>
          
             </Table.Row>
           </Table.Header>

           <Table.Body>

           {this.state.jobs.map((job, index) => (
                <Table.Row key={index}>
                <Table.Cell> <Moment date={job.created_at} format="LLL"  /> </Table.Cell>  
                <Table.Cell>{job.patientname} {job.specialty === 'pt' ? <Label size={'mini'} color='green' horizontal>PT</Label> : (job.specialty === 'ot' ? <Label size={'mini'} color='olive' horizontal>OT</Label> : <Label size={'mini'} color='purple' horizontal>ST</Label>  )} </Table.Cell> 
                <Table.Cell>{!job.therapists ? (<Label color='teal' horizontal>Not Assigned</Label>) : job.therapists.map((therapist, index) => (<Label color='teal' horizontal>{therapist.fname} {therapist.lname}</Label>)) }  </Table.Cell>
                
                <Table.Cell>{job.agency_name}  </Table.Cell> 
                <Table.Cell><Icon color={job.assigned ? 'green' : 'red'} name='check circle' />  </Table.Cell> 
              
                
                 
              </Table.Row> 
           ))}
           </Table.Body>


              </Table>



              </Segment>

           
                 
              {authenteicated.role === 'company' || authenteicated.role === 'therapist' ? (
                   <Segment>
                <div>
                   <Header as={'H1'}>Latest Payrolls</Header>
                 <Table singleLine>
                 <Table.Header>
                   <Table.Row>
                     <Table.HeaderCell>Name</Table.HeaderCell>
                     <Table.HeaderCell>Date</Table.HeaderCell>
                     <Table.HeaderCell>Count</Table.HeaderCell>
                     <Table.HeaderCell>Status</Table.HeaderCell>
                     <Table.HeaderCell>Total</Table.HeaderCell>
                     <Table.HeaderCell></Table.HeaderCell>
                      
                   </Table.Row>
                 </Table.Header>
             
                 <Table.Body>
             
                     {this.state.payrolls.map((payroll)=>(
                       <Table.Row>
                       <Table.Cell>{payroll.therapistname}</Table.Cell>
                       <Table.Cell>From:<Label color={'green'}><Moment date={payroll.from} format="L"  /> </Label>- To: <Label color={'red'}><Moment date={payroll.to} format="L"  /> </Label> </Table.Cell>
                       <Table.Cell>{payroll.count}</Table.Cell>
                       <Table.Cell><Label color={'violet'}>{payroll.status}</Label></Table.Cell>
                       <Table.Cell>${payroll.total}</Table.Cell>
                       <Table.Cell><Button as={Link} to={{pathname: "/payroll/", state: { "schedules": payroll.schedules, "from":payroll.from, "to":payroll.to, "id":payroll._id } }}color={'blue'} fluid>Detail</Button></Table.Cell>
                      
                      </Table.Row>
                     ))}            
                   
                 </Table.Body>
               </Table>
                </div>
                </Segment>

              ) : null }
             
          
                 
                 {authenteicated.role === 'company' || authenteicated.role === 'agency'   ? (
                       <Segment>
                    <div>
                      <Header as={'H1'}>Latest Invoices</Header>
                      <Table singleLine>
                      <Table.Header>
                        <Table.Row>
                         <Table.HeaderCell>#</Table.HeaderCell>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.HeaderCell>Date</Table.HeaderCell>
                          <Table.HeaderCell>Count</Table.HeaderCell>
                          <Table.HeaderCell>Status</Table.HeaderCell>
                          <Table.HeaderCell>Total</Table.HeaderCell>
                          <Table.HeaderCell></Table.HeaderCell>
                          
                        </Table.Row>
                      </Table.Header>
                  
                      <Table.Body>
                  
                          {this.state.invoices.map((invoice)=>(
                            <Table.Row>
                            <Table.Cell>{invoice.invoicenum}</Table.Cell>
                            <Table.Cell>{invoice.agency.name}</Table.Cell>
                            <Table.Cell>From:<Label color={'green'}><Moment date={invoice.from} format="L"  /> </Label>- To: <Label color={'red'}><Moment date={invoice.to} format="L"  /> </Label> </Table.Cell>
                            <Table.Cell>{invoice.count}</Table.Cell>
                            <Table.Cell><Label color={'violet'}>{invoice.status}</Label></Table.Cell>
                            <Table.Cell>${invoice.total}</Table.Cell>
                            <Table.Cell><Button as={Link} to={{pathname: "/invoice/", state: { "schedules": invoice.patients, "from":invoice.from, "to":invoice.to, "id":invoice._id } }}color={'blue'} fluid>Detail</Button></Table.Cell>
                            
                           </Table.Row>
                          ))}            
                        
                      </Table.Body>
                    </Table>
                    </div>
                    </Segment>
                 ) : null }
               

              <Header as={'h1'}>Notifications</Header>

              <Message negative>
                <Message.Header>We're sorry there is no notifications</Message.Header>
                <p>Will send you if there is any</p>
            </Message>
          </Container>


      </div>
   );
 }
}
 
export default Home;
