import React from 'react'; 
import { Table, Container, Button, Header, Icon, Modal, Dimmer, Loader, Segment, Label, Divider, Popup, Pagination, Input, Checkbox, Tab, Confirm} from 'semantic-ui-react'
import axios from 'axios';
import Moment from 'react-moment';  
import moment from 'moment'
import {
  Form, Dropdown, TextArea} from 'formsy-semantic-ui-react';
import { InputFile } from 'semantic-ui-react-input-file'
import Auth from '../../validators/auth';
import {
  DateInput,
  TimeInput,
  DateTimeInput,
  DatesRangeInput
} from 'semantic-ui-calendar-react';
import './jobs.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link
  } from "react-router-dom";

  import './jobs.css';

class Jobs extends React.Component {

    
    
  constructor(props) { 
    super(props) 
    this.props = props;
    this.state = {
        jobs:[],
        therapists:[],
        agencies:[],
        selectedtherapists:[],
        selectedassistants:null, 
        selectedaids:null,
        open:false,
        dimmer:true,
        queryid:null,
        refferal:null,
        addrefferal:null,
        selectedpatient:null,
        patientname:'',
        openupdate:false,
        selectedpage:1,
        selectedpagearchive:1,
        totalpages:1,
        totalpagesarchive:1,
        sort:'created_at',
        start:null,
        end:null,
        edit:false,
        archive:false,
        confirm:false,
        search:'',
        editjob:[],
        browse: (this.props.match.params ? this.props.match.params.id : null)
   };

   this.setOpenUpdate = this.setOpenUpdate.bind(this);
   this.handleChangeTherapist = this.handleChangeTherapist.bind(this);
  }

  fetchTherapistsAgencies(id){

    axios.get(`https://api.pureptinc.com/therapists` )
    .then(res => { 
         
        this.setState({
            therapists:res.data.therapists,
            dimmer:false
          })
    
    }) 

    axios.get(`https://api.pureptinc.com/agencies` )
    .then(res => { 
         
        this.setState({
          agencies:res.data.agencies,
            dimmer:false
          })
    
    }) 

  }

  searchOnChange = () => {
 
      this.refreshContent()

  }

  refreshContentArchive() {

    
   
    const authdata = Auth.isAuthenticated()
    const userid = authdata.userid;
    const search = (this.state.search ? true : false)
     
    const active =  (this.state.activearchive ? this.state.activearchive : 1)
 

    if(authdata.role === 'therapist'){
    
      const postdata = {query:{'therapists':userid, archive:true, "patientname": { "$regex": this.state.search, "$options": "i" }}, size:10, number:active}

      axios.get(`https://api.pureptinc.com/jobs`, { params: postdata } )
      .then(res => { 

        
          this.setState({
            archivedjobs:res.data.length > 0 ? res.data[0].patients : [],
            totalpagesarchive:res.data.length > 0 ? res.data[0].totalpages : 1,
              dimmer:false
            })
      
      })

    }

    else if(authdata.role === 'agency') {
      
       
      const postdata =   {query:{'company_id': '5f777a3e2b5082092ac62dc0', archive:true, 'agency_id': userid, "patientname": { "$regex": this.state.search, "$options": "i" }}, size:10, number:active}
      axios.get(`https://api.pureptinc.com/jobs`, { params: postdata } )
      .then(res => { 
        console.log(res)
          this.setState({
            archivedjobs:res.data.length > 0 ? res.data[0].patients : [],
            totalpagesarchive: res.data.length > 0 ? res.data[0].totalpages : [],
              dimmer:false
            })
      
      }) 

      axios.get(`https://api.pureptinc.com/agencies/`+ userid )
      .then(res => { 

        this.setState({
          agency_detail:res.data[0] 
          })
          
      
      }) 
    }

    else {
   
   
      if(typeof this.props.location.state === 'undefined'){
         

        const postdata =   {query:{'company_id': userid, archive:true, "patientname": { "$regex": this.state.search, "$options": "i" }  }, size:10, number:active}
        axios.get(`https://api.pureptinc.com/jobs`, { params: postdata } )
      .then(res => {
          
          if(res.data.length < 1){
            this.setState({
              archivedjobs:[], 
            })
          }
          else {
            this.setState({
              archivedjobs:res.data.length > 0 ? res.data[0].patients : [],
              totalpagesarchive:res.data.length > 0 ? res.data[0].totalpages : 1,
              dimmer:false
            })
          }
        
      
      }) 
      }
      else{
        
      const postdata =   {query:{'company_id': userid, archive:true, 'agency_id':this.props.location.state.id, "patientname": { "$regex": this.state.search, "$options": "i" }}, size:10, number:active}
        axios.get(`https://api.pureptinc.com/jobs`, { params: postdata } )
      .then(res => {
        if(res.data.length < 1){
          this.setState({
            archivedjobs:[], 
          })
        }
        else {
          this.setState({
            archivedjobs:res.data[0].patients,
            totalpagesarchive:res.data[0].totalpages,
            dimmer:false
          })
        }
      
      }) 
      }    }
        
  }



  refreshContent() {

    
   
    const authdata = Auth.isAuthenticated()
    const userid = authdata.userid;
    const search = (this.state.search ? true : false)
     
    const active =  (this.state.active ? this.state.active : 1)
 

    if(authdata.role === 'therapist'){
    
      const postdata = {query:{'therapists':userid, archive:false, "patientname": { "$regex": this.state.search, "$options": "i" }}, size:10, number:active}

      axios.get(`https://api.pureptinc.com/jobs`, { params: postdata } )
      .then(res => { 
        
          this.setState({
            jobs:res.data.length > 0 ? res.data[0].patients : [],
            totalpages:res.data.length > 0 ? res.data[0].totalpages : 0,
              dimmer:false
            })
      
      })

    }

    else if(authdata.role === 'agency') {
      
       
      const postdata =   {query:{'company_id': '5f777a3e2b5082092ac62dc0',  archive:false, 'agency_id': userid, "patientname": { "$regex": this.state.search, "$options": "i" }}, size:10, number:active}
      axios.get(`https://api.pureptinc.com/jobs`, { params: postdata } )
      .then(res => { 
        console.log(res)
          this.setState({
            jobs:res.data.length > 0 ? res.data[0].patients : [],
            totalpages:res.data.length > 0 ? res.data[0].totalpages : 1,
              dimmer:false
            })
      
      }) 

      axios.get(`https://api.pureptinc.com/agencies/`+ userid )
      .then(res => { 

        this.setState({
          agency_detail:res.data[0] 
          })
          
      
      }) 
    }

    else {
   
   
      if(typeof this.props.location.state === 'undefined'){
         

        const postdata =   {query:{'company_id': userid,   archive:false, "patientname": { "$regex": this.state.search, "$options": "i" }  }, size:10, number:active}
        axios.get(`https://api.pureptinc.com/jobs`, { params: postdata } )
      .then(res => {
          console.log(res.data)
          if(res.data.length < 1){
            this.setState({
              jobs:[], 
            })
          }
          else {
            this.setState({
              jobs:res.data.length > 0 ? res.data[0].patients : [],
              totalpages:res.data.length > 0 ? res.data[0].totalpages : 1,
              dimmer:false
            })
          }
        
      
      }) 
      }
      else{
        
      const postdata =   {query:{'company_id': userid,  archive:false, 'agency_id':this.props.location.state.id, "patientname": { "$regex": this.state.search, "$options": "i" }}, size:10, number:active}
        axios.get(`https://api.pureptinc.com/jobs`, { params: postdata } )
      .then(res => {
        if(res.data.length < 1){
          this.setState({
            jobs:[], 
          })
        }
        else {
          this.setState({
            jobs:res.data.length > 0 ? res.data[0].patients : [],
            totalpages:res.data.length > 0 ? res.data[0].totalpages : 1,
            dimmer:false
          })
        }
      
      }) 
      }    }
        
  }

  toggle = (data, {name, value}) => { 
    
   
    this.setState((prevState) => ({ archive: !this.state.archive }))
  }

  componentDidMount(){

    this.fetchTherapistsAgencies(); 
    this.props.reset();
    
    if(this.props.location.state) {
      this.refreshContentArchive({states: this.props.location.state});
      this.refreshContent({states: this.props.location.state});
    }
    else {

      this.props.socket.on('send message', (data) => {
        this.refreshContent({states: null, pages:this.state.pages});
      });
      this.props.socket.on('send message', (data) => {
        this.refreshContentArchive({states: null, pages:this.state.pages});
      });
      this.refreshContent();
      this.refreshContentArchive();
      
    }

    
        
  }

  setOpen(value){
    this.setState({
        open:value,
        dimmer: (value ? true : false)
      })
  }

  setOpenUpdate(value){
    this.setState({
        openupdate:value.open,
        selectedjob:value.id,
        dimmer: (value.open ? true : false)
      })
  }
  handleChange = event => {
    console.log(event.target.name)
    this.name = event.target.name;
    this.setState({ [event.target.name]: event.target.value });
 }

 handleChangeAgency= (e, { value }) => { 
  
  this.setState({
    agency_id:value,
    agency_name: e.target.innerText
  })
   
 }

 dynamicSort = (property) => {
   console.log(property)
  var sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a,b) {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
  }
}

 handleChangeTherapist = (e, { name, value }) => {  
   const therapists = this.state.therapists;
   const seletedtherapist = []
  value.forEach(function(obj){  
    
    const therapist = therapists.find(function(i) { if(i._id === obj) return i; });
    seletedtherapist.push(therapist._id)
    
  });

  console.log(seletedtherapist)
 
  this.setState({[name]:seletedtherapist})
  
 }

 handleDateChange = (event, {name, value}) => {
   
  
 
  this.setState(prevState => ({
    [name]: value    
}))
  
}

 handleChangeAssistant = (e, { name, value }) => {  
  const therapists = this.state.therapists;
  const therapist = therapists.find(function(i) { if(i._id === value) return i._id; });
   

 this.setState({[name]:therapist})
 
}

 handleDelete = id => {
  axios.delete(`https://api.pureptinc.com/jobs/`+id).then(res => {
    
  this.setState({confirm:false, selectedpatient:null})  
  this.refreshContent();
  this.refreshContentArchive();

});

}

handlePaginationChange = (e, { activePage }) => { 
  
  this.setState({active:activePage}, ()=>{this.refreshContent()})
  
}

handlePaginationChangeArchice = (e, { activePage }) => { 
  
  this.setState({activearchive:activePage}, ()=>{this.refreshContentarchive()})
  
}

sortContent = (data) => {
    
  const jobs = this.state.jobs.sort(this.dynamicSort(String(data)));
  this.setState({jobs:jobs})
}





handleUpdate = () => {
  axios.put(`https://api.pureptinc.com/jobs/`+this.state.selectedjob, {

    therapists:this.state.selectedtherapists,
    assistants: this.state.selectedassistants,
    aids:this.state.selectedaids,
    assigned:'true',
    updated_at: Date.now()

  }).then(res => {
    
    this.refreshContent()
    this.refreshContentArchive()
    this.setState({
        openupdate:false,
        selectedtherapists:null,
        selectedassistants:null,
        selectedaids:null
    })
  });

}

handleUpload = (data) => {
  console.log(data)

  let formData = new FormData();
  formData.append('file', data.target.files[0]); 

  axios.post(`https://api.pureptinc.com/jobs/upload`, formData, {  headers: {
    'Content-Type': 'multipart/form-data'
}}).then(res => {
  
  this.setState({
    refferal:res.data,
    filename: data.target.files[0].name
  })

});
}

handleUploadAdd = (data) => {
  console.log(data)

  let formData = new FormData();
  formData.append('file', data.target.files[0]); 

  axios.post(`https://api.pureptinc.com/jobs/upload`, formData, {  headers: {
    'Content-Type': 'multipart/form-data'
}}).then(res => {
  
  this.setState({
    addrefferal:res.data,
    addfilename: data.target.files[0].name
  })

});
}

handleJobUpdate = () => {

  this.setState({ 
    dimmer: true
  })

  const startOfMonth = moment().clone().startOf('month').format('L');
  const endOfMonth   = moment().clone().endOf('month').format('L');
            
  axios.put(`https://api.pureptinc.com/jobs/`+this.state.selectedjob, {
         therapists:this.state.selectedtherapists,
        assistants: this.state.selectedassistants,
        aids:this.state.selectedaids,
        agency_id:this.state.agency_id,  
        agency_name: this.state.agency_name,
        refferal:this.state.refferal,
        addrefferal:this.state.addrefferal,
        patientname:this.state.patientname, 
        note:this.state.note,  
        specialty:this.state.specialty,
        archive:this.state.archive
    })
    .then(res => {
      this.refreshContent(this.props.location.state)
      this.refreshContentArchive(this.props.location.state)
      this.setState({
        archive:false,
        edit:false,
        dimmer: false
      })
    })
}

 handleSubmit = () => {

  this.setState({ 
    dimmer: true
  })
  const selecteddate = this.state.jobstart;
  const startOfMonth = moment(selecteddate, 'MM-DD-YYYY');
  const endOfMonth   = moment(selecteddate, 'MM-DD-YYYY').add(60, 'days').calendar();
  const daymark = moment(selecteddate, 'MM-DD-YYYY').add(30, 'days').calendar();
            
    axios.post(`https://api.pureptinc.com/jobs/`, {
        therapists:this.state.selectedtherapists,
        assistants: this.state.selectedassistants,
        aids:this.state.selectedaids,
        agency_id:this.state.agency_id,  
        agency_name: this.state.agency_name,
        refferal:this.state.refferal,
        addrefferal:this.state.addrefferal,
        patientname:this.state.patientname,
        company_id:"5f777a3e2b5082092ac62dc0",
        assigned:'true',
        eval:'false',
        note:this.state.note,
        visits:this.state.visits,
        created_at: Date.now(),
        updated_at: Date.now(),
        specialty:this.state.specialty, 
        start:startOfMonth,
        end:endOfMonth,
        daymark:daymark
    })
    .then(res => {
      this.refreshContent(this.props.location.state)
      this.refreshContentArchive(this.props.location.state)
      this.setState({
        open:false,
        dimmer: false
      })
    })
}
 


handleSubmitAgnecy = () => {

 
      this.setState({ 
        dimmer: true
      })

      const startOfMonth = moment().clone().startOf('month').format('L');
      const endOfMonth   = moment().clone().endOf('month').format('L');
            
    axios.post(`https://api.pureptinc.com/jobs/`, {
         
        refferal:this.state.refferal,
        addrefferal:this.state.addrefferal,
        agency_id:this.state.agency_detail._id,  
        agency_name: this.state.agency_detail.name,
        patientname:this.state.patientname,
        company_id:"5f777a3e2b5082092ac62dc0",
        assigned:'false',
        eval:'false',
        visits:this.state.visits,
        created_at: Date.now(),
        updated_at: Date.now(),
        start:moment(startOfMonth, 'MM-DD-YYYY').format(),
        end:moment(endOfMonth, 'MM-DD-YYYY').format(),
        note:this.state.note,
        specialty:this.state.specialty
    })
    .then(res => {
      this.refreshContent(this.props.location.state) 
      this.refreshContentArchive(this.props.location.state)
      this.props.socket.emit('send message', {agency_name: this.state.agency_detail.name,
        patientname:this.state.patientname})
      this.setState({
        open:false,
        dimmer: false
      })
    })
}

handleChangeSpecialty= (e, { value }) => { 
  this.setState({
    specialty:value  
  })
 }

handleInputChange = e => {
  if(e.target.value.length == 0){
   this.refreshContent()
   this.refreshContentArchive()
   this.setState({ search:'' });
  }
  this.setState({ search: e.target.value });
};
  
 render() {

   const agencyOptions = this.state.agencies.map((p, index) => {
    return {...p, key: index, text: p.name, value:p._id}
  })
   const renderLabelAgency = (label) => ({
    color: 'blue',
    content: label.name,
    icon: 'check',
  })

  const renderLabel = (label) => ({
    color: 'blue',
    content: label.name,
    icon: 'check',
  })

  const panes = [
    {
      menuItem: 'Active',
      render: () => <Tab.Pane  > <Input
      action={{
        content:"Search",
        icon: "search",
        onClick: () => this.searchOnChange()
      }}
      fluid
      defaultValue={this.state.value}
      onChange={this.handleInputChange}
    />
     
    <Table  basic='very' >
     
     <Table.Header>
       <Table.Row>
         <Table.HeaderCell onClick={() => {  this.sortContent('created_at')}}>Created</Table.HeaderCell> 
         <Table.HeaderCell onClick={() => {  this.sortContent('patientname')}}>Patient Name</Table.HeaderCell>
         <Table.HeaderCell onClick={() => {  this.sortContent('therapist_name')}}>Therapist</Table.HeaderCell> 
         <Table.HeaderCell onClick={() => {  this.sortContent('agency_name')}}>Agency</Table.HeaderCell>
         <Table.HeaderCell onClick={() => {  this.sortContent('assgined')}}>Assigned</Table.HeaderCell>
         <Table.HeaderCell onClick={() => {  this.sortContent('assgined')}}>Evaluation</Table.HeaderCell>
         <Table.HeaderCell></Table.HeaderCell>
         <Table.HeaderCell></Table.HeaderCell>
         {authenteicated.role === 'company' ? ( <Table.HeaderCell></Table.HeaderCell> ) : ('') }
       </Table.Row>
     </Table.Header>

     <Table.Body>
     
     {this.state.jobs && this.state.jobs.map((job, index) => (
          <Table.Row key={index}>
          <Table.Cell> <Moment date={job.created_at} format="LLL"  /> </Table.Cell>  
          <Table.Cell>{job.patientname} {job.specialty === 'pt' ? <Label size={'mini'} color='green' horizontal>PT</Label> : (job.specialty === 'ot' ? <Label size={'mini'} color='olive' horizontal>OT</Label> : <Label size={'mini'} color='purple' horizontal>ST</Label>  )} </Table.Cell> 
          <Table.Cell>{job.therapists.length === 0 ? (<Label color='teal'  horizontal>Not Assigned</Label>) : job.therapists.map((therapist, index) => (<Label color='teal' horizontal>{therapist.fname} {therapist.lname}</Label>)) }  </Table.Cell>
     
           
          <Table.Cell>{job.agency_name}  </Table.Cell> 
          <Table.Cell><Icon color={job.assigned ? 'green' : 'red'} name='check circle' />  </Table.Cell>  
          <Table.Cell><Icon color={job.eval ? 'green' : 'red'} name='check circle' />  </Table.Cell>  
          { authenteicated.role === 'company' ? <Table.Cell><Button icon onClick={()=>{
            console.log(job.assistants)
            this.setState({
            edit:true, 
            selectedjob:job._id,
            selectedtherapists: job.therapists.map((therapist)=>{return therapist._id}),                
            selectedassistants: (job.assistants ? job.assistants._id : null),
            selectedaids: (job.aids ? job.aids._id : null),
            agency_id:job.agency_id,  
            agency_name: job.agency_name,
            refferal:job.refferal,
            addrefferal:job.addrefferal,
            patientname:job.patientname, 
            specialty:job.specialty,
            note:job.note,  
            archive:job.archive
            })}}  color={'grey'} fluid><Icon name='edit' />Edit</Button></Table.Cell> : null }
          
          {job.therapists.length === 0   ? ( authenteicated.role === 'company' ? (<Table.Cell><Button color={'black'}   icon='file pdf'  href={job.refferal} content="Docs"  fluid /></Table.Cell>) : (<Table.Cell><Button  icon labelPosition='right' as={Link} to={{pathname: "/job/", state: { "id": job._id } }}color={'blue'}   fluid><Icon name='inbox' /> Detail</Button></Table.Cell>)) : (<Table.Cell><Button as={Link} to={{pathname: "/job/", state: { "id": job._id } }}color={'blue'} icon fluid><Icon name='inbox' />Detail</Button></Table.Cell>) }
          {job.therapists.length === 0   ? ( authenteicated.role === 'company' ? (<Table.Cell><Button color={'black'} onClick={() => { this.setOpenUpdate({open:true, id:job._id}) }}   fluid>Assign Therapist</Button></Table.Cell>) : (<Table.Cell><Button basic color={'grey'}  disabled fluid>Therapist Not Assigned</Button></Table.Cell>)) : null }
          {authenteicated.role === 'company' ? (<Table.Cell> <Button color='red' fluid  icon='trash' content='Delete' onClick={()=>{this.setState({confirm:true, selectedpatient:job._id})}} /></Table.Cell> ) : (<Table.Cell></Table.Cell>) }
        </Table.Row> 
     ))}
 
     </Table.Body>
     
      {authenteicated.role === 'company' || authenteicated.role === 'agency' ? (
        <Table.Footer fullWidth>
       <Table.Row>
         
         <Table.HeaderCell colSpan='8'>

         <Modal
           onClose={() => this.setState({
             edit:false, 
             selectedjob:null,
             selectedtherapists: null,             
             selectedassistants: null,
             selectedaids:null,
             agency_id:null,
             agency_name: null,
             refferal:null,
             addrefferal:null,
             patientname:null,
             note:null,
             archive:false
            })}
            
           open={this.state.edit}
           
         >
           <Modal.Header>Edit Patient</Modal.Header>
           <Modal.Content  >
           <Form onValidSubmit={this.handleJobUpdate}>
                       
           <Form.Group unstackable widths={3}>

           <Form.Field>
                            <label>Patient Name </label>
                            <Form.Input required value={this.state.patientname}  disabled={authenteicated.role === 'agency'} placeholder='John Doe' name='patientname'  onChange={this.handleChange}  /> 
                            
                          </Form.Field>

                           

                          <Form.Field>
                            <label>Select Agency </label>
                            <Dropdown
                              name='agency_id'
                              selection
                              fluid
                              clearable
                              options={agencyOptions}
                              onChange={this.handleChangeAgency}
                              defaultValue={this.state.agency_id}
                              placeholder='Choose an option'
                              renderLabel={renderLabelAgency}
                              required
                              validationErrors={{
                                isDefaultRequiredValue: 'You need to select a agency',
                              }}
                              errorLabel={errorLabel}
                              disabled={authenteicated.role === 'agency'}
                            />
                          </Form.Field>

                          <Form.Field>
                        <label>Select Specialty </label>
                        <Dropdown
                          name='specialty'
                          selection
                          fluid
                          options={specialties}
                          defaultValue={this.state.specialty}
                          onChange={this.handleChangeSpecialty}
                          placeholder='Choose an specialty'
                          renderLabel={renderLabel}
                          required
                          validationErrors={{
                            isDefaultRequiredValue: 'You need to select a specialty',
                          }}
                          errorLabel={errorLabel}
                        />
                      </Form.Field>


           </Form.Group>
     
                 <Form.Group unstackable widths={3}> 
                       
                       <Form.Field>
                            <label>Select Therapists </label>
                            <Dropdown
                              name='selectedtherapists'
                              selection
                              multiple
                              fluid
                              clearable
                              defaultValue={this.state.selectedtherapists}
                              options={therapistOptions}
                              onChange={this.handleChangeTherapist}
                              placeholder='Choose an option'
                              renderLabel={renderLabelTherapist}
                              required
                              disabled={authenteicated.role === 'agency'}
                              validationErrors={{
                                isDefaultRequiredValue: 'You need to select a therapist',
                              }}
                              errorLabel={errorLabel}
                            />
                          </Form.Field>


                          <Form.Field>
                            <label>Select Assistant </label>
                            <Dropdown
                              name='selectedassistants'
                              selection
                              clearable
                              fluid
                              options={assistantOptions}
                              onChange={this.handleChangeAssistant}
                              placeholder='Choose an option'
                              renderLabel={renderLabelTherapist}
                              defaultValue={this.state.selectedassistants}
                              disabled={authenteicated.role === 'agency'}
                              validationErrors={{
                                isDefaultRequiredValue: 'You need to select a assistant',
                              }}
                              errorLabel={errorLabel}
                            />
                          </Form.Field>

                          <Form.Field>
                            <label>Select Aid </label>
                            <Dropdown
                              name='selectedaids'
                              selection
                              clearable
                              fluid
                              options={aidsOptions}
                              onChange={this.handleChangeAssistant}
                              placeholder='Choose an option'
                              renderLabel={renderLabelTherapist}
                              defaultValue={this.state.selectedaids}
                              disabled={authenteicated.role === 'agency'}
                              validationErrors={{
                                isDefaultRequiredValue: 'You need to select a aid',
                              }}
                              errorLabel={errorLabel}
                            />
                          </Form.Field>

                          
                         
                          
                                  
                       </Form.Group> 

                       
                       <Form.Group unstackable widths={3}>
                       <Form.Field>
                          <label>Refferal Document </label>
                          
                   

                          <InputFile
                             
                            input={{
                              id: 'input-control-id',
                              onChange: this.handleUpload
                            }}
                            />

                            {this.state.refferal ? (  < Label size='tiny'  basic color='red' pointing >{this.state.refferal} </Label>) : null }

                           
                          </Form.Field>

                          <Form.Field>
                          <label>Add Refferal Document </label>
                          <InputFile
                             
                            input={{
                              id: 'input-control-id',
                              onChange: this.handleUploadAdd
                            }}
                            />

                            {this.state.addrefferal ? (  < Label size='tiny'  basic color='red' pointing >{this.state.addrefferal} </Label>) : null }

                           
                          </Form.Field>

                          <Form.Field>
                          <label>Want to archive this patients ?</label>

                          <Checkbox defaultChecked={this.state.archive} onChange={this.toggle}  name={'archive'}toggle />

                          </Form.Field>

                           
                       </Form.Group>
                     
                       <Form.Field>
                            <label>Note</label>
                            <Form.TextArea name='note' defaultValue={this.state.note} fluid onChange={this.handleChange}  ></Form.TextArea>
                            </Form.Field>
                       <Button
               content="Submit"
               labelPosition='right'
               icon='checkmark'
               color={'blue'}
               
                fluid 
             />
                     </Form>
              
           </Modal.Content>
            
         </Modal>
          
           
         </Table.HeaderCell>
         
       </Table.Row>
     </Table.Footer>
        ) : (
          <Table.Footer> </Table.Footer>
        )
          }
          
   </Table>
   <Pagination onPageChange={this.handlePaginationChange} defaultActivePage={this.state.selectedpage} totalPages={this.state.totalpages} /></Tab.Pane>,
    },
    { menuItem: 'Archived', render: () => <Tab.Pane> <Input
    action={{
      content:"Search",
      icon: "search",
      onClick: () => this.searchOnChange()
    }}
    fluid
    defaultValue={this.state.value}
    onChange={this.handleInputChange}
  />
   
  <Table  basic='very' >
   
   <Table.Header>
     <Table.Row>
       <Table.HeaderCell onClick={() => {  this.sortContent('created_at')}}>Created</Table.HeaderCell> 
       <Table.HeaderCell onClick={() => {  this.sortContent('patientname')}}>Patient Name</Table.HeaderCell>
       <Table.HeaderCell onClick={() => {  this.sortContent('therapist_name')}}>Therapist</Table.HeaderCell> 
       <Table.HeaderCell onClick={() => {  this.sortContent('agency_name')}}>Agency</Table.HeaderCell>
       <Table.HeaderCell onClick={() => {  this.sortContent('assgined')}}>Assigned</Table.HeaderCell>
       <Table.HeaderCell onClick={() => {  this.sortContent('assgined')}}>Evaluation</Table.HeaderCell>
       <Table.HeaderCell></Table.HeaderCell>
       <Table.HeaderCell></Table.HeaderCell>
       {authenteicated.role === 'company' ? ( <Table.HeaderCell></Table.HeaderCell> ) : ('') }
     </Table.Row>
   </Table.Header>

   <Table.Body>
   
   {this.state.archivedjobs && this.state.archivedjobs.map((job, index) => (
        <Table.Row key={index}>
        <Table.Cell> <Moment date={job.created_at} format="LLL"  /> </Table.Cell>  
        <Table.Cell>{job.patientname} {job.specialty === 'pt' ? <Label size={'mini'} color='green' horizontal>PT</Label> : (job.specialty === 'ot' ? <Label size={'mini'} color='olive' horizontal>OT</Label> : <Label size={'mini'} color='purple' horizontal>ST</Label>  )} </Table.Cell> 
        <Table.Cell>{job.therapists.length === 0 ? (<Label color='teal'  horizontal>Not Assigned</Label>) : job.therapists.map((therapist, index) => (<Label color='teal' horizontal>{therapist.fname} {therapist.lname}</Label>)) }  </Table.Cell>
   
         
        <Table.Cell>{job.agency_name}  </Table.Cell> 
        <Table.Cell><Icon color={job.assigned ? 'green' : 'red'} name='check circle' />  </Table.Cell>  
        <Table.Cell><Icon color={job.eval ? 'green' : 'red'} name='check circle' />  </Table.Cell>  
        { authenteicated.role === 'company' ? <Table.Cell><Button icon onClick={()=>{
          console.log(job.assistants)
          this.setState({
          edit:true, 
          selectedjob:job._id,
          selectedtherapists: job.therapists.map((therapist)=>{return therapist._id}),                
          selectedassistants: (job.assistants ? job.assistants._id : null),
          selectedaids: (job.aids ? job.aids._id : null),
          agency_id:job.agency_id,  
          agency_name: job.agency_name,
          refferal:job.refferal,
          addrefferal:job.addrefferal,
          patientname:job.patientname, 
          specialty:job.specialty,
          note:job.note,  
          archive:job.archive
          })}}  color={'grey'} fluid><Icon name='edit' />Edit</Button></Table.Cell> : null }
        
        {job.therapists.length === 0   ? ( authenteicated.role === 'company' ? (<Table.Cell><Button color={'black'}   icon='file pdf'  href={job.refferal} content="Docs"  fluid /></Table.Cell>) : (<Table.Cell><Button  icon labelPosition='right' as={Link} to={{pathname: "/job/", state: { "id": job._id } }}color={'blue'}   fluid><Icon name='inbox' /> Detail</Button></Table.Cell>)) : (<Table.Cell><Button as={Link} to={{pathname: "/job/", state: { "id": job._id } }}color={'blue'} icon fluid><Icon name='inbox' />Detail</Button></Table.Cell>) }
        {job.therapists.length === 0   ? ( authenteicated.role === 'company' ? (<Table.Cell><Button color={'black'} onClick={() => { this.setOpenUpdate({open:true, id:job._id}) }}   fluid>Assign Therapist</Button></Table.Cell>) : (<Table.Cell><Button basic color={'grey'}  disabled fluid>Therapist Not Assigned</Button></Table.Cell>)) : null }
        {authenteicated.role === 'company' ? (<Table.Cell>  <Button color='red' fluid  icon='trash' content='Delete' onClick={()=>{this.setState({confirm:true, selectedpatient:job._id})}} /> </Table.Cell> ) : (<Table.Cell></Table.Cell>) }
      </Table.Row> 
   ))}

   </Table.Body>
   
    {authenteicated.role === 'company' || authenteicated.role === 'agency' ? (
      <Table.Footer fullWidth>
     <Table.Row>
       
       <Table.HeaderCell colSpan='8'>

       <Modal
         onClose={() => this.setState({
           edit:false, 
           selectedjob:null,
           selectedtherapists: null,             
           selectedassistants: null,
           selectedaids:null,
           agency_id:null,
           agency_name: null,
           refferal:null,
           addrefferal:null,
           patientname:null,
           note:null,
           archive:false
          })}
          
         open={this.state.edit}
         
       >
         <Modal.Header>Edit Patient</Modal.Header>
         <Modal.Content  >
         <Form onValidSubmit={this.handleJobUpdate}>
                     
         <Form.Group unstackable widths={3}>

         <Form.Field>
                          <label>Patient Name </label>
                          <Form.Input required value={this.state.patientname}  disabled={authenteicated.role === 'agency'} placeholder='John Doe' name='patientname'  onChange={this.handleChange}  /> 
                          
                        </Form.Field>

                         

                        <Form.Field>
                          <label>Select Agency </label>
                          <Dropdown
                            name='agency_id'
                            selection
                            fluid
                            clearable
                            options={agencyOptions}
                            onChange={this.handleChangeAgency}
                            defaultValue={this.state.agency_id}
                            placeholder='Choose an option'
                            renderLabel={renderLabelAgency}
                            required
                            validationErrors={{
                              isDefaultRequiredValue: 'You need to select a agency',
                            }}
                            errorLabel={errorLabel}
                            disabled={authenteicated.role === 'agency'}
                          />
                        </Form.Field>

                        <Form.Field>
                      <label>Select Specialty </label>
                      <Dropdown
                        name='specialty'
                        selection
                        fluid
                        options={specialties}
                        defaultValue={this.state.specialty}
                        onChange={this.handleChangeSpecialty}
                        placeholder='Choose an specialty'
                        renderLabel={renderLabel}
                        required
                        validationErrors={{
                          isDefaultRequiredValue: 'You need to select a specialty',
                        }}
                        errorLabel={errorLabel}
                      />
                    </Form.Field>


         </Form.Group>
   
               <Form.Group unstackable widths={3}> 
                     
                     <Form.Field>
                          <label>Select Therapists </label>
                          <Dropdown
                            name='selectedtherapists'
                            selection
                            multiple
                            fluid
                            clearable
                            defaultValue={this.state.selectedtherapists}
                            options={therapistOptions}
                            onChange={this.handleChangeTherapist}
                            placeholder='Choose an option'
                            renderLabel={renderLabelTherapist}
                            required
                            disabled={authenteicated.role === 'agency'}
                            validationErrors={{
                              isDefaultRequiredValue: 'You need to select a therapist',
                            }}
                            errorLabel={errorLabel}
                          />
                        </Form.Field>


                        <Form.Field>
                          <label>Select Assistant </label>
                          <Dropdown
                            name='selectedassistants'
                            selection
                            clearable
                            fluid
                            options={assistantOptions}
                            onChange={this.handleChangeAssistant}
                            placeholder='Choose an option'
                            renderLabel={renderLabelTherapist}
                            defaultValue={this.state.selectedassistants}
                            disabled={authenteicated.role === 'agency'}
                            validationErrors={{
                              isDefaultRequiredValue: 'You need to select a assistant',
                            }}
                            errorLabel={errorLabel}
                          />
                        </Form.Field>

                        <Form.Field>
                          <label>Select Aid </label>
                          <Dropdown
                            name='selectedaids'
                            selection
                            clearable
                            fluid
                            options={aidsOptions}
                            onChange={this.handleChangeAssistant}
                            placeholder='Choose an option'
                            renderLabel={renderLabelTherapist}
                            defaultValue={this.state.selectedaids}
                            disabled={authenteicated.role === 'agency'}
                            validationErrors={{
                              isDefaultRequiredValue: 'You need to select a aid',
                            }}
                            errorLabel={errorLabel}
                          />
                        </Form.Field>

                        
                       
                        
                                
                     </Form.Group> 

                     
                     <Form.Group unstackable widths={3}>
                     <Form.Field>
                        <label>Refferal Document </label>
                        
                 

                        <InputFile
                           
                          input={{
                            id: 'input-control-id',
                            onChange: this.handleUpload
                          }}
                          />

                          {this.state.refferal ? (  < Label size='tiny'  basic color='red' pointing >{this.state.refferal} </Label>) : null }

                         
                        </Form.Field>

                        <Form.Field>
                        <label>Add Refferal Document </label>
                        <InputFile
                           
                          input={{
                            id: 'input-control-id',
                            onChange: this.handleUploadAdd
                          }}
                          />

                          {this.state.addrefferal ? (  < Label size='tiny'  basic color='red' pointing >{this.state.addrefferal} </Label>) : null }

                         
                        </Form.Field>

                        <Form.Field>
                        <label>Want to archive this patients ?</label>

                        <Checkbox defaultChecked={this.state.archive} onChange={this.toggle}  name={'archive'} toggle />

                        </Form.Field>

                         
                     </Form.Group>
                   
                     <Form.Field>
                          <label>Note</label>
                          <Form.TextArea name='note' defaultValue={this.state.note} fluid onChange={this.handleChange}  ></Form.TextArea>
                          </Form.Field>
                     <Button
             content="Submit"
             labelPosition='right'
             icon='checkmark'
             color={'blue'}
             
              fluid 
           />
                   </Form>
            
         </Modal.Content>
          
       </Modal>
        
         
       </Table.HeaderCell>
       
     </Table.Row>
   </Table.Footer>
      ) : (
        <Table.Footer> </Table.Footer>
      )
        }
        
 </Table>
 <Pagination onPageChange={this.handlePaginationChange} defaultActivePage={this.state.selectedpagearchive} totalPages={this.state.totalpagesarchive} /></Tab.Pane> },
    
  ]

  

  const renderLabelTherapist = (label) => ({
    
    color: 'blue',
    content: ' ' + label.fname + ' ' + label.lname,
    
    
  })

  const specialties = [
    {key: 0, text: 'Occupational', value:'ot'},
    {key: 1, text: 'Physical', value:'pt'},
    {key: 2, text: 'Speech', value:'st'},
    
  ]

  const therapistOptions = this.state.therapists.filter(therapist => therapist.role === 'therapist').map((p, index) => {
    return {...p, key: index, text: p.fname + ' ' + p.lname, value:p._id, content: ( <div><Label color={p.specialty === 'ot' ? 'violet' : 'blue'} horizontal>
    {p.specialty}
  </Label> { p.fname + ' ' + p.lname }</div>)}
  })

  const assistantOptions = this.state.therapists.filter(therapist => therapist.role === 'assistant').map((p, index) => {
    return {...p, key: index, text: p.fname + ' ' + p.lname, value:p._id, content: ( <div><Label color={p.specialty === 'ot' ? 'violet' : 'blue'} horizontal>
    {p.specialty}
  </Label> { p.fname + ' ' + p.lname }</div>)}
  })


  const aidsOptions = this.state.therapists.filter(therapist => therapist.role === 'aid').map((p, index) => {
    return {...p, key: index, text: p.fname + ' ' + p.lname, value:p._id, content: ( <div><Label color={p.specialty === 'ot' ? 'violet' : 'blue'} horizontal>
    {p.specialty}
  </Label> { p.fname + ' ' + p.lname }</div>)}
  })
  
 
  const authenteicated =  Auth.isAuthenticated();
  const selectedtherapist = this.state.edit ? this.state.selectedtherapists.map((therapist)=>{return therapist._id}) : null
  const selectedassistants = this.state.edit ? (this.state.selectedassistants ? this.state.selectedassistants._id : null) : null
  const selectedaids= this.state.edit ? (this.state.selectedaids ? this.state.selectedaids._id : null) : null
  const errorLabel = <Label color="red" pointing/>

   return (

    
      <div>   

        <Confirm
          size={'tiny'}
          open={this.state.confirm}
          confirmButton={'Yes'}
          content={'Are you sure you want to delete this patient?'}
          onCancel={()=>{this.setState({confirm:false})}}
          onConfirm={()=>{ 
              this.setState({confirm:false})
              this.handleDelete(this.state.selectedpatient)

           }}
        />
         
         <Container>



         {authenteicated.role === 'company' ? (
                 <Modal
                 onClose={() => this.setOpen(false)}
                 onOpen={() => this.setOpen(true)}
                 open={this.state.open}
                 trigger={  <Button
                   floated='right'
                   icon
                   labelPosition='left'
                    
                   color={'teal'}
                   size='medium'
                 >
                   <Icon name='briefcase' /> Add New Patient
                 </Button>}
               >
                 <Modal.Header>Add New Patient</Modal.Header>
                 <Modal.Content  >
                 <Form onValidSubmit={this.handleSubmit}>
                             
                 <Form.Group unstackable widths={3}>

                 <Form.Field>
                                  <label>Patient Name </label>
                                  <Form.Input required  placeholder='John Doe' name='patientname'  onChange={this.handleChange}  /> 
                                  
                                </Form.Field>

                                 

                                <Form.Field>
                                  <label>Select Agency </label>
                                  <Dropdown
                                    name='agency_id'
                                    selection
                                    fluid
                                    clearable
                                    options={agencyOptions}
                                    onChange={this.handleChangeAgency}
                                    placeholder='Choose an option'
                                    renderLabel={renderLabelAgency}
                                    required
                                    validationErrors={{
                                      isDefaultRequiredValue: 'You need to select a agency',
                                    }}
                                    errorLabel={errorLabel}
                                  />
                                </Form.Field>

                                <Form.Field>
                              <label>Select Specialty </label>
                              <Dropdown
                                name='specialty'
                                selection
                                fluid
                                options={specialties}
                                onChange={this.handleChangeSpecialty}
                                placeholder='Choose an specialty'
                                renderLabel={renderLabel}
                                required
                                validationErrors={{
                                  isDefaultRequiredValue: 'You need to select a specialty',
                                }}
                                errorLabel={errorLabel}
                              />
                            </Form.Field>


                 </Form.Group>
           
                             <Form.Group unstackable widths={3}>
                               
                             
    
                              
    
                                <Form.Field>
                                  <label>Select Therapists </label>
                                  <Dropdown
                                    name='selectedtherapists'
                                    selection
                                    multiple
                                    fluid
                                    clearable
                                    options={therapistOptions}
                                    onChange={this.handleChangeTherapist}
                                    placeholder='Choose an option'
                                    renderLabel={renderLabelTherapist}
                                    required
                                    validationErrors={{
                                      isDefaultRequiredValue: 'You need to select a therapist',
                                    }}
                                    errorLabel={errorLabel}
                                  />
                                </Form.Field>

                                <Form.Field>
                                  <label>Select Assistant </label>
                                  <Dropdown
                                    name='selectedassistants'
                                    selection
                                    clearable
                                    fluid
                                    options={assistantOptions}
                                    onChange={this.handleChangeAssistant}
                                    placeholder='Choose an option'
                                    renderLabel={renderLabelTherapist}
                                     
                                    validationErrors={{
                                      isDefaultRequiredValue: 'You need to select a assistant',
                                    }}
                                    errorLabel={errorLabel}
                                  />
                                </Form.Field>

                                <Form.Field>
                                  <label>Select Aid </label>
                                  <Dropdown
                                    name='selectedaids'
                                    selection
                                    clearable
                                    fluid
                                    options={aidsOptions}
                                    onChange={this.handleChangeAssistant}
                                    placeholder='Choose an option'
                                    renderLabel={renderLabelTherapist}
                                     
                                    validationErrors={{
                                      isDefaultRequiredValue: 'You need to select a aid',
                                    }}
                                    errorLabel={errorLabel}
                                  />
                                </Form.Field>
    
                                
                               
                                
                                        
                             </Form.Group> 

                             
                             <Form.Group unstackable widths={3}>
                                
                                <Form.Field>
                                <label>Start Date </label>
                                <DateInput closable={true}
                              required
                              name="jobstart"
                              placeholder="Date"
                              value={this.state.jobstart}
                              dateFormat={'MM-DD-YYYY'}
                              iconPosition="left"
                              onChange={this.handleDateChange}
                            />
                                </Form.Field>

                             <Form.Field>
                                <label>Refferal Document </label>
                                
                         

                                <InputFile
                                   
                                  input={{
                                    id: 'input-control-id',
                                    onChange: this.handleUpload
                                  }}
                                  />

                                  {this.state.filename ? (  < Label size='tiny'  basic color='red' pointing >{this.state.filename} </Label>) : null }

                                 
                                </Form.Field>

                                <Form.Field>
                                <label>Add Refferal Document </label>
                                <InputFile
                                   
                                  input={{
                                    id: 'input-control-id',
                                    onChange: this.handleUploadAdd
                                  }}
                                  />

                                  {this.state.filename ? (  < Label size='tiny'  basic color='red' pointing >{this.state.addfilename} </Label>) : null }

                                 
                                </Form.Field>

                                 
                             </Form.Group>
                           
                             <Form.Field>
                                  <label>Note</label>
                                  <Form.TextArea name='note' fluid onChange={this.handleChange}  ></Form.TextArea>
                                  </Form.Field>
                             <Button
                     content="Submit"
                     labelPosition='right'
                     icon='checkmark'
                     color={'blue'}
                     
                      fluid 
                   />
                           </Form>
                    
                 </Modal.Content>
                  
               </Modal>
               ) : (<Modal
                onClose={() => this.setOpen(false)}
                onOpen={() => this.setOpen(true)}
                open={this.state.open}
                trigger={  <Button
                  floated='right'
                  icon
                  labelPosition='left'
                  disabled={authenteicated.role === 'therapist' }
                  color={'teal'}
                  size='medium'
                >
                  <Icon name='briefcase' /> Add New Patient
                </Button>}
              >
                <Modal.Header>Add New Job</Modal.Header>
                <Modal.Content  >
                <Form onValidSubmit={this.handleSubmitAgnecy}>
                            
          
                             
                              
                           <Form.Group widths={2}>
                           <Form.Field>
                                 <label>Patient Name </label>
                                 <Form.Input fluid required dplaceholder='John Doe' name='patientname'  onChange={this.handleChange}  /> 
                                 
                               </Form.Field>

                               <Form.Field>
                              <label>Select Specialty </label>
                              <Dropdown
                                name='specialty'
                                selection
                                fluid
                                options={specialties}
                                onChange={this.handleChangeSpecialty}
                                placeholder='Choose an specialty'
                                renderLabel={renderLabel}
                                required
                                validationErrors={{
                                  isDefaultRequiredValue: 'You need to select a specialty',
                                }}
                                errorLabel={errorLabel}
                              />
                            </Form.Field>
                           </Form.Group>
  
                               
                          
                            <Form.Group unstackable widths={3}>
                             <Form.Field>
                                <label>Refferal Document </label>
                                
                         

                                <InputFile
                                   
                                  input={{
                                    id: 'input-control-id',
                                    onChange: this.handleUpload
                                  }}
                                  />

                                  {this.state.refferal ? (  < Label size='tiny'  basic color='red' pointing >{this.state.refferal} </Label>) : null }

                                 
                                </Form.Field>

                                <Form.Field>
                                <label>Add Refferal Document </label>
                                <InputFile
                                   
                                  input={{
                                    id: 'input-control-id',
                                    onChange: this.handleUploadAdd
                                  }}
                                  />

                                  {this.state.addrefferal ? (  < Label size='tiny'  basic color='red' pointing >{this.state.addrefferal} </Label>) : null }

                                 
                                </Form.Field>

                                 
                             </Form.Group>
                           
                             <Form.Field>
                                  <label>Note</label>
                                  <Form.TextArea name='note' defaultValue={this.state.note} fluid onChange={this.handleChange}  ></Form.TextArea>
                                  </Form.Field>
                            <Button content="Submit"  labelPosition='right'  icon='checkmark' color={'blue'}  fluid   />
                          </Form>
                   
                </Modal.Content>
                 
              </Modal>) }
         
             <Header as='h1'>Patients</Header>
          <Segment basic>
          <Dimmer active={this.state.dimmer} inverted>
      <Loader />
    </Dimmer>

        <Modal 
          onClose={() => this.setOpenUpdate({open:false})}
          onOpen={() => this.setOpenUpdate({open:true})}
          open={this.state.openupdate}
          size='large' 
        >
          <Header icon>
            <Icon name='archive' />
              Assign Workers
          </Header>
          <Modal.Content>

            <Form  onValidSubmit={this.handleUpdate}>
            <Form.Group  unstackable widths={3}>

            <Form.Field>
              <label>Select Therapists </label>
              <Dropdown
                name='selectedtherapists'
                selection
                fluid
                search
                options={therapistOptions}
                multiple
                onChange={this.handleChangeTherapist}
                placeholder='Select'
                renderLabel={renderLabelTherapist} 
                required
                validationErrors={{
                  isDefaultRequiredValue: 'You need to select a therapist',
                }}
                errorLabel={errorLabel}
              />
                </Form.Field>
                <Form.Field>
              <label>Select Assistant </label>
              <Dropdown
                name='selectedassistants'
                selection
                fluid
                search
                options={assistantOptions}
                 
                onChange={this.handleChangeAssistant}
                placeholder='Select'
                renderLabel={renderLabelTherapist}
                 
                validationErrors={{
                  isDefaultRequiredValue: 'You need to select a therapist assistant',
                }}
                errorLabel={errorLabel}
              />
                </Form.Field>

                <Form.Field>
                                  <label>Select Aid </label>
                                  <Dropdown
                                    name='selectedaids'
                                    selection 
                                    fluid
                                    options={aidsOptions}
                                    onChange={this.handleChangeAssistant}
                                    placeholder='Select'
                                    renderLabel={renderLabelTherapist}
                                    clearable
                                    validationErrors={{
                                      isDefaultRequiredValue: 'You need to select a aid',
                                    }}
                                    errorLabel={errorLabel}
                                  />
                                </Form.Field>

            </Form.Group>
                  <Divider></Divider>
                <Button color='blue' content='Submit' fluid  >Assign</Button> 
            </Form>
                         
          </Modal.Content>
         
        </Modal>
         <Tab panes={panes} />
         
          </Segment>
         </Container>

      </div>
   );
 }
}
 
export default Jobs;
