import React from 'react'; 
import {Component} from 'react'; 
import {  Table, Loader,  Container, Checkbox, Tab, Icon, Button, Header, Segment, Message, Dimmer, Label, Confirm} from 'semantic-ui-react';
import axios from 'axios';
import Moment from 'react-moment';
import Auth from '../../validators/auth';
import { BrowserRouter as Link} from "react-router-dom";
  

 
class Docs extends React.Component {
  constructor(props) {
    super(props);
    this.props = props; 
  
    this.state = {
      forms:[],
        open:false,
        openForm:false, 
        dimmer:true,
        jobdata:{},
        therapists:[],
        confirm:false,
        editconfirm:false,
        user:[]
   };
 
    
   
  }
  setCloseForm=()=>{
    this.setState({
      openForm:false
    })
  }

  onchangeToggle=(id, value)=>{
     
    if(!value){
      axios.put(`https://api.pureptinc.com/forms/`+id, { 

        

        signed:true,
        signatureurl:this.state.user.signatureurl


      } )
      .then(res => { 
          
        this.refreshContent();
      
      }) 
    }
  }

  refreshContent=()=> {
    const authenteicated =  Auth.isAuthenticated(); 
    this.setState({ dimmer:true, forms:[]  })
    if( authenteicated.role === 'therapist' ){
        axios.get(`https://api.pureptinc.com/jobs/docs`, { params: { therapistid:authenteicated.userid  } } )
        .then(res => { 
            
            
            if(res.data.length > 0){
              this.setState({
                forms:res.data[0].forms,
                  dimmer:false
                })
            }
           
           
        
        }) 
    }
    else{
        axios.get(`https://api.pureptinc.com/forms`, { params: { } } )
        .then(res => { 
             
            this.setState({
              forms:res.data,
                dimmer:false
              })
        
        }) 
    }
  }
 
  componentDidMount(){
    
    this.refreshContent();
    const authenteicated =  Auth.isAuthenticated(); 
    
    axios.get(`https://api.pureptinc.com/therapists/`+authenteicated.userid )
    .then(res => { 
        
        
        this.setState({
          user:res.data[0],
            dimmer:false
          })
    
    }) 
 
  }
 

  handleChange = event => {
    this.name = event.target.name;
    this.setState({ [event.target.name]: event.target.value });
 }

 

 
 

handlePdfDownload = data => {


  axios.post(`https://api.pureptinc.com/forms/pdf`, data).then(response =>  {
 
     if(response.status === 201){
      setTimeout(() => {
        const link = document.createElement('a');
        link.href =  'https://pure-doc.s3.us-west-1.amazonaws.com/forms-'+data.template+'-'+data.job_id+'.pdf'
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }, 3000);
     
     }
    
     

  });
}

handleDownload = data => {
  axios.post(`https://api.pureptinc.com/pdfs/create`, data).then(response =>  {
 
     
    const link = document.createElement('a');
    link.href =  response.data.Location;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  });

} 
 
 
render() {
 
    const panes = [
        {
          menuItem: 'Unsigned Docs',
          render: () => <Table   singleLine  >
           
          <Table.Header>
            <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>Created</Table.HeaderCell>
              <Table.HeaderCell>Updated</Table.HeaderCell>
              <Table.HeaderCell>Note/Visit Date</Table.HeaderCell>
              <Table.HeaderCell>Form Type</Table.HeaderCell>
             
           
               <Table.HeaderCell>View</Table.HeaderCell>
               {
                authenteicated.role === 'therapist' ?  <Table.HeaderCell>Sign</Table.HeaderCell>
                 : null
              }
              
            </Table.Row>
          </Table.Header>
       
          <Table.Body>
          
          {this.state.forms.filter((form)=>{return !form.signed}).map((form, index) => (
               <Table.Row key={index}>
               <Table.Cell> <Icon disabled name='file alternate outline' /> </Table.Cell>     
               <Table.Cell> <Moment date={form.created_at} format="LL"  /> </Table.Cell> 
               <Table.Cell> <Moment date={form.updated_at} format="LL"  /> </Table.Cell> 
               <Table.Cell> {form.data.date} </Table.Cell> 
               <Table.Cell>{form.type} {form.type === 'Note' 
               ? <Label color='orange' horizontal>{form.index}</Label> : null }  </Table.Cell> 
               <Table.Cell> <Button color='blue' fluid onClick={() => { this.handlePdfDownload(
                {
                 "agency":this.state.agency_name,
                 "signatureurl":form.signatureurl,
                 "data":form.data, 
                 "job_id": form.job_id,
                 "template": form.type }
              ) }} >View/PDF</Button></Table.Cell>
               {authenteicated.role === 'therapist' ? <div>

               <Table.Cell> <Checkbox   onChange={(data)=>{this.onchangeToggle(form._id, form.signed)}} toggle disabled={this.state.user.signature === 'false'} /> </Table.Cell> 

               </div> : null}
                
               
             </Table.Row> 
          ))}
      
          </Table.Body>
          <Table.Footer fullWidth>
           
          </Table.Footer>
        </Table>,
        },
        {
          menuItem: 'Signed Docs',
          render: () => <Table   singleLine  >
           
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>Created</Table.HeaderCell>
              <Table.HeaderCell>Updated</Table.HeaderCell>
              <Table.HeaderCell>Note/Visit Date</Table.HeaderCell>
              <Table.HeaderCell>Form Type </Table.HeaderCell>
            
             

           
               <Table.HeaderCell>View </Table.HeaderCell>

               {
                authenteicated.role === 'therapist' ?  <Table.HeaderCell>Sign</Table.HeaderCell>
                 : null
              } 

              
            </Table.Row>
          </Table.Header>
              
          <Table.Body>
            
          
          {this.state.forms.filter((form)=>{return form.signed}).map((form, index) => (
               <Table.Row key={index}>
                 <Table.Cell> <Icon disabled name='file alternate outline' /> </Table.Cell>  
               <Table.Cell> <Moment date={form.created_at} format="LL"  /> </Table.Cell> 
               <Table.Cell> <Moment date={form.updated_at} format="LL"  /> </Table.Cell> 
               <Table.Cell> {form.data.date} </Table.Cell> 
               <Table.Cell>{form.type} {form.type === 'Note' 
               ? <Label color='orange' horizontal>{form.index}</Label> : null }  </Table.Cell> 
               <Table.Cell> <Button color='blue' fluid onClick={() => { this.handlePdfDownload(
                {
                 "agency":this.state.agency_name,
                 "data":form.data, 
                 "job_id": form.job_id,
                 "template": form.type }
              ) }} >View/PDF</Button></Table.Cell>
               {authenteicated.role === 'therapist' ? <div>
 
               <Table.Cell> <Checkbox toggle checked={form.signed} disabled /> </Table.Cell> 

               </div> : null}
                
               
             </Table.Row> 
          ))}
      
          </Table.Body>
          <Table.Footer fullWidth>
           
          </Table.Footer>
        </Table>,
        },
        
      ]
 
  const authenteicated =  Auth.isAuthenticated(); 
   
   
   return (
      <div>   
    
  <Container> 
    
 
  <Segment basic>
          <Dimmer active={this.state.dimmer} inverted>
      <Loader />
    </Dimmer>

    

 

    

    <Tab renderActiveOnly onTabChange={this.refreshContent}  menu={{ secondary: true, pointing: true }} panes={panes} />
          
          </Segment>
             
            
             </Container>
            
      </div>
   );
 }
}
 
export default Docs;
