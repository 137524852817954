
 class Authentication  {

   setSession = (authResult) => {
    console.log(authResult)
    let expiresAt = JSON.stringify(new Date().getTime());
    localStorage.setItem('userid', authResult._id);
    localStorage.setItem('isloggedin', true);
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('role', authResult.role);
    localStorage.setItem('uuid', authResult.uuid);
  }

  delSession = () => {
    localStorage.clear(); 
    window.location.href = '/';
  }


  isAuthenticated = () => {

    let auth = localStorage.getItem('isloggedin');
    let role = localStorage.getItem('role');
    let userid = localStorage.getItem('uuid');
    if(auth){
      return {status:true, role:role, userid:userid }
    }
    else {
      return {status:false}
    } 
  }
 

}

const Auth = new Authentication();
export default Auth;
