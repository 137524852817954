import React from 'react'; 
import { Table, Container, Button, Header, Icon, Modal, Label, Confirm, Pagination} from 'semantic-ui-react';
import {
  Form } from 'formsy-semantic-ui-react';
import axios from 'axios';
import { 
    Link
  } from "react-router-dom"; 

  import Auth from '../../validators/auth';
class Agencies extends React.Component {

    
    
  constructor(props) {
    super(props) 
    this.props = props;
    this.state = {
        agencies:[],
        open:false,
        selectedpage:1,
        totalpages:1,
        sort:'created_at',
        confirm:false
   };

  
   
  }

  refreshContent(data) { 
         const active =  (data ? data.active : 1)
         const postdata = {query:null, size:10, number:active,  sort:this.state.sort};
  
        axios.get(`https://api.pureptinc.com/agencies`, { params: postdata })
        .then(res => { 
            console.log(res)
            this.setState({
                agencies:res.data.agencies,
                totalpages:res.data.totalpages,
                dimmer:false
              })
        
        })
  }

  componentDidMount(){
     
    this.refreshContent()
     
  }

  handleChange = event => {
    this.name = event.target.name;
    this.setState({ [event.target.name]: event.target.value });
 }

 handleDelete = id => {
  axios.delete(`https://api.pureptinc.com/agencies/`+id).then(res => {this.refreshContent();});

}

  handleSubmit = () => {

    this.setState({
       
      dimmer: true
    })
              
      axios.post(`https://api.pureptinc.com/agencies/`, {
          name:this.state.name,
          contact:this.state.contact,
          phone:this.state.phone,
          address1:this.state.address1,
          address2:this.state.address2,
          city:this.state.city,
          state:this.state.state,
          zip:this.state.zip, 
          company_id:"5f777a3e2b5082092ac62dc0",
          rate:this.state.rate
      })
      .then(res => {
         
          axios.post(`https://api.pureptinc.com/auth/signup`, {
            email:this.state.email,
            password:this.state.password,
            permission:"false",
            role:"agency", 
            uuid:res.data,
            companyid:"5f777a3e2b5082092ac62dc0"
        }).then(result => {
            this.refreshContent()
            this.setState({
              open:false,
              dimmer: false
            })
          })
      })
  }

  setOpen(value){
    this.setState({
        open:value 
         
      })
  }

  handlePaginationChange = (e, { activePage }) => {
 
 
    this.refreshContent({active:activePage})
    
  }
  
  
  dynamicSort = (property) => {
    console.log(property)
   var sortOrder = 1;
   if(property[0] === "-") {
       sortOrder = -1;
       property = property.substr(1);
   }
   return function (a,b) {
       /* next line works with strings and numbers, 
        * and you may want to customize it to your needs
        */
       var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
       return result * sortOrder;
   }
  }
  
  sortContent = (data) => {
      
    const agencies = this.state.agencies.sort(this.dynamicSort(String(data)));
    this.setState({agencies:agencies})
  }

  
 render() {

  const errorLabel = <Label color="red" pointing/>
  
   return (

    
      <div>   

<Confirm
          size={'tiny'}
          open={this.state.confirm}
          confirmButton={'Yes'}
          content={'Are you sure you want to delete this agency?'}
          onCancel={()=>{this.setState({confirm:false})}}
          onConfirm={()=>{ 
             this.setState({confirm:false})
              this.handleDelete(this.state.selectedagencyid)

           }}
        />
         
         <Container>
             <Header as='H1'>Agenecies</Header>
         <Table   basic='very'  >
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell onClick={() => {  this.sortContent('name')}} >Name</Table.HeaderCell>
        <Table.HeaderCell>Contact</Table.HeaderCell>
        <Table.HeaderCell>Phone</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
        
      </Table.Row>
    </Table.Header>

    <Table.Body>
    
    {this.state.agencies.map((agency, index) => (
             <Table.Row key={index}>
         <Table.Cell>{agency.name}</Table.Cell>
         <Table.Cell>{agency.contact}</Table.Cell>
         <Table.Cell>{agency.phone}</Table.Cell> 
        
         <Table.Cell>
           
           <Button as={Link} to={{
                pathname: "/schedule", 
                state: { "id": agency._id }
              }} color={'green'} fluid>
                <Icon name='calendar alternate' />
                Schedule</Button></Table.Cell>

                <Table.Cell>
           
           <Button as={Link} to={{
                pathname: "/patients",  
                state: { "id": agency._id, agency:true}
              }} color={'violet'} fluid>
                <Icon name='briefcase alternate' />
                Patients</Button></Table.Cell>
                
                
         <Table.Cell><Button as={Link}   
              to={{
                pathname: "/agency", 
                state: { "id": agency._id }
              }}
         
         color={'blue'} fluid>
           <Icon name='edit outline' />
           Detail</Button></Table.Cell>
          
         <Table.Cell>
           <Button color={'red'}    onClick={() => { this.setState({selectedagencyid:agency._id, confirm:true}) }}  fluid>Delete</Button>
                  </Table.Cell> 
       </Table.Row> 
    ))}

    </Table.Body>
    <Table.Footer fullWidth>
      <Table.Row>
        
        <Table.HeaderCell colSpan='7'>
        

          <Modal
      onClose={() => this.setOpen(false)}
      onOpen={() => this.setOpen(true)}
      open={this.state.open}
      trigger={  <Button
        floated='right'
        icon
        labelPosition='left'
         
        color={'olive'}
        size='medium'
      >
        <Icon name='briefcase' /> Add New Agenecy
      </Button>}
    >
      <Modal.Header>Add New Agency</Modal.Header>
      <Modal.Content  >
      <Form onValidSubmit={this.handleSubmit}>
      <Form.Group unstackable widths={2}>
                           <Form.Input validations="isEmail" validationErrors={{ isDefaultRequiredValue: 'Email is Required', isEmail: 'Email not valid' }} required  errorLabel={ errorLabel } onChange={this.handleChange} name='email' label='Email' placeholder='Email' value={this.state.email} />
                           <Form.Input required  validations="minLength:8"  validationErrors={{ minLength: 'Minimin of 8 characters', isDefaultRequiredValue: 'Password is Required', }} errorLabel={ errorLabel } onChange={this.handleChange}  name='password' label='Password' type='password' placeholder='Password' value={this.state.password}   />            
                         </Form.Group>
                  <Form.Group unstackable widths={3}>
                    <Form.Input required onChange={this.handleChange} name='name' label='Name' placeholder='Name' value={this.state.name} />
                    <Form.Input required onChange={this.handleChange} name='contact' label='Contact' placeholder='Contact' value={this.state.contact}   />
                    <Form.Input required onChange={this.handleChange} name='phone' label='Phone' placeholder='Phone' value={this.state.phone}   />
                    <Form.Input required onChange={this.handleChange} name='rate' label='Rate' placeholder='Rate' value={this.state.rate}   />
                  </Form.Group>
                  <Form.Group widths={5}>
                    <Form.Input required onChange={this.handleChange} name='address1' label='Address' placeholder='Address 1' value={this.state.address1}  />
                    <Form.Input onChange={this.handleChange} name='address2' label='Address 2' placeholder='Address 2' value={this.state.address2}  /> 
                    <Form.Input required onChange={this.handleChange} name='city' label='City' placeholder='City' value={this.state.city}  />
                    <Form.Input required onChange={this.handleChange} name='state' label='State' placeholder='State' value={this.state.state}  />
                    <Form.Input validations="isNumeric" validationErrors={{ isDefaultRequiredValue: 'Valid Zipcode Required', isNumeric: 'Numbers Only' }} required  errorLabel={ errorLabel } onChange={this.handleChange} name='zip' label='Zipcode' placeholder='Zipcode' value={this.state.zip}  />
                  </Form.Group>
                  
                  <Button
          content="Confirm"
          labelPosition='left'
          icon='plus'
          content="Submit"
           fluid 
        />
                </Form>
         
      </Modal.Content>
       
    </Modal>
          
        </Table.HeaderCell>
        
      </Table.Row>
    </Table.Footer>
  </Table>

  <Pagination onPageChange={this.handlePaginationChange} defaultActivePage={this.state.selectedpage} totalPages={this.state.totalpages} />
         </Container>

      </div>
   );
 }
}
 
export default Agencies;
