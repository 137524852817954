import React from 'react'; 
import { Table, Container, Button, Header, Popup, Label, Pagination, Checkbox, Dimmer, Loader, Segment, Confirm} from 'semantic-ui-react'
import axios from 'axios';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link
  } from "react-router-dom";
  import {
    Form, Input, TextArea,   Radio, RadioGroup, Dropdown, Select,
  } from 'formsy-semantic-ui-react';

class Users extends React.Component {

    
    
  constructor(props) {
    super(props) 
    this.state = {
        users:[], 
        open:false,
        dimmer:true,
        selectedpage:1,
        totalpages:1,
        sort:'created_at',
        email:'',
        password:''
   };

  
   
  }

  handleSubmit = () => {
     
    axios.post(`https://api.pureptinc.com/auth/signup`, {
      email:this.state.email,
      password:this.state.password,
      permission:"false",
      role:"employee", 
      uuid:"5f777a3e2b5082092ac62dc0",
      companyid:"5f777a3e2b5082092ac62dc0"
  }).then(result => {
    this.refreshContent()
    this.setState({
      open:false,
      dimmer: false
    })
  })
  }

  refreshContent(data) { 
    const active =  (data ? data.active : 1)
    const postdata = {query:null, size:10, number:active,  sort:this.state.sort};

   axios.get(`https://api.pureptinc.com/users`, { params: postdata })
   .then(res => { 
       console.log(res)
       this.setState({
           users:res.data.users,
           totalpages:res.data.totalpages,
           dimmer:false
         })
   
   })
}

  componentDidMount(){
      
    this.refreshContent();
        
  }

  

  handlePaginationChange = (e, { activePage }) => {
 
 
    this.refreshContent({active:activePage})
    
  }

  setOpen(value){
    this.setState({
        open:value,
        dimmer: (value ? true : false)
      })
  }
  handleChange = event => {
    this.name = event.target.name;
    this.setState({ [event.target.name]: event.target.value });
 }

 handleDelete = id => {
  axios.delete(`https://api.pureptinc.com/users/`+id).then(res => {this.refreshContent();});

}

  
  
 render() {
  const errorLabel = <Label color="red" pointing/>
    
   return (

    
      <div>   
         
         <Container>
         <Confirm
          size={'tiny'}
          open={this.state.confirm}
          confirmButton={'Yes'}
          content={'Are you sure you want to delete this user?'}
          onCancel={()=>{this.setState({confirm:false})}}
          onConfirm={()=>{ 
             this.setState({confirm:false})
              this.handleDelete(this.state.selecteduser)

           }}
        />
             <Header as='H1'>Users</Header>
          <Segment basic>
          <Dimmer active={this.state.dimmer} inverted>
      <Loader />
    </Dimmer>
          <Table   basic={'very'}   >
           
           <Table.Header>
             <Table.Row>
               <Table.HeaderCell>Email</Table.HeaderCell>
               <Table.HeaderCell>Role</Table.HeaderCell>
               <Table.HeaderCell>Permission</Table.HeaderCell>
               <Table.HeaderCell>Active</Table.HeaderCell>
               <Table.HeaderCell></Table.HeaderCell>
             </Table.Row>
           </Table.Header>
        
           <Table.Body>
           
           {this.state.users.map((user, index) => (
                <Table.Row>
                <Table.Cell>{user.email}  </Table.Cell>
                <Table.Cell> <Label color={user.role === 'therapist' ? 'green' : 'blue'} horizontal>
                {user.role}
      </Label> </Table.Cell>
                <Table.Cell><Label color={user.permission === 'true' ? 'blue' : 'red'} horizontal>{user.permission}    </Label> </Table.Cell> 
                <Table.Cell><Checkbox toggle disabled={user.role != 'company' ? false : true} /></Table.Cell>
                {user.role != 'company' ? <Table.Cell><Button color={'red'}    onClick={() => { this.setState({confirm:true, selecteduser:user._id}) }}  fluid>Delete</Button></Table.Cell> : null }
                
                 
              </Table.Row> 
           ))}
       
           </Table.Body>
            
         </Table>
         <Pagination onPageChange={this.handlePaginationChange} defaultActivePage={this.state.selectedpage} totalPages={this.state.totalpages} />
          </Segment>

          <Segment stacked>  
          <Form onValidSubmit={this.handleSubmit}>
                       
                       <Form.Group unstackable widths={2}> 
                       <Form.Input validations="isEmail" validationErrors={{ isDefaultRequiredValue: 'Email is Required', isEmail: 'Email not valid' }} required  errorLabel={ errorLabel } onChange={this.handleChange} name='email' label='Email' placeholder='Email' value={this.state.email} />
                       <Form.Input required  validations="minLength:8"  validationErrors={{ minLength: 'Minimin of 8 characters', isDefaultRequiredValue: 'Password is Required', }} errorLabel={ errorLabel } onChange={this.handleChange}  name='password' label='Password' type='password' placeholder='Password' value={this.state.password}   />            
                     
                       </Form.Group>
                       <Button  content="Submit" labelPosition='right' icon='checkmark' color={'blue'}  fluid  />
          </Form>
          </Segment>
          
         </Container>

      </div>
   );
 }
}
 
export default Users;
