import React from 'react'; 
import { Menu, Divider, Modal, Sidebar, Grid, Button, Table, Label, Popup,   Confirm, Loader, Segment, Icon, Item, Input, Header} from 'semantic-ui-react';
import axios from 'axios'; 
import moment from 'moment'
import Moment from 'react-moment'; 
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; 
import resourceTimelinePlugin from "@fullcalendar/resource-timeline"; 
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'; 
import momentTimezonePlugin from '@fullcalendar/moment-timezone'; 
import PhysicalTherapyOrder from '../jobs/forms/therapists/pt/order';
import PhysicalTherapyEval from '../jobs/forms/therapists/pt/eval';
import PhysicalTherapyNote from '../jobs/forms/therapists/pt/note';
import PhysicalTherapyDischarge from '../jobs/forms/therapists/pt/discharge';
import Empty from '../jobs/forms/therapists/pt/empty'; 
import {
  DateInput,
  TimeInput,
  DateTimeInput,
  DatesRangeInput
} from 'semantic-ui-calendar-react';
import {
  Form, Checkbox, Dropdown   
} from 'formsy-semantic-ui-react';
import './schedule.css'; 

const forms = {
  'Empty': Empty,
  'Order': PhysicalTherapyOrder,
  'Evaluation': PhysicalTherapyEval,
  'Note': PhysicalTherapyNote,
  'Discharge': PhysicalTherapyDischarge
};
 
class Schedule extends React.Component {
   
  constructor(props) {

    super(props) 
    this.props = props;
    this.state = {
      jobs:[],
      therapist:[],
      error:false,
      errorMessage:'',
      selecttherapist:[],
      selectedassistants:null,
      newaids:null,
      therapists:[],
      clockin:new Date(),
      clockout:new Date(),
      resourceid:null,
      selectedschedule:null,
      jobstart:new Date,
      jobend:new Date,
      daymark: new Date,
      jobedit:false,
      job_id:null, 
      start: null,
      end:null,
      status:null,
      discharge:false,
      headerToolbar: {
        left:'prev,today,next',
        center: 'title',
        right: 'resourceTimelineFull,resourceTimelineGrid'
      }, 
      dimmer:true,
      edit:false,
      open:false,
      visits:null,
      note:'',
      forms:[],
      newform:false,
      selectedForm:Empty,
      exludeemp:false,
      billing:false,
      resource:[],
      search:null,
      calendarsort:'title'
   };

   this.creatEvent = this.creatEvent.bind(this);
  }

  calendarRef = React.createRef()

  handleError = () => {
    this.setState({error:false})
    
  }

  renderEventContent(eventInfo){
    
    const status = eventInfo.event._def.extendedProps.status;
    const billing = eventInfo.event._def.extendedProps.billing;
    const aids = eventInfo.event._def.extendedProps.aids;
    const assistants = eventInfo.event._def.extendedProps.assistants;
    const therapists = eventInfo.event._def.extendedProps.therapists;
    return (
      <>
        <Popup  trigger={<Label basic horizontal>
    {eventInfo.event.title} 
      </Label>} on={['hover']}>   
         <Item> 

      <Item.Content>
        <Item.Header  >
          
       
          <strong>{therapists.map((therapist)=> {return therapist.fname + ' ' + therapist.lname})}</strong></Item.Header>
        <Item.Meta>
          <span className='cinema'>{eventInfo.event.title} </span>
        </Item.Meta>
        <Item.Description>Assistant: <strong>{assistants.map((asistant)=> {return asistant.fname + ' ' + asistant.lname})}</strong> Aid: <strong>{aids.map((aid)=> {return aid.fname + ' ' + aid.lname})}</strong> </Item.Description>
        <Item.Extra>
        <Label size={'mini'} color={status === 'discharge' ? 'red' : (status === 'evaluate' ? 'blue' : (status === 'reeval' ? 'teal' : (status === 'refusal' ? 'yellow' : (status === 'visit' ? 'green' : (status === 'missvisit' ? 'violet' : 'orange')))))}  >{status}</Label>
        <Label size={'mini'} color={billing ? 'green' : 'red' }  >Billed</Label>
        </Item.Extra>
      </Item.Content>
    </Item>
        </Popup>
       
      </>
    )
  }

   searchresources = async() => {
    const agencyid = this.props.location.state.id;
    let calendarApi = this.calendarRef.current.getApi()
    axios.get(`https://api.pureptinc.com/jobs/schedules`, {
      params: {

        query:{

          agency_id: agencyid, 
          assigned:true,
          start:  calendarApi.view.currentStart,
          end:   calendarApi.view.currentEnd,

         }
       
       } 
       
       })
   .then(res => { 
   
       
       const resources =   res.data.jobs.map((job)=>{
       return  { 
          id: job._id, 
          aids: (job.aids ? <Label  color={'green'} horizontal>{ job.aids.fname + ' ' + job.aids.lname } - { job.aids.specialty}</Label>:null),
          assistant: (job.assistants ? <Label  color={'yellow'} horizontal>{ job.assistants.fname + ' ' + job.assistants.lname } - { job.assistants.specialty} </Label> : null),
          therapist: job.therapists.map((therapist)=>{return <Label  color={'violet'} horizontal>{ therapist.fname + ' ' + therapist.lname } - { therapist.specialty}</Label>}),  
          title:  job.patientname + job.specialty,
          detail:   <Button compact color={'blue'} onClick={()=>{
           axios.get(`https://api.pureptinc.com/forms`, { params: { "job_id": job._id} } )
           .then(res => { 
            
               this.setState({
                 forms:res.data 
                 })
           
           }) 
           this.setState({
             selectedjob:job._id,
             jobstart:moment(job.start).format('L'),
             jobend: moment(job.end).format('L'),
             daymark: moment(job.daymark).format('L'),
             visitseq:job.visitseq,
             visit:job.visit, 
             jobedit:true
           })
          }}>Edit Patient</Button>
         }
       })

      

       this.setState({
           resource:resources,
           dimmer:false
         })
   
   })

  } 

  async fetchData(id){


    let calendarApi = this.calendarRef.current.getApi()
     

    this.setState({dimmer:true})
    const agencyid = id;
    await axios.get(`https://api.pureptinc.com/therapists`)
    .then(res => { 
     
        this.setState({
            therapists:res.data.therapists,
            dimmer:false
          })
    
    }) 


      

     


    await axios.get(`https://api.pureptinc.com/schedules` )
    .then(res => { 
        

        const events =   res.data.schedules.map((schedule)=>{
            return  { id: schedule._id, resourceId: schedule.resourceid, start:moment(`${schedule.start} ${schedule.time_in}`, 'YYYY-MM-DD HH:mm:ss a').format(), end:moment(`${schedule.start} ${schedule.time_out}`, 'YYYY-MM-DD HH:mm:ss a').format(), title:moment(schedule.time_in).format('LT') + '-' + moment(schedule.time_out).format('LT'), backgroundColor:schedule.status === 'visit' ? '#21ba45' : (schedule.status === 'discharge' ? '#db2828' : (schedule.status === 'reeval' ? '#009c95': (schedule.status === 'refusal' ? '#FBBD08' : (schedule.status === 'cancelation' ? '#F2711C' : (schedule.status === 'missvisit' ? '#6435c9' : '#1678c2') ))) ), borderColor:'transparent', extendedProps:{billing:schedule.billing, jobid:schedule.job_id, note:schedule.note, therapists:schedule.therapists, start: schedule.start, time_in:schedule.time_in, time_out: schedule.time_out, aids: schedule.aids, assistants: schedule.assistants,status:schedule.status}}
        })
        this.setState({ 
            events:events,
            dimmer:false
          })
    
    })
    
     
     
    axios.get(`https://api.pureptinc.com/jobs/schedules`, {
       params: {

         query:{

           agency_id: agencyid, 
           assigned:true,
           start:  calendarApi.view.currentStart,
           end:   calendarApi.view.currentEnd
          }
        
        } 
        
        })
    .then(res => { 
    
        
        const resources =   res.data.jobs.map((job)=>{
        return  { 
           id: job._id, 
           assistantid: (job.assitants ? job.assistants.fname + ' ' + job.assistants.lname : null),
           therapistid: (job.therapists.length > 0 ? job.therapists[0].fname + ' ' + job.therapists[0].lname : null),
           aids: (job.aids ? <Label  basic color={'green'} horizontal>{ job.aids.fname + ' ' + job.aids.lname } - { job.aids.specialty}</Label>:null),
           assistant: (job.assistants ? <Label  basic color={'yellow'} horizontal>{ job.assistants.fname + ' ' + job.assistants.lname } - { job.assistants.specialty} </Label> : null),
           therapist: job.therapists.map((therapist)=>{return <Label basic color={'violet'} horizontal>{ therapist.fname + ' ' + therapist.lname } - { therapist.specialty}</Label>}),  
           patient: <Segment basic>{job.patientname}  {job.specialty === 'pt' ? <Label size={'mini'} color='green' horizontal>PT</Label> : (job.specialty === 'ot' ? <Label size={'mini'} color='olive' horizontal>OT</Label> : <Label size={'mini'} color='purple' horizontal>ST</Label>  )}</Segment>,
           title:job.patientname,
           detail:   <Label basic as={'a'} color={'black'} onClick={()=>{
            axios.get(`https://api.pureptinc.com/forms`, { params: { "job_id": job._id} } )
            .then(res => { 
             
                this.setState({
                  forms:res.data 
                  })
            
            }) 
            this.setState({
              selectedjob:job._id,
              jobstart:moment(job.start).format('L'),
              jobend: moment(job.end).format('L'),
              daymark: moment(job.daymark).format('L'),
              visitseq:job.visitseq,
              selecttherapist:null,
              selectedassistants:null,
              newaids:null,
              visit:job.visit, 
              jobedit:true
            })
           }}>Edit Patient</Label>
          }
        })

       

        this.setState({
            resource:resources,
            dimmer:false
          })
    
    })
}

handleDateChange = (event, {name, value}) => {
   
  
  
  this.setState({  [name]: value  })
  
}

toggle = (key, value) => {
   
  this.setState({  [key]: !this.state[key]  })
}


updatedropEvent = (data) => {
  
  
  axios.put(`https://api.pureptinc.com/schedules/`+data.id, { 
    
    start:data.start
  
  }).then(res => {  
    this.fetchData(this.props.location.state.id); 
  });


}

updateEvent = () => {

  

  const query = {
    id:this.state.selectedschedule,
    vistor:this.state.selectedvisitor,
    start:this.state.start,
    time_in: moment(`${this.state.start} ${this.state.clockin}`, 'YYYY-MM-DD HH:mm:ss a').format() ,
    time_out: moment(`${this.state.start} ${this.state.clockout}`, 'YYYY-MM-DD HH:mm:ss a').format(),
  };

  const postdata = {query:query};

  axios.get(`https://api.pureptinc.com/schedules/validator`, { params: postdata } )
  .then(res => {  
    
    if(res.data === undefined || res.data.length == 0){
      console.log(this.state.selecttherapist)
      axios.put(`https://api.pureptinc.com/jobs/`+this.state.job_id, {
        therapists: this.state.selecttherapist,
        aids:this.state.newaids === '' ? null : this.state.newaids,
        assistants:this.state.selectedassistants,
      })
    axios.put(`https://api.pureptinc.com/schedules/`+this.state.selectedschedule, { 
    
    aid:this.state.newaids === '' ? null : this.state.newaids,
    therapists: this.state.selecttherapist,
    assistant:(this.state.selectedassistants ? this.state.selectedassistants : null),
    time_in: (moment(`${this.state.start} ${this.state.clockin}`, 'YYYY-MM-DD HH:mm:ss a').format() ? moment(`${this.state.start} ${this.state.clockin}`, 'YYYY-MM-DD HH:mm:ss a').format() : null),
    time_out: (moment(`${this.state.start} ${this.state.clockout}`, 'YYYY-MM-DD HH:mm:ss a').format() ? moment(`${this.state.start} ${this.state.clockout}`, 'YYYY-MM-DD HH:mm:ss a').format() : null),
    status: (this.state.status ? this.state.status : null),
    note:this.state.note,
    billing:this.state.billing,
    exludeemp:this.state.exludeemp,
       
  
  }).then(res => {  
    this.fetchData(this.props.location.state.id);
    this.setState({
      edit:false,
      selecttherapist:null,
      selectedassistants:null,
      newaids:null,
      assistant:null,
      clockin:null,
      clockout:null,
      status:null,
      billing:false,
      exludeemp:false,
    })
  });
    }
    else{
      this.setState({ billing:false,
        exludeemp:false, error:true, errorMessage:'Physical Therapist is busy at ' + moment(res.data[0].start).format('L') + ' starting at ' + moment(res.data[0].time_in).format('LT') + ' ending at ' + moment(res.data[0].time_out).format('LT') + ' please select different time or date.'})
    }
   
})  

}

refreshContent = () => {
  this.setState({newform:false})
  this.fetchData(this.props.location.state.id) 
}

handleChangeForm = (e, { value }) => { 
  this.setState({
    selectedForm:value  
  })
 }

deleteSchedule = () => {
 
  axios.delete(`https://api.pureptinc.com/schedules/`+this.state.selectedschedule).then(res => {  
    this.fetchData(this.props.location.state.id);
    this.setState({
      edit:false,
      selectedschedule:null,
      clockin:null,
      clockout:null,
      status:null 
    })
  });
}


missVisit = () => {
 
  axios.put(`https://api.pureptinc.com/schedules/`+this.state.selectedschedule, { status: 'missvisit' }).then(res => {  
    this.fetchData(this.props.location.state.id);
    this.setState({
      edit:false,
      selectedschedule:null,
      clockin:null,
      clockout:null,
      status:null 
    })
  });
}
 
  creatEvent = (info) => {

    let query = {}
 
    if(this.state.status === 'evaluate' || this.state.status === 'reeval' || this.state.status === 'discharge'){

      
     query = {
        visitor:this.state.selecttherapist[0],
        assistant: this.state.selectedassistants,
        therapists: this.state.selecttherapist,
        start:this.state.start, 
        time_in: moment(`${this.state.start} ${this.state.clockin}`, 'YYYY-MM-DD HH:mm:ss a').format() ,
        time_out: moment(`${this.state.start} ${this.state.clockout}`, 'YYYY-MM-DD HH:mm:ss a').format(),
        status: this.state.status,
        aids:this.state.selectedaids,
        id:null
      };

    }

    else if(this.state.status === 'visit') {
      
      

      if( this.state.selectedassistants === '' ||  this.state.selectedassistants === null){

       
        query = {
          visitor:this.state.selecttherapist[0],
          assistant: this.state.selectedassistants,
          therapists: this.state.selecttherapist,
          start:this.state.start, 
          time_in: moment(`${this.state.start} ${this.state.clockin}`, 'YYYY-MM-DD HH:mm:ss a').format() ,
          time_out: moment(`${this.state.start} ${this.state.clockout}`, 'YYYY-MM-DD HH:mm:ss a').format(),
          status: this.state.status,
          aids:this.state.selectedaids,
          id:null
        };
      }
      else {
        
      query = {
          visitor:this.state.selectedassistants,
          assistant: this.state.selectedassistants,
          therapists: this.state.selecttherapist,
          start:this.state.start, 
          time_in: moment(`${this.state.start} ${this.state.clockin}`, 'YYYY-MM-DD HH:mm:ss a').format() ,
          time_out: moment(`${this.state.start} ${this.state.clockout}`, 'YYYY-MM-DD HH:mm:ss a').format(),
          status: this.state.status,
          aids:this.state.selectedaids,
          id:null
        };
      }


    }

    else {

      

      query = {
        visitor:null,
        assistant: this.state.selectedassistants,
        therapists: this.state.selecttherapist,
        start:this.state.start, 
        time_in: moment(`${this.state.start} ${this.state.clockin}`, 'YYYY-MM-DD HH:mm:ss a').format() ,
        time_out: moment(`${this.state.start} ${this.state.clockout}`, 'YYYY-MM-DD HH:mm:ss a').format(),
        status: this.state.status,
        aids:this.state.selectedaids,
        id:null
      };
      

    }


 
    
    const postdata = {query:query};

    if(this.state.status === 'cancelation' || this.state.status === 'refusal') {
      this.setState({dimmer:true})
      axios.put(`https://api.pureptinc.com/jobs/`+this.state.job_id, {
        therapists: this.state.selecttherapist,
        aids:this.state.selectedaids,
        assistants:this.state.selectedassistants,
      })
      axios.post(`https://api.pureptinc.com/schedules`, {
        visitor:null,
        start:this.state.start,
        job_id: this.state.job_id,
        resourceid: this.state.resourceid,
        agencyid: this.props.location.state.id,
        company_id:"5f777a3e2b5082092ac62dc0",
        therapists: null,
        aids:null,
        assistant:null,
        time_in: moment(`${this.state.start} ${this.state.clockin}`, 'YYYY-MM-DD HH:mm:ss a').format() ,
        time_out: moment(`${this.state.start} ${this.state.clockout}`, 'YYYY-MM-DD HH:mm:ss a').format(),
        status: this.state.status,
        note:this.state.note
    } ).then((data)=>{
       
      this.fetchData(this.props.location.state.id)
      this.setState({
        dimmer:false,               
        open:false, 
        time_in: null,
        time_out: null,
        discharge: false,
        selecttherapist:'',
        selectedassistants:'',
        selectedaids:'', 
        note:''
      })
    })
    }

    else {
      axios.get(`https://api.pureptinc.com/schedules/validator`, { params: postdata } )
      .then(res => {  
        
  
        
        if(res.data === undefined || res.data.length == 0){
            
          this.setState({dimmer:true})
          axios.put(`https://api.pureptinc.com/jobs/`+this.state.job_id, {
            therapists: this.state.selecttherapist,
            aids:this.state.selectedaids,
            assistants:this.state.selectedassistants,
          })
          axios.post(`https://api.pureptinc.com/schedules`, {
            visitor:query.visitor,
            start:this.state.start,
            job_id: this.state.job_id,
            resourceid: this.state.resourceid,
            agencyid: this.props.location.state.id,
            company_id:"5f777a3e2b5082092ac62dc0",
            therapists: this.state.selecttherapist,
            aid:this.state.selectedaids,
            assistant:this.state.selectedassistants,
            time_in: moment(`${this.state.start} ${this.state.clockin}`, 'YYYY-MM-DD HH:mm:ss a').format() ,
            time_out: moment(`${this.state.start} ${this.state.clockout}`, 'YYYY-MM-DD HH:mm:ss a').format(),
            status: this.state.status,
            note:this.state.note
        } ).then((data)=>{
           
          this.fetchData(this.props.location.state.id)
          this.setState({
            dimmer:false,               
            open:false, 
            time_in: null,
            time_out: null,
            discharge: false,
            selecttherapist:'',
            selectedassistants:'',
            selectedaids:'', 
            note:''
          })
        })
        
        }
        else{
          this.setState({error:true, errorMessage:'Physical Therapist is busy at ' + moment(res.data[0].start).format('L') + ' starting at ' + moment(res.data[0].time_in).format('LT') + ' ending at ' + moment(res.data[0].time_out).format('LT') + ' please select different time or date.'})
        }
       
    })
    }

 
     
 

  
  }

    handleDateSelect = (info) => {

      axios.get(`https://api.pureptinc.com/jobs/`+info.resource._resource.id).then((job)=>{
        
        this.setState({
          clockin: '',
          clockout: '',
          selectedtherapists: job.data[0].therapists,
          selecttherapist: job.data[0].therapists,
          selectedassistants:job.data[0].assistants,
          selectedaids:job.data[0].aids,         
          open:true, 
          status:null,
          start: info.startStr,
          job_id: info.resource._resource.id,
          resourceid: info.resource._resource.id,
        
        })
      })

       
    }

  componentDidMount(){


    this.fetchData(this.props.location.state.id) 
   

  }

  handleChangeTherapist = (event, {name, value}) => {
     
    this.setState({selecttherapist:value}) 
     
  }

  handleChangeAid = (event, {name, value}) => {
  
    
    this.state.therapists.filter(therapist => therapist._id === value).map((p, index) => {
      this.setState({   [name]: p._id}) 
    })
  }

  handleChangeAssistant = (event, {name, value}) => {

    console.log(event)
    if(!value){
      this.setState({[name]: null}) 
    }
    else{
      this.state.therapists.filter(therapist => therapist._id === value).map((p, index) => {
        this.setState({[name]: p._id}) 
      })
    }
  
   
  }

 handleChange = (event, {name, value}) => {
   
   
  this.setState(prevState => ({
    [name]: value  
}))
  
}

refetchResources = (data) => {


  
}

 

handleJobModify = () => {
    
    let start = moment(this.state.jobstart, 'MM-DD-YYYY').format()
    let end = moment(this.state.jobend, 'MM-DD-YYYY').format() 
    let mark = moment(this.state.daymark, 'MM-DD-YYYY').format() 
  
    axios.put(`https://api.pureptinc.com/jobs/`+this.state.selectedjob, { 

      start: new Date(start),
      end: new Date(end),
      visit:this.state.visit,
      visitseq:this.state.visitseq,
      daymark: new Date(mark)

     }).then(res => {  
    this.fetchData(this.props.location.state.id);
    this.setState({
      jobedit:false,
      selectedjob:null,
      jobstart:null,
      jobend:null,
      visit:null,
      daymark:null
    })
  });
}


 render() {

    const calendarRef = React.createRef()
    const statusOptions = [
      {
        key: 1,
        text: 'Discharge',
        value: 'discharge'  
      },
      {
        key: 2,
        text: 'Evaluate',
        value: 'evaluate'  
      },
      {
        key: 3,
        text: 'Visit',
        value: 'visit'  
      },
      {
        key: 4,
        text: 'Re Evaluate',
        value: 'reeval'  
      },
      {
        key: 5,
        text: 'Refusal',
        value: 'refusal'  
      },
      {
        key: 6,
        text: 'Cancelation',
        value: 'cancelation'  
      }
     
    ]


    const DynamicComponent = forms[this.state.selectedForm];

    const renderLabelTherapist = (label) => ({
    
      color: 'blue',
      content: ' ' + label.fname + ' ' + label.lname,
      
      
    })

    const therapistOptions = this.state.therapists.filter(therapist => therapist.role === 'therapist').map((p, index) => {
      return {...p, key: index, text: p.fname + ' ' + p.lname, value:p._id, content: ( <div><Label color={p.specialty === 'ot' ? 'violet' : 'blue'} horizontal>
      {p.specialty}
    </Label> { p.fname + ' ' + p.lname }</div>)}
    })

    const assisOptions = this.state.therapists.filter(therapist => therapist.role === 'assistant').map((p, index) => {
      return {...p, key: index, text: p.fname + ' ' + p.lname, value:p._id, content: ( <div><Label color={p.specialty === 'ot' ? 'violet' : 'blue'} horizontal>
      {p.specialty}
    </Label> { p.fname + ' ' + p.lname }</div>)}
    })

    const aidsOptions = this.state.therapists.filter(therapist => therapist.role === 'aid').map((p, index) => {
      return {...p, key: index, text: p.fname + ' ' + p.lname, value:p._id, content: ( <div><Label color={p.specialty === 'ot' ? 'violet' : 'blue'} horizontal>
      {p.specialty}
    </Label> { p.fname + ' ' + p.lname }</div>)}
    })


    const errorLabel = <Label color="red" pointing/>
    const visible = this.state.openslider;

    const options = [
      { key: 1, text: 'Order', value: 'Order' },
      { key: 2, text: 'Evaluation', value: 'Evaluation' },
      { key: 3, text: 'Note', value: 'Note' },
      { key: 4, text: 'Discharge', value: 'Discharge' }    
    ]

    const renderLabel = (label) => ({
      color: 'blue',
      content: `Customized label - ${label.text}`,
      icon: 'check',
    })


  
    const resources = this.state.search ? this.state.resource.filter(resource => resource.title.includes(this.state.search)) : this.state.resource;
 

    return (
       <div style={{paddingLeft:'150px',  }}>   
 
          
                    <div>
                    <Confirm
          confirmButton="Yes"
          open={this.state.confirm}
          onCancel={()=> this.setState({confirm:false, open:true })}
          onConfirm={()=> { 
            this.setState({confirm:false, newform:false })}
          }
        />
                    <Confirm
         confirmButton="Yes"
          open={this.state.editconfirm}
          onCancel={()=> this.setState({editconfirm:false, openForm:true })}
          onConfirm={()=> { 
            this.setState({editconfirm:false, openForm:false, formname:'Empty'})
          }}
        />

        
                    <Modal 
                   
                   onClose={() =>  this.setState({
                     
                    editconfirm:true,  
                    openForm:true,   
                    selectedtherapists: '',
                    selectedassistants:'',
                    selectedaids:'',  
                  
                  })}
                   open={this.state.openForm} 
                 >
                  
                   <Modal.Content >
                       <DynamicComponent job_id={this.state.selectedjob} id={this.state.formid} data={this.state.jobdata} refreshContent={this.refreshContent} setCloseForm={this.setCloseForm} type={this.state.type}/>
  </Modal.Content>
                
   </Modal>
    <Sidebar.Pushable >

    <Sidebar 
            as={Menu}
            animation='overlay'
            direction='left'
            width='very wide'
            vertical 
            visible={this.state.jobedit}
          >
            <Segment basic>
<Form onValidSubmit={this.handleJobModify}>
                       
                       <Form.Group unstackable widths={3}>
            
                     
            
                                      <Form.Field>
                                        <label>Start Date </label>
                                        <DateInput closable={true}
                              required
                              name="jobstart"
                              placeholder="Date"
                              value={this.state.jobstart}
                              dateFormat={'MM-DD-YYYY'}
                              iconPosition="left"
                              onChange={this.handleDateChange}
                            />
                                        
                                      </Form.Field>

                                      <Form.Field>
                                        <label>30 Day Mark</label>
                                        <DateInput closable={true}
                              required
                              name="daymark"
                              placeholder="Date"
                              value={this.state.daymark}
                              dateFormat={'MM-DD-YYYY'}
                              iconPosition="left"
                              onChange={this.handleDateChange}
                            />
                                        
                                      </Form.Field>
            
                                      <Form.Field>
                                        <label>End Date</label>
                                        <DateInput closable={true}
                              required
                              name="jobend"
                              placeholder="Date"
                              value={this.state.jobend}
                              dateFormat={'MM-DD-YYYY'}
                              iconPosition="left"
                              onChange={this.handleDateChange}
                            />
                                        
                                      </Form.Field>
                                     
             
            
            
                       </Form.Group>

                       <Form.Group unstackable widths={2}>

                       <Form.Field>
                                      <Form.Input
                                        fluid 
                                        label="Sequence/Weekly"
                                        placeholder="2,2,2,2"
                                        onChange={this.handleChange}
                                        name='visitseq'
                                        value={this.state.visitseq}
                                      />
                                        
                                      </Form.Field>  
                                      <Form.Field>
                                      <Form.Input
                                        fluid 
                                        label="Number of visits"
                                        placeholder="1"
                                        onChange={this.handleChange}
                                        name='visit'
                                        value={this.state.visit}
                                      />
                                        
                                      </Form.Field>
                       </Form.Group>
                 
                                   
                                   
                                  
            
                                   
                                   <Button.Group widths='2'>
                                   <Button  labelPosition='left'
                           icon='arrow alternate circle left outline'  content="Back"   color={'black'} onClick={()=>this.setState({jobedit:false})} /> 
                                   <Button
                           content="Update"
                           labelPosition='right'
                           icon='checkmark'
                           color={'green'} 
                            fluid 
                         />


                                   </Button.Group>
                                 </Form>
                                 <Divider></Divider>
                                 <Button color={'green'}   icon='file' target={'_blank'} onClick={()=>{this.setState({newform:true, selectedForm:'Evaluation' })}}  href={this.state.addrefferal}  content="Evaluation"  fluid />
                                 <Divider></Divider>
                                 <Button color={'olive'}   icon='file' target={'_blank'} onClick={()=>{this.setState({newform:true, selectedForm:'Order' })}}  href={this.state.addrefferal}  content="Order"  fluid />
                                 <Divider></Divider>
                                 <Button color={'orange'}   icon='file' target={'_blank'} onClick={()=>{this.setState({newform:true, selectedForm:'Note' })}}  href={this.state.addrefferal}  content="Note"  fluid />
                                 <Divider></Divider>
                                 <Button color={'red'}   icon='file' target={'_blank'} onClick={()=>{this.setState({newform:true, selectedForm:'Discharge' })}}  href={this.state.addrefferal}  content="Discharge"  fluid />
                                 
        
        
        
        
        <Divider></Divider>
      <Modal 
         onClose={() =>  this.setState({confirm:true })} 
         open={this.state.newform} 
          
    >
   
      <Modal.Content >
       <DynamicComponent  data={''} new={true} refreshContent={this.refreshContent} job_id={this.state.selectedjob}  setCloseForm={() => { this.setState({open:false}) }} type={this.state.selectedForm} created_at={new Date} updated_at={new Date} />
      </Modal.Content>
      
    </Modal>


                                 <Table   singleLine  >
           
           <Table.Header>
             <Table.Row>
               <Table.HeaderCell>Created</Table.HeaderCell> 
               <Table.HeaderCell>Form Type</Table.HeaderCell>
               <Table.HeaderCell></Table.HeaderCell>
              
                
             </Table.Row>
           </Table.Header>
        
           <Table.Body>
           
           {this.state.forms.map((form, index) => (
                <Table.Row key={index}>
                <Table.Cell> <Moment date={form.created_at} format="LLL"  /> </Table.Cell>  
                <Table.Cell>{form.type}  </Table.Cell> 
                <Table.Cell>
                <Button color='blue' fluid onClick={() => {   
                  this.setState({selectedForm: form.type, jobdata:form.data, formid:form._id, openForm:true})
   }} >View Form</Button>
               </Table.Cell> 
              </Table.Row> 
           ))}
       
           </Table.Body>
           <Table.Footer fullWidth>
            
           </Table.Footer>
         </Table>

                                 </Segment>
          </Sidebar>
         <Sidebar
            as={Menu}
            animation='overlay'
            direction='right'
            width='very wide'
            vertical 
            visible={this.state.open}
          >

        <Segment basic>
        <Form onValidSubmit={this.creatEvent}>
       <Form.Group widths="3">
       <Form.Field>
       <label>Status</label>
       <Dropdown
          placeholder='Select Status'
          fluid
          selection
          name="status"
          required
          options={statusOptions}
          onChange={this.handleChange}
          value={this.state.status}
        /> 
       </Form.Field>
       <Form.Field>
       <label>Time In</label>
                 <TimeInput closable={true}
                   required
                   
                   name="clockin"
                   placeholder="Clock In"
                   value={this.state.clockin}
                   timeFormat={'ampm'}
                   iconPosition="left"
                   onChange={this.handleChange}
                   disabled={this.state.status === 'refusal'  ? true :  ( this.state.status === 'cancelation'  ? true : false)}
                 />
       </Form.Field>
 
       <Form.Field>
       <label>Time Out</label>
                 <TimeInput closable={true}
                   required
                   name="clockout"
                   placeholder="Clock Out"
                   value={this.state.clockout}
                   initialDate={this.state.clockin}
                   timeFormat={'ampm'}
                   iconPosition="left"
                   onChange={this.handleChange}
                   disabled={this.state.status === 'refusal'  ? true :  ( this.state.status === 'cancelation'  ? true : false)}
                 />
       </Form.Field>
       
 
      
 
  
       </Form.Group>

       <Form.Group widths="3">
       <Form.Field>
       <label>Therapists</label>
      <Dropdown
        name='selectedtherapists'
        selection 
        multiple
        fluid
        defaultSelectedLabel={null}
        options={therapistOptions}
        onChange={this.handleChangeTherapist}
        placeholder='Therapist'
        renderLabel={renderLabelTherapist}
        required
        value={this.state.selectedtherapists || null}
        validationErrors={{
          isDefaultRequiredValue: 'You need to select a therapist',
        }}
        errorLabel={errorLabel}
      />
   </Form.Field>

     

   <Form.Field>
   <label>Assistants</label> 
   <Dropdown
     name='selectedassistants'
     selection 
     fluid  
     clearable
     options={assisOptions}
     closeOnSelectionChange={false}
     value={this.state.selectedassistants || ''}
     onChange={this.handleChangeAssistant}
     placeholder='Assistant'
     renderLabel={renderLabelTherapist} 
     validationErrors={{
       isDefaultRequiredValue: 'You need to select a assistant',
     }}
     errorLabel={errorLabel}
   />
 </Form.Field>

 <Form.Field>
     <label>Aids</label>
     <Dropdown
       name='selectedaids'
       selection 
       fluid 
       clearable
       options={aidsOptions}
       onChange={this.handleChangeAid}
       value={this.state.selectedaids || ''}
       placeholder='Aid'
       renderLabel={renderLabelTherapist} 
       validationErrors={{
         isDefaultRequiredValue: 'You need to select a aid',
       }}
       errorLabel={errorLabel}
     />
   </Form.Field>

 
       </Form.Group>

       <Form.Field>
        <label>Note</label>
        <Form.TextArea name='note' defaultValue={this.state.note} fluid onChange={this.handleChange}  ></Form.TextArea>
       </Form.Field>
      

 <Form.Field>
       <Popup
             trigger={<Form.Button content={'Submit'} fluid color={'green'}  >Add Schedule</Form.Button>}
             content={this.state.errorMessage}
             on='click'
             open={this.state.error}
             onClose={this.handleError} 
             position='top right'
           />
               
       </Form.Field>
     
       
       </Form>
       <Divider horizontal>Or</Divider>

       <Button     fluid color={'black'} onClick={()=>this.setState({open:false})} >Close</Button>
          
        </Segment>
          </Sidebar>
          <Sidebar
            as={Menu}
            animation='overlay'
            icon='labeled'
            direction='right'
          
            vertical
            visible={this.state.edit}
            width='very wide'
          >
<Segment basic>
<Form onValidSubmit={this.updateEvent}>
        <Form.Group widths="3">
        <Form.Field>
        <label>Time In</label>
               <TimeInput closable={true}
                 required
                 
                 name="clockin"
                 placeholder="Clock In"
                 value={this.state.clockin}
                 timeFormat={'ampm'}
                 iconPosition="left"
                 onChange={this.handleChange}
                 disabled={this.state.status === 'refusal'  ? true :  ( this.state.status === 'cancelation'  ? true : false)}
               />
     </Form.Field>

     <Form.Field>
     <label>Time Out</label>
               <TimeInput closable={true}
                 required
                 name="clockout"
                 placeholder="Clock Out"
                 value={this.state.clockout}
                 timeFormat={'ampm'}
                 iconPosition="left"
                 onChange={this.handleChange}
                 disabled={this.state.status === 'refusal'  ? true :  ( this.state.status === 'cancelation'  ? true : false)}
               />
     </Form.Field>
     

     <Form.Field>
     <label>Status</label>
     <Dropdown
       placeholder='Select Status'
       fluid
       selection
       name="status"
       value={this.state.status}
       options={statusOptions}
       onChange={this.handleChange}
     />


                
     </Form.Field>

        </Form.Group>
      <Form.Group widths="3">
 
      
     
      <Form.Field>
      <label>Therapists</label>
      <Dropdown
       name='selectedtherapists'
       selection 
       multiple
       fluid
       defaultSelectedLabel={null}
       options={therapistOptions}
       onChange={this.handleChangeTherapist}
       placeholder='Therapist'
       renderLabel={renderLabelTherapist}
       required
       value={this.state.selectedtherapists || null}
       validationErrors={{
         isDefaultRequiredValue: 'You need to select a therapist',
       }}
       errorLabel={errorLabel}
     />
   </Form.Field>

       <Form.Field>
       <label>Assistants</label>
      <Dropdown
        name='selectedassistants'
        selection 
        fluid
        options={assisOptions}
        defaultValue={this.state.selectedassistants}
        onChange={this.handleChangeAssistant}
        placeholder='New Assistant'
        renderLabel={renderLabelTherapist}
         
        validationErrors={{
          isDefaultRequiredValue: 'You need to select a assistant',
        }}
        errorLabel={errorLabel}
      />
       </Form.Field>
 
       <Form.Field>
       <label>Aids</label>
    <Dropdown
      name='selectedaids'
      selection 
      fluid
      options={aidsOptions}
      defaultValue={this.state.newaids}
      onChange={this.handleChangeAid}
      placeholder='New Aid'
      renderLabel={renderLabelTherapist}
       
      validationErrors={{
        isDefaultRequiredValue: 'You need to select a aid',
      }}
      errorLabel={errorLabel}
    />
     </Form.Field>

      </Form.Group>

      <Form.Group> 


      <Checkbox   defaultChecked={this.state.exludeemp}  onChange={(e, data) => this.toggle('exludeemp', e.target.checked)} label='Exclude Employee' name={'exludeemp'} toggle />
      <Checkbox defaultChecked={this.state.billing}  onChange={(e, data) => this.toggle('billing',  e.target.checked)}   label='Billing' name={'billing'} toggle /> 
       

      </Form.Group>

      <Form.Field>
        <label>Note</label>
        <Form.TextArea name='note' defaultValue={this.state.note} fluid onChange={this.handleChange}  ></Form.TextArea>
       </Form.Field>
      
      <Form.Field>
   
      <Popup
            trigger={<Form.Button   content={'Submit'} fluid color={'green'}  >Update</Form.Button>}
            content={this.state.errorMessage}
            on='click'
            open={this.state.error}
            onClose={this.handleError} 
            position='top right'
          />
            
      
  
     
              
      </Form.Field>
 
      
      </Form>

      

      <Divider></Divider>

      <Button.Group widths='3'>
      <Button compact  color={'violet'} onClick={this.missVisit}  >Miss Visit</Button>   
      <Button compact  color={'red'} onClick={this.deleteSchedule} >Delete</Button>  
      <Button  compact   color={'black'} onClick={()=>this.setState({edit:false, selectedtherapists: '',
                    selectedassistants:'',
                    selectedaids:'', })} >Close</Button> 
      </Button.Group>
      
      
     
      </Segment>
          </Sidebar>

          <Sidebar.Pusher   >
          <Segment>
    <Header as='h3'>Filter Patients</Header>
    
    <Grid columns={2} divided>
    <Grid.Row>
      <Grid.Column>
      <Input fluid icon='search' onChange={(data)=>{
    this.setState({search:data.target.value})
     
    }} type='text' placeholder='Search patients'  >
     
     
  </Input>
      </Grid.Column>
      <Grid.Column>
      
    <Button.Group fluid basic>

      <Button onClick={()=>{ this.setState({calendarsort:'title'})}} >Patient</Button>
      <Button onClick={()=>{ this.setState({calendarsort:'therapistid'})}} >Therapist</Button>
      <Button onClick={()=>{ this.setState({calendarsort:'assistantid'})}} >Assistant</Button>
   
    </Button.Group>
      </Grid.Column>
       
    </Grid.Row>

    
  </Grid>
  
     
    
   

    <Divider section />

    <Header as='h3'>Scheduler</Header>
    <FullCalendar 
            
            eventContent={this.renderEventContent}
            plugins={[momentTimezonePlugin, dayGridPlugin, resourceTimeGridPlugin, timeGridPlugin, interactionPlugin, resourceTimelinePlugin]}
            initialView="resourceTimelineFull"
            schedulerLicenseKey='GPL-My-Project-Is-Open-Source' 
            timeZone={'America/Los_Angeles'}
            selectable 
            resourceOrder={this.state.calendarsort}
            ref={this.calendarRef} 
            eventClick={(info)=>{
              
              let status = info.event._def.extendedProps.status;
              const selectedassistants =  info.event._def.extendedProps.assistants.length > 0 ? info.event._def.extendedProps.assistants[0]._id : null
              const newaids =  info.event._def.extendedProps.aids.length > 0 ? info.event._def.extendedProps.aids[0]._id : null
              this.setState({
                 
                edit:true,
                job_id: info.event._def.extendedProps.jobid,
                selectedtherapists: info.event._def.extendedProps.therapists.length > 0 ? [info.event._def.extendedProps.therapists[0]._id] : null,
                selecttherapist:  info.event._def.extendedProps.therapists.length > 0 ? [info.event._def.extendedProps.therapists[0]._id] : null,
                selectedassistants: selectedassistants,
                newaids: newaids,
                note: info.event._def.extendedProps.note,
                start:info.event._def.extendedProps.start,
                selectedschedule:info.event._def.publicId,
                clockin:moment(info.event._def.extendedProps.time_in ).format('LT'),
                clockout:moment(info.event._def.extendedProps.time_out ).format('LT'),
                status:info.event._def.extendedProps.status,
                billing:info.event._def.extendedProps.billing 

                
              })
               
            
            }}

             selectOverlap={false}
             eventOverlap={false}
             editable={true}
             select={this.handleDateSelect}
             eventChange={this.handleChangeDate}
             eventDrop={(arg) => {
             this.updatedropEvent({
               start:moment(arg.event._instance.range.start).format('YYYY-MM-DD'),
               id: arg.event._def.publicId
             })
            
             
            }}
            stickyHeaderDates={true}
            stickyFooterScrollbar={true}
            contentHeight={800} 
            views= {
                { 
                    resourceTimelineFull: {
                        type: 'resourceTimeline',
                        buttonText: 'Full', 
                        duration: { months: 1 }  
                         
                    },
                    resourceTimelineGrid: {
                      type: 'resourceTimeGridDay',
                      buttonText: 'Day',
                       
                       
                  }
                }
            }
            customButtons={
              {prevbtn: {
                text: 'Previouse',
                click: () => {
                  let calendarApi = this.calendarRef.current.getApi()
                   calendarApi.prev()
                  this.fetchData(this.props.location.state.id);
                }
              },
              nextbtn: {
                text: 'Next',
                click: () => {
                  let calendarApi = this.calendarRef.current.getApi()
                   calendarApi.next()
                  this.fetchData(this.props.location.state.id);
                }
              }
            }
            }
            headerToolbar={{
              left:'prevbtn,nextbtn',
              center: 'title',
              right: 'resourceTimelineFull,resourceTimelineWeek,resourceTimelineDay'
            }}
             
            resources={resources} 
            events={this.state.events}
            resourceAreaWidth={'35%'}
            resourceAreaColumns={ [
                    {
                    
                      field: 'patient',
                      headerContent: 'Patient',
                      width:'30%',
                  
                    },
                    {
                      field: 'therapist',
                      headerContent: 'Therapists',
                      width:'20%',
                      
                    },
                    {
                      field: 'assistant',
                      headerContent: 'Assistant',
                      width:'20%',
                      
                    },
                    {
                      field: 'aids',
                      headerContent: 'Aids',
                      width:'10%'
                    },
                    {
                      field: 'detail',
                      headerContent: 'Details',
                      width:'20%'
                    } 
                     
                  ]}
                ></FullCalendar>
  </Segment>
        
          </Sidebar.Pusher>
        </Sidebar.Pushable>
                    </div>
 
       </div>
    );
  }
 } 
 
 export default Schedule;
 