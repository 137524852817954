import React from "react";
import { Form, Button } from "semantic-ui-react";

const Empty = () => {
  return (
    <div  >
        Please select a form first! 
    </div>
  );
};

export default Empty;
